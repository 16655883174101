import React from 'react';
import {Field, FieldProps, FormikValues, useFormikContext} from "formik";
import styles from "./Counter.module.scss";
import NumberFormat from "react-number-format";
import TextInput from "../TextInput";
import classNames from "classnames";

export type CounterProps = {
  name: string;
  minimum?: number;
  maximum?: number;
  className?: string;
}

/**
 * Счетчик с кнопками уменьшения/увеличения.
 * Должен находиться внутри <Formik/>
 */
function Counter<Values extends FormikValues = FormikValues>({
  name,
  minimum = 0,
  maximum,
  className,
}: CounterProps) {
  
  const {values, setFieldValue} = useFormikContext<Values>();
  const count = Number(values[name] ?? 0)
  
  return <div className={classNames(styles["counter"], className)}>
    <div
      className={classNames(styles["counter-button"], count === minimum && styles["counter-button--disabled"])}
      onClick={() => setFieldValue(name, count - 1)}>
      <div className={styles["counter-button-sign"]}/>
    </div>
    <Field name={name}>
      {({meta, ...props}: FieldProps) =>
        <div className={styles["counter-input"]}>
          <NumberFormat
            {...props}
            id={String(name)}
            onValueChange={({floatValue}) => setFieldValue(name, floatValue ?? 0)}
            value={count}
            allowNegative={false}
            onBlur={props.field.onBlur}
            customInput={TextInput}
          />
        </div>}
    </Field>
    <div
      className={classNames(styles["counter-button"], styles["counter-button--plus"], count === maximum && styles["counter-button--disabled"])}
      onClick={() => setFieldValue(name, count + 1)}
    >
      <div className={classNames(styles["counter-button-sign"], styles["counter-button-sign--plus"])}/>
    </div>
  </div>;
}

export default Counter;
