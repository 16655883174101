import axios, {AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse, AxiosResponseHeaders} from "axios";
import queryString from 'query-string';
import {APP_VERSION} from "../constants/appStatus";

export const request = (
  endpoint: string,
  method: AxiosRequestConfig["method"] = "GET",
  data: unknown = null,
  {
    headers = null,
    options = null,
  }: {
    headers?: AxiosRequestHeaders | null;
    options?: AxiosRequestConfig | null;
  } = {},
): Promise<AxiosResponse> => {
  const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://api-partner.emenu.delivery/v1'
    : 'http://api-partner.emenu.softlab.kz/v1';
  
  const url = endpoint.includes('http' || 'https') ? endpoint : `${BASE_URL}${endpoint}`;
  
  const authHeaders: AxiosRequestHeaders = {};
  
  const authInfo = JSON.parse(localStorage.getItem('auth') ?? "null");
  
  if (authInfo?.authKey) {
    authHeaders['Authorization'] = `Bearer ${authInfo.authKey}`;
  }
  
  return axios({
    url,
    data,
    method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-app-version': APP_VERSION,
      ...authHeaders,
      ...headers,
    },
    ...options,
  })
}

export type APIRequestParameters = {
  expand?: string;
  page?: number;
} & Record<string, unknown>

export const addParametersToEndpoint = (
  endpoint: string,
  params: APIRequestParameters = {},
) => {
  
  const search = queryString.stringify(params, {
    skipNull: true,
    skipEmptyString: true,
  });
  
  return `${endpoint}${search && '?'}${search}`;
}

/**
 * Получить значение заголовка Date
 */
export const getDateHeader = (
  headers: AxiosResponseHeaders,
): string | null => headers['date'] || null
