import React from 'react';
import Spinner from "react-bootstrap/Spinner";
import {LoaderProps} from "./types";
import classNames from "classnames";

/**
 * Спиннер 48х48
 */
const Loader: React.VFC<LoaderProps> = ({
  className,
  ...spinnerProps
}) =>
  <div className={classNames("d-flex justify-content-center p-2", className)}>
    <Spinner animation="border" variant="primary" {...spinnerProps}/>
  </div>;

export default Loader;
