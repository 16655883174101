import React from 'react';
import styles from "./TextButton.module.scss";
import classNames from "classnames";
import Icon from "../Icon";

export type TextButtonProps = {
  onClick: () => void;
  icon?: string;
  label?: JSX.Element | string;
  state?: string[];
  className?: string;
}

const TextButton: React.VFC<TextButtonProps> = ({
  onClick,
  label,
  icon,
  state,
  className,
}) =>
  <div
    className={classNames(styles["text-button"], state?.map(cl => styles[cl]), className)}
    onClick={onClick}>
    {icon && <Icon icon={icon}/>}
    {label && <span className={styles["text-button-label"]}>{label}</span>}
  </div>;

export default TextButton;
