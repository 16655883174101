import React, {useEffect, useMemo, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {Form, Formik} from "formik";
import BootstrapForm from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {useAppSelector} from "../../../../../app/store";
import {getCatalogProducts} from "../../../../catalog/subfeatures/products/actions";
import {flatProductsReSelector} from "../../../../catalog/subfeatures/products/reselectors";
import {
  catalogProductsErrorSelector,
  catalogProductsLoadingSelector
} from "../../../../catalog/subfeatures/products/reducer";
import {useDispatch} from "react-redux";
import Loader from "../../../../../common/components/Loader";
import {extractPartsFromCatalogItemCompositeId} from "../../../../catalog/utils/helpers";
import {AttributeResponse, AttributeTypes} from "../../../../catalog/subfeatures/attributes/models";
import Radio from "../../../../../common/components/Radio";
import classNames from "classnames";
import Checkbox from "../../../../../common/components/Checkbox";
import {OrderAddItemDialogProps} from "./types";
import SelectWrapper from "../../../../../common/components/SelectWrapper";
import {OrderProductItemForm} from "../../../models";
import Counter from "../../../../../common/components/Counter";

/**
 * Диалог добавления позиции к заказу
 */
const OrderAddItemDialog: React.VFC<OrderAddItemDialogProps> = ({
  show,
  closeDialog,
  onSubmit,
  companyId,
}) => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const products = useAppSelector(flatProductsReSelector);
  const productsLoading = useAppSelector(catalogProductsLoadingSelector);
  const productsError = useAppSelector(catalogProductsErrorSelector);
  
  type ProductOption = {
    value: string;
    label: string;
    attributes?: AttributeResponse[];
  }
  
  type GroupedOption = {
    label: string;
    options: ProductOption[];
  }
  
  /**
   * Опции, сгруппированные по родительской категории или подкатегории
   */
  const groupedOptions = useMemo(() => {
    const thirdLevelItems = products.filter(({level, enabled}) => level === "third" && enabled);

    return products
      .filter(({level}) => level !== "third")
      .map(({name, compositeId}) => ({
        label: name,
        options: thirdLevelItems
          .filter(({compositeId: thirdLevelId}) => thirdLevelId.startsWith(`${compositeId}.`))
          .map(({compositeId, name, portionSize, attributes}) => ({
            value: String(extractPartsFromCatalogItemCompositeId(compositeId).portionId),
            label: `${name}${portionSize ? ` (${portionSize})` : ''}`,
            attributes,
          })),
      }));
  }, [products])
  
  const [chosenItemAttributes, setChosenItemAttributes] = useState<AttributeResponse[]>([]);
  
  useEffect(() => {
    if (show && companyId) {
      dispatch(getCatalogProducts(companyId, 'products.groupVariants.items,childs.products.groupVariants.items', true));
      setChosenItemAttributes([]);
    }
  }, [companyId, dispatch, show]);
  
  const initialValues = {
    itemId: '',
    quantity: '1',
    attributeIds: [] as number[],
  }
  
  return <Modal show={show} onHide={closeDialog} centered>
    <Modal.Header closeButton>
      <Modal.Title>{t(`features.orders.addingProductsToOrder`)}</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={initialValues}
      onSubmit={({itemId, quantity, attributeIds}) => {
        const form: OrderProductItemForm = {
          portionId: Number(itemId),
          quantity: Number(quantity),
        }
        
        if (attributeIds.length) {
          form.attributeIds = attributeIds;
        }
        
        onSubmit(form)
        closeDialog();
      }}>
      {({values, setFieldValue}) => {
        /** есть ли ошибки в атрибутах */
        const someAttributesInvalid = !!chosenItemAttributes
          .filter(({typeId, items, maxItems}) => {
            const itemIds = items?.map(({id}) => id) ?? [];
            const itemIdsInValues = values.attributeIds.filter(vai => itemIds.includes(vai));
            
            /**
             * Если атрибут обязательный и он не выбран (или каким-то образом выбрано > 1),
             * то это ошибка
             */
            if (typeId === AttributeTypes.Singular && itemIdsInValues.length !== 1) {
              return true;
            }
            
            /**
             * Если атрибут множественный, указан максимум и выбрано больше максимума,
             * то это ошибка
             */
            return typeId === AttributeTypes.Multiple
              && typeof maxItems === "number"
              && itemIdsInValues.length > maxItems;
          })
          .length;
        
        return <Form>
          <Modal.Body>
            {productsLoading
              ? <Loader className="mt-0_5"/>
              : <SelectWrapper<ProductOption, false, GroupedOption>
                  isDisabled={!groupedOptions.length}
                  onChange={(data) => {
                    setFieldValue('itemId', data?.value ?? '');
                    setFieldValue('attributeIds', [])
                    setChosenItemAttributes(data?.attributes ?? []);
                  }}
                  options={groupedOptions}
                  placeholder={"Выберите блюдо"}
                />}
  
            <Counter
              name={"quantity"}
              className="mt-4"
            />
      
            {chosenItemAttributes.map(({name, maxItems, id, typeId, items}) =>
              <div className="mt-4" key={id}>
                <BootstrapForm.Label htmlFor={`attr-${id}`}>
                  {name}{typeof maxItems === "number" && ` (макс. ${maxItems})`}
                </BootstrapForm.Label>
                {typeId === AttributeTypes.Singular
                  ? <>
                    {items?.map(({id: itemId, price, name}, index, array) =>
                      <Radio
                        checked={values.attributeIds.includes(itemId)}
                        className={classNames(index !== array.length - 1 && "mb-2")}
                        id={`${id}-${itemId}`}
                        key={itemId}
                        label={<><span style={{width: 60}}>+{price} ₸</span> {name}</>}
                        onChange={() => {
                          setFieldValue('attributeIds', [
                              /** убрать другое значение этой радио-группы */
                              ...values.attributeIds.filter(i => !array.map(({id}) => id).includes(i)),
                              itemId,
                            ]
                          )
                        }}
                      />)}
                  </>
                  : <>
                    {items?.map(({id: itemId, price, name}, index, array) =>
                      <div className={classNames(index !== array.length - 1 && "mb-2", "d-flex")} key={itemId}>
                        <Checkbox
                          checked={values.attributeIds.includes(itemId)}
                          disabled={!values.attributeIds.includes(itemId)
                          && typeof maxItems === "number"
                          && values.attributeIds.filter(i => array.map(({id}) => id).includes(i)).length >= maxItems}
                          id={`${id}-${itemId}`}
                          label={<><span style={{width: 60}}>+{price} ₸</span> {name}</>}
                          onChange={(e) => {
                            e.target.checked
                              ? setFieldValue('attributeIds', [
                                ...values.attributeIds,
                                itemId,
                              ])
                              : setFieldValue('attributeIds', values.attributeIds.filter(i => i !== itemId))
                          }}
                        />
                      </div>)}
                  </>}
              </div>)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeDialog}>
              {t('common.actionCancel')}
            </Button>
            <Button
              disabled={!!productsError || someAttributesInvalid || !values.quantity || !values.itemId}
              variant="outline-primary"
              type="submit">
              {t('common.actionSubmit')}
            </Button>
          </Modal.Footer>
        </Form>;
      }}
    </Formik>
  </Modal>;
};



export default OrderAddItemDialog;
