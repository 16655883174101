import {addParametersToEndpoint, APIRequestParameters, request} from "../../../../common/utils/apiHelpers";
import {AttributeDisableForm, AttributeEnableForm, AttributeItemEditForm} from "./models";

/**
 * API методы каталога товаров (Catalog), связанные с атрибутами
 */
export const requestAttributes = (
  companyId: number,
  params: APIRequestParameters = {},
) =>
  request(
    addParametersToEndpoint(`/attribute`, {...params, companyId}),
    'GET',
  )

export const requestEnableAttributes = (
  form: AttributeEnableForm,
) =>
  request(
    `/attribute-item/enable`,
    'POST',
    form
  )

export const requestDisableAttributes = (
  form: AttributeDisableForm,
) =>
  request(
    `/attribute-item/disable`,
    'POST',
    form,
  )

export const requestAttributeEdit = (
  attributeId: number,
  form: AttributeItemEditForm,
) =>
  request(
    `/attribute-item/${attributeId}`,
    'POST',
    form,
  )
