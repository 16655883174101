export const GET_ORDERS_REQUEST = Symbol('orders/GET_REQUEST');

export const GET_ORDERS_SUCCESS = Symbol('orders/GET_SUCCESS');

export const GET_ORDERS_FAILURE = Symbol('orders/GET_FAILURE');

export const UPDATE_ORDERS_SUCCESS = Symbol('orders/UPDATE_SUCCESS');

export const REPLACE_IN_ORDERS = Symbol('orders/REPLACE_ONE');

export const REMOVE_FROM_ORDERS = Symbol('orders/REMOVE_ONE');

export const COMPLAIN_COURIER_SUCCESS = Symbol('orders/COMPLAIN_COURIER_SUCCESS')

/**
 * id статусов заказов, используемые в логике панели
 */
export enum OrderStatusIds {
  New = 5,
  StartCooking = 11,
}

/**
 * Группы id статусов заказов для удобства фильтрации
 */
export enum OrderStatusGroupIds {
  Cancelled = 0,
  TimeForProcessingExpired = 5,
  // InProgress = 10, // Рабочие статусы (не используется)
  Delivered = 20,
}

/** верхний предел отмененных заказов (все статусы, связанные с отменой, <= 0) */
export const CANCELLED_ORDERS_UPPER_BOUND_STATUS_ID = 0;
/** нижний предел выполненных заказов (все статусы, связанные с завершением, >= 100) */
export const COMPLETED_ORDERS_LOWER_BOUND_STATUS_ID = 100;

/**
 * Типы курьеров
 */
export enum CourierTypes {
  Yandex = 20,
}
