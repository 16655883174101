import {PortionResponse, ProductResponse, SectionResponse} from "../subfeatures/products/models";
import {AttributeResponse} from "../subfeatures/attributes/models";

export enum CatalogSubFeatures {
  Attributes = 'attributes',
  Products = 'products',
}

/**
 * Позиция списка блюд/добавок (категория, подкатегория, порция/позиция добавки)
 */
export type CatalogItem = {
  /**
   * Составной идентификатор позиции.
   * Формат: aaa-bbb.ccc.ddd или aaa.ccc.ddd, где:
   *  aaa - id категории (id добавки),
   *  bbb - id подкатегории (не всегда присутствует, у добавок вообще нет)
   *  ccc - id самого блюда (у добавок нет, используется '_')
   *  ddd - id конкретной порции блюда (id конкретной позиции добавки)
   */
  compositeId: string;
  /**
   * Уровень отображения позиции, определяет размер текста и отступы.
   * first для категорий
   * second для подкатегорий
   * third для порций/позиций добавки
   */
  level: 'first' | 'second' | 'third';
  /** Название позиции */
  name: string;
  /** Описание позиции */
  description?: string;
  /** Статус позиции */
  enabled: boolean;
  /** Размер позиции */
  portionSize?: string | null;
  /** Цена позиции */
  price?: number | null;
  /** Деактивировано до */
  disabledUntil?: string | null;
  /** Атрибуты */
  attributes?: AttributeResponse[];
}

/**
 * Объект частей составного id, поля названы по полям блюда, но используется и для добавок
 */
export type CatalogItemCompositeIdParts = {
  sectionId: number;
  subSectionId?: number;
  productId: number;
  portionId: number;
}

export type CatalogFilter = {
  searchText: string;
  isEnabled: boolean | null;
}

/**
 * Поля CatalogItem для редактирования
 */
export type CatalogItemEditInfo = Pick<CatalogItem, 'compositeId' | 'price' | 'name' | 'description' | 'portionSize'>

/**
 * Собрать составной id порции из объектов иерархии порции
 */
export const buildCompositeCatalogItemId = (
  section: SectionResponse,
  product: ProductResponse,
  portion: PortionResponse,
  prefix = '',
) => `${prefix}${section.id}.${product.id}.${portion.id ?? ''}`

/**
 * Выделить id объектов иерархии из составного id
 */
export const extractPartsFromCatalogItemCompositeId = (
  compositeId: string
): CatalogItemCompositeIdParts => {
  const ids = compositeId.split('.');
  
  let sectionId = ids[0];
  let subSectionId: number | undefined = undefined;
  
  if (sectionId.includes('-')) {
    subSectionId = Number(sectionId.split('-')[1]);
    sectionId = sectionId.split('-')[0];
  }
  
  return {
    sectionId: Number(sectionId),
    subSectionId,
    productId: Number(ids[1]),
    portionId: Number(ids[2]),
  }
}

/**
 * Отфильтровать блюда из флат списка
 */
export const filterCatalogItems = (
  items: CatalogItem[],
  {searchText, isEnabled}: CatalogFilter,
): CatalogItem[] =>
  items
    /**
     * 1. порции фильтруются по поиску и статусу
     */
    .filter(item => {
      if (item.level === "third") {
        if (typeof isEnabled === "boolean" && item.enabled !== isEnabled) {
          return false;
        }
        
        if (searchText && !item.name.toLowerCase().includes(searchText.toLowerCase())) {
          return false;
        }
      }
      
      return true;
    })
    /**
     * 2. удаляются пустые подкатегории
     */
    .filter((item, _, array) => {
      if (item.level !== "second") {
        return true;
      }
      
      return array.some(({compositeId}) => compositeId.startsWith(`${item.compositeId}.`))
    })
    /**
     * 3. удаляются пустые категории
     */
    .filter((item, _, array) => {
      if (item.level !== "first") {
        return true;
      }
      
      return array.some(({compositeId}) =>
        compositeId.startsWith(`${item.compositeId}.`) || compositeId.startsWith(`${item.compositeId}-`))
    })
