import React, {useMemo} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../../../assets/images/emenu-logo-full.svg"
import Button from "react-bootstrap/Button";
import {useAppSelector} from "../../../../app/store";
import {currentCompanyIdSelector, employeeDataSelector} from "../../../../features/auth/reducer";
import youtubeLogo from "../../../../assets/images/youtube.svg";
import styles from "./Footer.module.scss";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const Footer: React.FC = () => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'layout'});
  
  const companyId = useAppSelector(currentCompanyIdSelector);
  const {
    companies,
  } = useAppSelector(employeeDataSelector) ?? {};
  
  const currentCompany = companies?.find(({id}) => id === companyId);
  
  const videoInstructionLink = currentCompany?.hasEmenuDelivery
    ? 'https://emenuvideoinstruction.tilda.ws/'
    : 'https://emenuvideoinstruction.tilda.ws/aggregation';
  
  const links = useMemo(() => <>
    <a
      href={'https://b24-32s68o.bitrix24.site/crm_form_j5wp2/'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={classNames('link-body', styles['label'])}>
      {t('addItems')}
    </a>
    <br/>
    <a
      href={'https://b24-32s68o.bitrix24.site/crm_form_xsmol/'}
      target={'_blank'}
      rel={'noopener noreferrer'}
      className={classNames('d-inline-block link-body mt-1', styles['label'])}>
      {t('promotionRequest')}
    </a>
  </>, [t])
  
  return (
    <div className='px-2 px-md-0 mt-4 mt-lg-5'>
      <Row className='align-items-center gy-3 gy-lg-4 gx-3 gx-md-4 gx-lg-5'  xs={2} md={3} lg={4} xl={5}>
        <Col>
          <img src={logo} alt='' width={111} height={34}/>
          <div className={classNames('fw-bold mt-3', styles['label'])}>
            {t('EMENUCustomerService')}
          </div>
          <a href={'tel:77750309555'} className={classNames('d-inline-block link-body mt-1', styles['label'])}>
            +7 775 030 95 55
          </a>
        </Col>
        <Col className='d-none d-md-block'>
          {links}
        </Col>
        <Col>
          <Button
            href={videoInstructionLink}
            target={"_blank"}
            rel={'noopener'}
            className={styles['button-adaptive']}>
            <div className={classNames('d-flex align-items-center', styles['label'])}>
              <img src={youtubeLogo} width={30} height={20} alt={''} className='me-2'/>
              {t('videoInstruction')}
            </div>
          </Button>
          <div className={classNames('gray-500 mt-1', styles['label'])}>
            {t('aboutUsingAdminPanel')}
          </div>
          <div className='d-block d-md-none mt-1'>
            {links}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
