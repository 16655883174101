import * as types from './constants';
import {OrderResponse} from "./models";
import {RootState} from "../../app/store";
import {removeFromData, replaceInData} from "../../common/utils/functions";
import {doesOrderNeedAttention} from "./helpers";

type OrdersState = {
  data: OrderResponse[];
  loading: boolean;
  error: string | null;
  /**
   * Заголовок Date запроса, чтобы можно было использовать серверное время для вычисления в таймере
   * (серверное время имеет фиксированный часовой пояс +05, в отличие от часового пояса девайса, который может изменяться)
   * @see OrderTimerDisplay - обратный отсчет, использующий этот заголовок
   * @see OrderDetail - не использует редусер (сохраняет значение в state компонента), но логика там аналогичная
   */
  dateHeader: string | null;
  /**
   * Флаг успеха запроса жалобы на курьера.
   * Вообще не должно быть в этом редусере, но раз уж было реализовано и работает, то пусть будет.
   */
  isSuccessComplainCourier: boolean;
}

export type OrdersAction = {
  type: typeof types.GET_ORDERS_REQUEST;
} | {
  type: typeof types.GET_ORDERS_SUCCESS;
  payload: {
    data: OrderResponse[];
    dateHeader: string | null;
  };
} | {
  type: typeof types.UPDATE_ORDERS_SUCCESS;
  payload: {
    data: OrderResponse[];
    dateHeader: string | null;
  };
} | {
  type: typeof types.GET_ORDERS_FAILURE;
  payload: string;
} | {
  type: typeof types.REPLACE_IN_ORDERS;
  payload: OrderResponse;
} | {
  type: typeof types.REMOVE_FROM_ORDERS;
  payload: number;
} | {
  type: typeof types.COMPLAIN_COURIER_SUCCESS
}

const initialState: OrdersState = {
  data: [],
  loading: false,
  error: null,
  dateHeader: null,
  isSuccessComplainCourier: false
}

export const ordersListSelector = ({orders: {data}}: RootState) => data;
export const ordersLoadingSelector = ({orders: {loading}}: RootState) => loading;
export const ordersErrorSelector = ({orders: {error}}: RootState) => error;
/** id заказов, которые требуют внимания */
export const orderIdsThatNeedAttentionSelector = ({orders: {data}}: RootState): number[] => data
  .filter(doesOrderNeedAttention)
  .map(({id}) => id);
export const isSuccessComplainCourierSelector = ({orders: {isSuccessComplainCourier}}: RootState) => isSuccessComplainCourier;

export const ordersDateHeaderSelector = ({orders: {dateHeader}}: RootState): string | null => dateHeader;

export default function orders(
  state = initialState,
  action: OrdersAction
): OrdersState {
  switch (action.type) {
    case types.GET_ORDERS_REQUEST:
      return {
        data: [],
        loading: true,
        error: null,
        dateHeader: null,
        isSuccessComplainCourier: false
      }
    case types.UPDATE_ORDERS_SUCCESS:
    case types.GET_ORDERS_SUCCESS:
      return {
        data: action.payload.data,
        loading: false,
        error: null,
        dateHeader: action.payload.dateHeader,
        isSuccessComplainCourier: false
      };
    case types.GET_ORDERS_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload,
        dateHeader: null,
        isSuccessComplainCourier: false
      }
    case types.REPLACE_IN_ORDERS:
      return {
        ...state,
        data: replaceInData(state.data, action.payload)
      }
    case types.REMOVE_FROM_ORDERS:
      return {
        ...state,
        data: removeFromData(state.data, action.payload)
      }
    case types.COMPLAIN_COURIER_SUCCESS:
      return {
        ...state,
        isSuccessComplainCourier: true
      }
    default:
      return state;
  }
}
