import {AppThunkAction} from "../../app/store";
import * as types from "../constants/toasts";
import {ToastsAction} from "../reducers/toasts";

/** Добавление тоаста */
export const addToast = (message: string, id: number): AppThunkAction<ToastsAction> => dispatch => {
    dispatch({type: types.ADD_TOAST, payload: {message, id}})
};

/** Удаление тоаста */
export const removeToast = (id: number): AppThunkAction<ToastsAction> => dispatch => {
    dispatch({type: types.REMOVE_TOAST, payload: id})
};
