import React, {useEffect} from "react";
import {ToastContainer, Toast} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {removeToast} from "../../actions/toasts";

const Toasts: React.VFC<any> = () => {

    const {
        toasts
    } = useSelector((state: RootState) => ({
        toasts: state.toasts
    }));

    return(
        <ToastContainer style={{position: 'fixed', right: 0, bottom: 0}}>
            {toasts.map((toast: any, index: number) => <ToastItem key={toast.id} id={toast.id} message={toast.message} index={index} />)}
        </ToastContainer>
    )
}

type ToastItemProps = {
        id: number
        message: string
        index: number
}

const ToastItem: React.FC<ToastItemProps> = ({id, message, index}) => {

    const dispatch = useDispatch();

    useEffect(() => {
        const timeoutID = window.setTimeout(() => {
            dispatch(removeToast(id))
        }, (5 + index) * 5000);

        return () => window.clearTimeout(timeoutID );
    }, [dispatch, id, index]);

    const closeHandler = () => {
        dispatch(removeToast(id))
    }

    return (
        <Toast onClose={closeHandler}>
            <Toast.Header>
                <strong className="me-auto">Уведомление</strong>
                <small className="text-muted">{new Date().toLocaleTimeString()}</small>
            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    )
}

export default Toasts;
