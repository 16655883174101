import React, {useCallback, useMemo, useState} from 'react';
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import Button from "react-bootstrap/Button";
import TextArea from "../../../../../common/components/TextArea";
import * as Yup from "yup";
import {requiredMessage} from "../../../../../common/utils/validation";
import {ReviewAnswerDialogProps} from "./types";
import {useAppSelector} from "../../../../../app/store";
import {reviewsListSelector} from "../../../reducer";
import classNames from "classnames";
import styles from "./ReviewAnswerDialog.module.scss";
import LightboxGallery from "../../../../../common/components/LightboxGallery";

/**
 * Диалог создания/редактирования ответа на отзыв
 */
const ReviewAnswerDialog: React.VFC<ReviewAnswerDialogProps> = ({
  id,
  closeHandler,
  submitHandler,
}) => {
  
  const {t} = useTranslation();
  
  const reviews = useAppSelector(reviewsListSelector);
  
  const relevantReview = useMemo(() => {
    if (!id) {
      return null;
    }
    
    return reviews.find(review => review.id === id) ?? null;
  }, [id, reviews])
  
  const initialValues = useMemo(() => ({
    message: relevantReview?.answer ?? '',
  }), [relevantReview?.answer])
  
  const images = relevantReview?.imageUrls || [];
  
  const [isLightBox, setIsLightBox] = useState(false);
  
  const openLightBox = useCallback(() => setIsLightBox(true), []);
  const closeLightBox = useCallback(() => setIsLightBox(false), []);
  
  const [imageInLightBoxIndex, setImageInLightBoxIndex] = useState(0);
  
  return <>
    <Modal show={typeof id === "number"} onHide={closeHandler} centered>
      <Modal.Header closeButton>
        <Modal.Title>{t('features.reviews.answer')}</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={({message}) => {
          submitHandler({message}, !relevantReview?.answer)
        }}>
        {({isValid}) =>
          <Form>
            <Modal.Body>
              {relevantReview && <div className="mb-3">
                {relevantReview.message && <div>{relevantReview.message}</div>}
                {!!relevantReview.imagePreviewUrls.length && <div className="d-flex">
                  {relevantReview.imagePreviewUrls.map((url, index) =>
                    <div
                      className={classNames("me-2_5 clickable", relevantReview.message && "mt-2_5", styles['picture'])}
                      key={url}
                      onClick={() => {
                        setImageInLightBoxIndex(index);
                        openLightBox();
                      }}>
                      <img src={url} alt=""/>
                    </div>)}
                </div>}
              </div>}
              <Field
                component={TextArea}
                name={'message'}
                placeholder={t('features.reviews.enterAnswer')}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="outline-secondary" onClick={closeHandler}>
                {t('common.actionCancel')}
              </Button>
              <Button variant="outline-primary"
                      type="submit"
                      disabled={!isValid}
              >
                {t('features.reviews.actionSendAnswer')}
              </Button>
            </Modal.Footer>
          </Form>}
      </Formik>
    </Modal>
    {isLightBox && <LightboxGallery
        images={images}
        imageIndex={imageInLightBoxIndex}
        setImageIndex={setImageInLightBoxIndex}
        closeHandler={closeLightBox}
    />}
  </>;
};

const validationSchema = Yup.object().shape({
  message: Yup.string()
    .required(requiredMessage())
})

export default ReviewAnswerDialog;
