import * as types from './constants';
import {PortionResponse, SectionResponse} from "./models";
import {CatalogItemCompositeIdParts} from "../../utils/helpers";
import {spreadInData} from "../../../../common/utils/functions";
import {RootState} from "../../../../app/store";

type CatalogProductsState = {
  sections: SectionResponse[];
  loading: boolean;
  error: string | null;
}

type ReplaceProductPortionObject = {
  compositeIdParts: CatalogItemCompositeIdParts[];
  form: Partial<PortionResponse>;
}

/**
 * Заменить порции в списке категорий
 */
const replacePortions = (
  sections: SectionResponse[],
  {compositeIdParts, form}: ReplaceProductPortionObject,
): SectionResponse[] => {
  const nextSections = [...sections];
  
  compositeIdParts.forEach(({
    sectionId,
    subSectionId,
    productId,
    portionId
  }) => {
    let section = nextSections.find(({id}) => id === sectionId)
  
    if (subSectionId && section?.subSections) {
      section = section.subSections.find(({id}) => id === subSectionId)
    }
    
    if (!section || !section.products) {
      return;
    }
    
    const product = section.products.find(({id}) => id === productId)
    
    if (!product || !product.portions) {
      return;
    }
    
    product.portions = spreadInData(product.portions, {id: portionId, ...form});
  })
  
  return nextSections;
}

export type CatalogProductsAction = {
  type: typeof types.GET_SECTIONS_REQUEST;
} | {
  type: typeof types.GET_SECTIONS_SUCCESS;
  payload: SectionResponse[];
} | {
  type: typeof types.GET_SECTIONS_FAILURE;
  payload: string;
} | {
  type: typeof types.REPLACE_PORTIONS;
  payload: ReplaceProductPortionObject;
}

const initialState: CatalogProductsState = {
  sections: [],
  loading: false,
  error: null,
}

export const catalogProductsSelector = ({catalogProducts: {sections}}: RootState) => sections;
export const catalogProductsLoadingSelector = ({catalogProducts: {loading}}: RootState) => loading;
export const catalogProductsErrorSelector = ({catalogProducts: {error}}: RootState) => error;

export default function catalogProducts(
  state = initialState,
  action: CatalogProductsAction,
): CatalogProductsState {
  switch (action.type) {
    case types.GET_SECTIONS_REQUEST:
      return {
        sections: [],
        loading: true,
        error: null,
      }
    case types.GET_SECTIONS_SUCCESS:
      return {
        sections: action.payload,
        loading: false,
        error: null,
      }
    case types.GET_SECTIONS_FAILURE:
      return {
        sections: [],
        loading: false,
        error: action.payload,
      }
    case types.REPLACE_PORTIONS:
      return {
        ...state,
        sections: replacePortions(state.sections, action.payload),
      };
    default:
      return state;
  }
}

