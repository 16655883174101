export type AttributeResponse = {
  id: number;
  /** Название атрибута */
  name: string;
  /** Тип атрибута */
  typeId: AttributeTypes;
  /** Максимальное количество доступное для выбора пользователем */
  maxItems?: number | null;
  /** Позиции атрибута */
  items?: AttributeItemResponse[] | null;
}

/**
 * Типы атрибутов
 */
export enum AttributeTypes {
  /** Единичный выбор */
  Singular = 1,
  /** Множественный выбор */
  Multiple = 2,
}

/**
 * Информация о позиции атрибута
 */
export type AttributeItemResponse = {
  /** ID */
  id: number;
  /** ID атрибута */
  attributeId?: number | null;
  /** Название */
  name: string;
  /** Стоимость */
  price: number;
  /** Активность */
  isEnabled?: boolean | null;
  /** Отключено до */
  disabledUntil?: string | null;
}

export type AttributeEnableForm = {
  ids: number[];
}

export type AttributeDisableForm = {
  ids: number[];
  dateTime?: string | null;
}

export type AttributeItemEditForm = {
  price: number;
}
