import React from 'react';
import {Alert, Button} from "react-bootstrap";
import {ErrorPlugProps} from "./types";
import classNames from "classnames";
import {useTranslation} from "react-i18next";

/**
 * Компонент - заглушка с сообщением об ошибке
 * @param header - Заголовок ошибки, необязателен
 * @param children - Сообщение об ошибке, может быть тип ReactElement | String | Null,
 * в последнем случае выводится дефолтное сообщение об ошибке
 * @param color - Цвет контейнера
 * @param onClick - Action вешающийся на кнопку "Обновить"
 * @param className
 * @constructor
 */
const ErrorPlug: React.VFC<ErrorPlugProps> = ({
    header,
    children,
    color= "danger",
    onClick,
    className
    }) => {

    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

    return (
        <>
            <Alert variant={color} className={classNames(className, "mb-3")}>
                {!!header &&
                    <h3>{header}</h3>
                }
                {!!children ? children : commonT("errorOccurred")}
            </Alert>
            {!!onClick &&
                <div>
                    <Button
                        variant="danger"
                        onClick={onClick}
                    >
                        {commonT("actionUpdate")}
                    </Button>
                </div>
            }</>
    );
};

export default ErrorPlug;
