export const requiredMessage = (): string => {
    return 'Поле обязательное'
};

export const minLengthMessage = (count: number): string => {
    return `Поле должно содержать не менее ${count} символов`;
};

export const maxLengthMessage = (count: number): string => {
    return `Поле должно содержать не более ${count} символов`;
};

export const emailFormatMessage = (): string => {
    return 'Неверный формат Email'
}
