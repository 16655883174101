import React from 'react';
import styles from "./Figure.module.scss";
import Icon from "../Icon";
import classNames from "classnames";
import {FigureProps} from "./types";

/**
 * Компонент с иконкой, названием и значением поля
 */
const Figure: React.VFC<FigureProps> = ({
  icon,
  value,
  label,
  className,
  valueClassName,
  variant,
  link
}) => {
  
  const imageClassName = classNames(styles['figure-image'], (variant && variant === "orange") && styles['figure-image--orange']);
  
  const image = link
    ? <a href={link} className={imageClassName}>
        <Icon icon={icon}/>
      </a>
    : <div className={imageClassName}>
        <Icon icon={icon}/>
      </div>;
  
  const valueOutput = link
    ? <a href={link} className={classNames(styles['figure-caption-value'], valueClassName)}>{value}</a>
    : <div className={classNames(styles['figure-caption-value'], valueClassName)}>{value}</div>;
  
  return <div className={classNames(styles.figure, className)}>
    {image}
    {(!!label || !!value) && <div className={styles['figure-caption']}>
      {!!label && <div className={styles['figure-caption-label']}>{label}</div>}
      {valueOutput}
    </div>}
  </div>;
}

export default Figure;
