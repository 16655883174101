import {
  PortionResponse,
  ProductDisableForm,
  ProductEnableForm,
  ProductPortionEditForm,
  SectionResponse
} from "./models";
import {addParametersToEndpoint, APIRequestParameters, request} from "../../../../common/utils/apiHelpers";
import {AxiosPromise} from "axios";

/**
 * companyId: 5 or 13
 */
export const requestAllCategories = (
  companyId: number,
  params: APIRequestParameters = {},
): AxiosPromise<SectionResponse[]> =>
  request(
    addParametersToEndpoint(`/category`, {...params, companyId}),
    'GET'
  )

export const requestFilteredCategories = (
  params: APIRequestParameters = {},
): AxiosPromise =>
  request(
    addParametersToEndpoint(`/category/search`, params)
  )

export const requestEnableProductPortions = (
  form: ProductEnableForm
): AxiosPromise =>
  request(
    `/product/enable`,
    'POST',
    form,
  )

export const requestDisableProductPortions = (
  form: ProductDisableForm
): AxiosPromise =>
  request(
    `/product/disable`,
    'POST',
    form,
  )

export const requestProductPortionEdit = (
  portionId: number,
  form: ProductPortionEditForm,
): AxiosPromise<PortionResponse> =>
  request(
    `/product/${portionId}`,
    'POST',
    form,
  )
