import React from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./OrderAcceptanceInfo.module.scss";
import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../../../../app/store";
import {appStatusAcceptanceInfoSelector} from "../../../../../common/reducers/appStatus";
import classNames from "classnames";

/**
 * Блоки с информацией о принятых и не принятых заказах
 */
const OrderAcceptanceInfo: React.FC = () => {
  
  const {t} = useTranslation('translation');
  
  const {
    orderTodayAcceptedCount,
    orderTodayNotAcceptedCount,
    orderYesterdayAcceptedCount,
    orderYesterdayNotAcceptedCount,
  } = useAppSelector(appStatusAcceptanceInfoSelector);
  
  return <div className='mt-4 mx-2 mx-md-0'>
    <Row className='g-2 g-md-4'>
      <Col xs={6} md={'auto'}>
        <div className={classNames(styles['block'], styles['block--success'])}>
          <div className={classNames('h5 mb-2', styles['block-title'])}>
            <span className='d-none d-sm-block'>{t('features.orders.acceptedOrders')}</span>
            <span className='d-block d-sm-none'>{t('features.orders.accepted')}</span>
          </div>
          <div className={classNames('mb-2 text-secondary', styles['block-line'])}>{t('common.yesterday')} - <span>{orderYesterdayAcceptedCount}</span></div>
          <div className={styles['block-line']}>{t('common.today')} - <span>{orderTodayAcceptedCount}</span></div>
        </div>
      </Col>
      <Col xs={6} md={'auto'}>
        <div className={classNames(styles['block'], styles['block--danger'])}>
          <div className={classNames('h5 mb-2', styles['block-title'])}>
            <span className='d-none d-sm-block'>{t('features.orders.notAcceptedOrders')}</span>
            <span className='d-block d-sm-none'>{t('features.orders.notAccepted')}</span>
          </div>
          <div className={classNames('mb-2 text-secondary', styles['block-line'])}>{t('common.yesterday')} - <span>{orderYesterdayNotAcceptedCount}</span></div>
          <div className={styles['block-line']}>{t('common.today')} - <span>{orderTodayNotAcceptedCount}</span></div>
        </div>
      </Col>
    </Row>
  </div>
};

export default OrderAcceptanceInfo;
