import React from 'react';
import classNames from "classnames";
import styles from "./StarRatingBar.module.scss";
import {StarRatingBarProps} from "./types";

/**
 * Рейтинг в виде шкалы звезд
 */
const StarRatingBar: React.VFC<StarRatingBarProps> = ({
  rating,
  state,
  className,
}) =>
  <div className={classNames(
    styles['bar'],
    state?.map(cl => styles[cl]),
    className,
  )}>
    <span className={styles['bar-outline']}>
      ☆☆☆☆☆
      <div
        className={styles['bar-outline-rating']}
        style={{width: `${(rating / 5) * 100}%`}}>
        ★★★★★
      </div>
    </span>
  </div>;

export default StarRatingBar;
