import {OrderResponse, OrderSummaryResponse} from "../orders/models";
import * as types from "./constants";
import {RootState} from "../../app/store";

type ArchiveOrdersState = {
  data: OrderResponse[];
  loading: boolean;
  error: string | null;
  pageCount: number;
  summary: OrderSummaryResponse | null;
  summaryLoading: boolean;
  summaryError: string | null;
}

export type ArchiveOrdersAction = {
  type: typeof types.GET_ARCHIVE_ORDERS_REQUEST;
} | {
  type: typeof types.GET_ARCHIVE_ORDERS_SUCCESS;
  payload: {
    data: OrderResponse[];
    pageCount: number;
  };
} | {
  type: typeof types.GET_ARCHIVE_ORDERS_FAILURE;
  payload: string;
} | {
  type: typeof types.GET_ARCHIVE_SUMMARY_REQUEST;
} | {
  type: typeof types.GET_ARCHIVE_SUMMARY_SUCCESS;
  payload: OrderSummaryResponse;
} | {
  type: typeof types.GET_ARCHIVE_SUMMARY_FAILURE;
  payload: string;
}

const initialState: ArchiveOrdersState = {
  data: [],
  loading: false,
  error: null,
  pageCount: 1,
  summary: null,
  summaryLoading: false,
  summaryError: null,
}

export const archiveOrdersListSelector = ({archiveOrders: {data}}: RootState) => data;
export const archiveOrdersLoadingSelector = ({archiveOrders: {loading}}: RootState) => loading;
export const archiveOrdersErrorSelector = ({archiveOrders: {error}}: RootState) => error;
export const archiveOrdersPageCountSelector = ({archiveOrders: {pageCount}}: RootState) => pageCount;
export const archiveSummarySelector = ({archiveOrders: {summary}}: RootState) => summary;
export const archiveSummaryLoadingSelector = ({archiveOrders: {summaryLoading}}: RootState) => summaryLoading;
export const archiveSummaryErrorSelector = ({archiveOrders: {summaryError}}: RootState) => summaryError;

export default function archiveOrders(
  state = initialState,
  action: ArchiveOrdersAction
): ArchiveOrdersState {
  switch (action.type) {
    case types.GET_ARCHIVE_ORDERS_REQUEST:
      return {
        ...state,
        data: [],
        pageCount: 1,
        loading: true,
        error: null,
      }
    case types.GET_ARCHIVE_ORDERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pageCount: action.payload.pageCount,
        loading: false,
        error: null,
      };
    case types.GET_ARCHIVE_ORDERS_FAILURE:
      return {
        ...state,
        data: [],
        pageCount: 1,
        loading: false,
        error: action.payload,
      }
    case types.GET_ARCHIVE_SUMMARY_REQUEST:
      return {
        ...state,
        summary: null,
        summaryLoading: true,
        summaryError: null,
      }
    case types.GET_ARCHIVE_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        summaryLoading: false,
        summaryError: null,
      };
    case types.GET_ARCHIVE_SUMMARY_FAILURE:
      return {
        ...state,
        summary: null,
        summaryLoading: false,
        summaryError: action.payload,
      }
    default:
      return state;
  }
}
