import {AppThunkAction} from "../../app/store";
import * as types from "./constants";
import {requestArchiveOrders, requestArchiveSummary} from "../orders/api";
import {getAxiosErrorMessage} from "../../common/utils/functions";
import {ArchiveOrdersAction} from "./reducer";
import {OrderFilterForm} from "../orders/models";
import {APIRequestParameters} from "../../common/utils/apiHelpers";

/**
 * Получить список архивных заказов
 */
export const getArchiveOrders = (
  form: OrderFilterForm | null = null,
  params: APIRequestParameters = {expand: '', page: 1},
): AppThunkAction<ArchiveOrdersAction> => dispatch => {
  
  dispatch({type: types.GET_ARCHIVE_ORDERS_REQUEST});
  
  requestArchiveOrders(form, params)
    .then(({data, headers}) => {
      const pageCount = Number(headers['x-pagination-page-count']) || 1;
      
      dispatch({
        type: types.GET_ARCHIVE_ORDERS_SUCCESS,
        payload: {
          data: data || [],
          pageCount,
        },
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ARCHIVE_ORDERS_FAILURE,
        payload: getAxiosErrorMessage(err)
      })
    })
}

/**
 * Получить статистику архивных заказов
 */
export const getArchiveSummary = (
  form: OrderFilterForm | null = null,
  params: APIRequestParameters = {expand: ''},
): AppThunkAction<ArchiveOrdersAction> => dispatch => {
  
  dispatch({type: types.GET_ARCHIVE_ORDERS_REQUEST});
  
  requestArchiveSummary(form, params)
    .then(({data}) => {
      dispatch({
        type: types.GET_ARCHIVE_SUMMARY_SUCCESS,
        payload: data,
      })
    })
    .catch(err => {
      dispatch({
        type: types.GET_ARCHIVE_SUMMARY_FAILURE,
        payload: getAxiosErrorMessage(err)
      })
    })
}
