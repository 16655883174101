import {buildCompositeCatalogItemId, CatalogItem} from "../../utils/helpers";
import {createSelector} from "reselect";
import {SectionResponse} from "./models";
import { catalogProductsSelector } from "./reducer";

/**
 * Преобразовать многоуровневый массив с продуктами в одноуровневый
 * для удобства отображения и обработки
 */
const flattenProducts = (
  sections: SectionResponse[],
): CatalogItem[] => {
  const list: CatalogItem[] = [];
  
  sections.forEach(section => {
    list.push({
      compositeId: section.id.toString(),
      level: 'first',
      name: section.name,
      enabled: true,
    })
    
    section.products?.forEach(product => {
      list.push(
        ...(
          product.portions?.map(portion => ({
            compositeId: buildCompositeCatalogItemId(section, product, portion),
            level: 'third' as 'third',
            name: product.name,
            description: product.description,
            enabled: portion.isEnabled,
            portionSize: portion.size,
            price: portion.price,
            disabledUntil: portion.disabledUntil,
            attributes: product.attributes,
          })) ?? []
        )
      )
    })
    
    section.subSections?.forEach(subSection => {
      list.push({
        compositeId: `${section.id}-${subSection.id}`,
        level: 'second',
        name: subSection.name,
        enabled: true,
      })
      
      subSection.products?.forEach(product => {
        list.push(
          ...(
            product.portions?.map(portion => ({
              compositeId: buildCompositeCatalogItemId(subSection, product, portion, `${section.id}-`),
              level: 'third' as 'third',
              name: product.name,
              enabled: portion.isEnabled,
              portionSize: portion.size,
              price: portion.price,
              disabledUntil: portion.disabledUntil,
              attributes: product.attributes,
            })) ?? []
          )
        )
      })
    })
  })
  
  return list;
}

export const flatProductsReSelector = createSelector(
  [catalogProductsSelector],
  flattenProducts,
)
