import React, {useCallback, useMemo, useState} from 'react';
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import {Link, useHistory} from "react-router-dom";
import styles from "./Layout.module.scss";
import classNames from 'classnames';
import {useDispatch} from "react-redux";
import {changeCurrentCompany, disableCompany, logout} from "../../../features/auth/actions";
import {useAppSelector} from "../../../app/store";
import {
  companyChangeLoadingSelector,
  currentCompanyIdSelector,
  employeeDataSelector,
  employeeLoadingSelector
} from "../../../features/auth/reducer";
import {useTranslation} from "react-i18next";
import LayoutNavTab from "./LayoutNavTab";
import CompanyStatusToggle from "./CompanyStatusToggle";
import ErrorBoundary from "../ErrorBoundary";
import Icon from "../Icon";
import Loader from "../Loader";
import logo from "../../../assets/images/emenu-logo-dark.svg";
import {NavTabProps} from "./types";
import {reviewsUnansweredCountSelector} from "../../../features/reviews/reducer";
import {orderIdsThatNeedAttentionSelector} from "../../../features/orders/reducer";
import {
  appStatusMarketingLinkSelector,
  appStatusNeedToUpdateSelector,
  appStatusNotificationMessageSelector,
} from "../../reducers/appStatus";
import LayoutTopAlert from "./LayoutTopAlert";
import DisableParamsDialog from "../DisableParamsDialog";
import {EntityDisableForm} from "../DisableParamsDialog/types";
import {CompanyStatuses} from "../../../features/profile/models";
import LayoutDebtWarning from "./LayoutDebtWarning";
import Footer from "./Footer";

/**
 * Каркас приложения
 */
const Layout: React.FC = ({
  children,
}) => {

  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const {
    companies,
    partner,
  } = useAppSelector(employeeDataSelector) ?? {};
  
  const loading = useAppSelector(employeeLoadingSelector);
  const companyId = useAppSelector(currentCompanyIdSelector);
  
  /** текущая компания */
  const currentCompanyObject = companies?.find(({id}) => id === companyId);
  const companyName = currentCompanyObject?.name ?? 'EMENU Partner';
  const companyCity = currentCompanyObject?.city?.name ?? '';
  const companyImageUrl = currentCompanyObject?.imageUrl ?? '';
  const companyIsDisabled = !!currentCompanyObject?.isClosed;
  const companyStatus = currentCompanyObject?.status;
  
  /** статус запроса на открытие/закрытие компании */
  const companyChangeLoading = useAppSelector(companyChangeLoadingSelector);
  
  const hasUnansweredReviews = !!useAppSelector(reviewsUnansweredCountSelector);
  const orderIdsThatNeedAttention = useAppSelector(orderIdsThatNeedAttentionSelector);
  
  /** статус показа диалога деактивации */
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  
  const openDisableCompanyDialog = useCallback(() => setIsDisableDialogOpen(true), []);
  const closeDisableCompanyDialog = useCallback(() => setIsDisableDialogOpen(false), []);
  
  const disableThisCompany = ({dateTime, message}: EntityDisableForm) => {
    if (companyId && message) {
      dispatch(disableCompany(companyId, {dateTime, message}))
    }
  }
  
  const needToUpdate = useAppSelector(appStatusNeedToUpdateSelector);
  const notificationMessage = useAppSelector(appStatusNotificationMessageSelector);
  const marketingLink = useAppSelector(appStatusMarketingLinkSelector);
  
  const {location: {pathname}} = useHistory();
  
  const navItems = useMemo<NavTabProps[]>(() => {
    const output: NavTabProps[] = [
      {
        link: '/orders',
        label: t('layout.navOrders'),
        icon: 'shopping_bag',
        active: pathname.includes('/orders'),
        hasIndicator: orderIdsThatNeedAttention.length > 0
      },
      {
        link: '/archive',
        label: t('layout.navArchive'),
        icon: 'analytics',
        active: pathname.includes('/archive'),
      },
      {
        link: '/catalog',
        label: t('layout.navCatalog'),
        icon: 'restaurant',
        active: pathname.includes('/catalog'),
      },
      {
        link: '/reviews',
        label: t('layout.navReviews'),
        icon: 'comment',
        active: pathname.includes('/reviews'),
        hasIndicator: hasUnansweredReviews,
      },
      // {
      //   link: '/profile',
      //   label: t('layout.navProfile'),
      //   icon: 'storefront',
      //   active: pathname.includes('/profile'),
      // },
    ]
    
    if (marketingLink) {
      output.push({
        link: marketingLink,
        label: t('layout.navMarketing'),
        icon: 'ads_click',
        hasIndicator: true,
        useHTMLLinkElement: true,
      })
    }
    
    return output;
  }, [hasUnansweredReviews, pathname, t, orderIdsThatNeedAttention.length, marketingLink]);
  
  if (loading) {
    return <div className={styles["plug"]}>
      <Loader className={styles["plug__loader"]}/>
    </div>
  }

  return <>
    {needToUpdate && <LayoutTopAlert/>}
    {notificationMessage && <LayoutTopAlert message={notificationMessage}/>}
    <LayoutDebtWarning
      debt={partner?.debt ?? 0}
      isClosedDueToDebt={companyStatus === CompanyStatuses.DisabledDueToDebt}
      payUrl={partner?.payUrl}
    />
    <Navbar className={classNames("mb-0 mb-md-4", styles["header"])}>
      <Container className="justify-content-between justify-content-lg-center px-0">
        <Navbar.Brand className={classNames("d-none d-xl-block me-0 me-xl-3")}>
          <Link to={"/orders"}>
            <img
              src={logo}
              width="172"
              height="52"
              className="d-none d-lg-inline-block align-top"
              alt="Emenu"
            />
          </Link>
        </Navbar.Brand>
        <CompanyStatusToggle
          isCompanyDisabled={companyIsDisabled}
          openDisableDialog={openDisableCompanyDialog}
        />
        <div className="d-none d-lg-block flex-grow-1"/>
        <Nav>
          <div className={classNames(styles["header-navigation"], navItems.length === 5 && styles["header-navigation--fuller"])}>
            {navItems.map(props =>
              <LayoutNavTab key={props.link} {...props}/>
            )}
          </div>
          <Navbar.Toggle aria-controls="dropdown-menu-toggle"/>
          <Navbar.Collapse id="dropdown-menu-toggle">
            <Nav>
              <NavDropdown
                id="nav-dropdown"
                align="end"
                className="p-0"
                title={<span className={styles["header-dropdown-label"]}>
                  <img className="overflow-hidden d-inline-block" src={companyImageUrl} alt={companyName} width="40" height="40"/>
                  <div className="d-none d-lg-block">
                    {companyName}
                    <div className={styles["header-dropdown-label-desc"]}>{companyCity}</div>
                  </div>
                  <Icon className="black d-none d-lg-inline-block" icon="navigate_next"/>
                </span>}
                menuVariant="light">
                {!companyIsDisabled && <>
                  <NavDropdown.Item as={"button"} onClick={openDisableCompanyDialog} disabled={companyChangeLoading}>
                    {t('layout.actionDisableCompany')}
                  </NavDropdown.Item>
                  <NavDropdown.Divider/>
                </>}
                {companies && companies.length > 1 && <>
                  {companies.map(({id, name, city, imageUrl}) =>
                    <NavDropdown.Item
                      disabled={id === companyId}
                      key={id}
                      onClick={() => dispatch(changeCurrentCompany(id))}>
                      <div className="d-flex align-items-center">
                        <img className="me-2_5 rounded-circle overflow-hidden" src={imageUrl} alt={name} width="40" height="40"/>
                        <div>
                          {name}
                          <div className={styles["header-dropdown-label-desc"]}>{city?.name}</div>
                        </div>
                      </div>
                    </NavDropdown.Item>)}
                  <NavDropdown.Divider />
                </>}
                <NavDropdown.Item as={Link} to={'/profile'}>
                  {t('layout.navProfile')}
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item as={"button"} onClick={() => dispatch(logout())}>
                  {t('features.auth.actionLogOut')}
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Nav>
      </Container>
    </Navbar>
    <ErrorBoundary>
      <Container className={classNames("px-0", styles["container"])}>
        {children}
        <Footer/>
      </Container>
    </ErrorBoundary>
    <DisableParamsDialog
      title={t('layout.actionDisableCompany')}
      description={t('layout.disableForHowLong')}
      onSubmit={disableThisCompany}
      isOpen={isDisableDialogOpen}
      closeDialog={closeDisableCompanyDialog}
      nextOpenTime={currentCompanyObject?.nextOpenTime ?? null}
      hasMessage
    />
  </>;
}

export default Layout;
