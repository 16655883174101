import React, {useMemo} from 'react';
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {Field, Form, Formik} from "formik";
import Button from "react-bootstrap/Button";
import {OrderChangeStatusDialogProps, TimeForReadyOptionsProps} from "./types";
import BootstrapForm from "react-bootstrap/Form";
import SelectWrapper from "../../../../common/components/SelectWrapper";
import DateTimePicker from "../../../../common/components/DateTimePicker";
import {format} from "date-fns";
import {
  mapPickerDateToServerDate,
  parseServerDate,
  UsedDateTimeFormats
} from "../../../../common/utils/dateTimeHelpers";

/**
 * Диалог подтверждения изменения статуса
 * (и, при необходимости, ввода дополнительных полей для изменения статуса)
 */
const OrderChangeStatusDialog: React.VFC<OrderChangeStatusDialogProps> = ({
  info,
  closeHandler,
  submitHandler
}) => {
  const {t} = useTranslation();

  // const [timeForReadyOptions, setTimeForReadyOptions] = useState<TimeForReadyOptionsProps[]>([]);
  //
  // useEffect(() => {
  //   const options = [];
  //   for (let a = 5; a <= 180; a+=5){
  //     options.push({label:t(`features.orders.minutes`, {minutes: a}), value: a})
  //   }
  //
  //   setTimeForReadyOptions(options);
  // }, [t])
  
  const timeForReadyOptions = useMemo<TimeForReadyOptionsProps[]>(() => {
    const options = [];
    for (let a = 5; a <= 180; a += 5){
      options.push({label:t(`features.orders.minutes`, {minutes: a}), value: a})
    }
    
    return options;
  }, [t])

  const dateTimeReadyStartObject = info?.isShowDateTimeReadyStart
    ? {
        dateTimeReadyStart: info?.dateTimeReadyStart
          ? format(parseServerDate(info.dateTimeReadyStart), UsedDateTimeFormats.DisplayedInPicker)
          : ""
      }
    : {};

  return <Modal show={!!info} onHide={closeHandler} centered>
    <Modal.Header closeButton>
      {info?.additionalForm === "timeReadyForm" ?
          <Modal.Title>{t(`features.orders.orderPreparationTime`)}</Modal.Title> :
          <Modal.Title>{t(`features.orders.orderStatusChange`)}</Modal.Title>
      }
    </Modal.Header>
    <Formik
      initialValues={{
        timeForReady: info?.timeForReady ? info?.timeForReady : null,
        ...dateTimeReadyStartObject
      }}
      onSubmit={(values) => {

        let form = null;

        if (values.timeForReady) {
          if (info?.additionalForm === "timeReadyForm") {

            const dateTimeReadyStart = (!!values.dateTimeReadyStart && values.dateTimeReadyStart.length > 0)
              ? {dateTimeReadyStart: mapPickerDateToServerDate(values.dateTimeReadyStart)}
              : {};

            form = {
              timeForReady: values.timeForReady,
              ...dateTimeReadyStart
            }
          }
        }
  
        submitHandler(form);
      }}>
      {({values, setFieldValue}) => {
        return <Form>
          <Modal.Body>

            {info?.additionalForm !== "timeReadyForm" && <>
            <div>
              {t(
                  'features.orders.reallyChangeStatus',
                  {
                    status: info?.statusTitle,
                  }
              )}
            </div>
            </>
            }

            {info?.additionalForm === "timeReadyForm" && <>
              <SelectWrapper
                  options={timeForReadyOptions}
                  onChange={data => {
                    setFieldValue('timeForReady', data?.value);
                  }}
                  value={timeForReadyOptions.find((x: TimeForReadyOptionsProps) => x.value === values.timeForReady)}
                  isClearable={false}
                  isSearchable={false}
                  placeholder={t(`features.orders.choseReadyTime`)}
              />
              <div className="d-flex mt-1">
                <Button
                    size={'sm'}
                    onClick={() => {
                      setFieldValue('timeForReady', timeForReadyOptions[2].value)
                    }}
                >{timeForReadyOptions[2].label}</Button>
                <Button
                    size={'sm'}
                    onClick={() => {
                      setFieldValue('timeForReady', timeForReadyOptions[5].value)
                    }}
                    className={'mx-1'}
                >{timeForReadyOptions[5].label}</Button>
                <Button
                    size={'sm'}
                    onClick={() => {
                      setFieldValue('timeForReady', timeForReadyOptions[11].value)
                    }}
                >{timeForReadyOptions[11].label}</Button>
              </div>
              {info?.isShowDateTimeReadyStart &&
                <>
                  <BootstrapForm.Label className="mt-2" htmlFor="message">{t(`features.orders.orderPreparationTimeStart`)}</BootstrapForm.Label>
                  <Field
                      name='dateTimeReadyStart'
                      component={DateTimePicker}
                      onChange={(name: string, value: Date | null) => {
                        if (value) {
                          const string = format(value, UsedDateTimeFormats.DisplayedInPicker);

                          setFieldValue('dateTimeReadyStart', string);
                          //setFieldValue('dateTimeReadyStartSuffix', format(value, ":ssx"));
                        } else {
                          setFieldValue('dateTimeReadyStart', '')
                          //setFieldValue('dateTimeReadyStartSuffix', '')
                        }
                      }}
                  />
                </>
              }
            </>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeHandler}>
              {t('common.actionCancel')}
            </Button>
            <Button
              disabled={(info?.additionalForm === "timeReadyForm" && (!values.timeForReady || (info?.isShowDateTimeReadyStart && !values.dateTimeReadyStart))) }
              variant="outline-primary"
              type="submit">
              {t('common.actionSubmit')}
            </Button>
          </Modal.Footer>
        </Form>
      }}
    </Formik>
  </Modal>;
};

export default OrderChangeStatusDialog;
