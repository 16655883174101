import {AppThunkAction} from "../../../../app/store";
import {CatalogAttributesAction} from "./reducer";
import * as types from "./constants";
import {requestAttributeEdit, requestAttributes, requestDisableAttributes, requestEnableAttributes} from "./api";
import {AttributeDisableForm, AttributeItemEditForm} from "./models";
import {extractPartsFromCatalogItemCompositeId} from "../../utils/helpers";
import {getAxiosErrorMessage} from "../../../../common/utils/functions";
import {addNotify} from "../../../../common/actions/notify";

export const getCatalogAttributes = (
  companyId: number,
  expand = '',
): AppThunkAction<CatalogAttributesAction> => dispatch => {

  dispatch({type: types.GET_ATTRIBUTES_REQUEST});

  requestAttributes(companyId, {expand})
    .then(({data}) => {
      dispatch({
        type: types.GET_ATTRIBUTES_SUCCESS,
        payload: data || [],
      })
    })
    .catch((err) => {
      dispatch({
        type: types.GET_ATTRIBUTES_FAILURE,
        payload: getAxiosErrorMessage(err)
      })
    })
}

export const editAttribute = (
  compositeId: string,
  form: AttributeItemEditForm,
): AppThunkAction<CatalogAttributesAction> => dispatch => {
  const ids = extractPartsFromCatalogItemCompositeId(compositeId);
  
  requestAttributeEdit(ids.portionId, form)
    .then(({data}) => {
      dispatch({
        type: types.REPLACE_ATTRIBUTES,
        payload: {
          compositeIdParts: [ids],
          form: data,
        }
      })
    })
    .catch((err) => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}

export const enableAttributes = (
  compositeIds: string[],
): AppThunkAction<CatalogAttributesAction> => dispatch => {
  const compositeIdParts = compositeIds.map(extractPartsFromCatalogItemCompositeId);
  const portionIds = compositeIdParts.map(({portionId}) => portionId);
  
  requestEnableAttributes({ids: portionIds})
    .then(() => {
      dispatch({
        type: types.REPLACE_ATTRIBUTES,
        payload: {
          compositeIdParts,
          form: {isEnabled: true},
        }
      })
    })
    .catch((err) => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}

export const disableAttributes = (
  compositeIds: string[],
  dateTime: string | null = null,
): AppThunkAction<CatalogAttributesAction> => dispatch => {
  const compositeIdParts = compositeIds.map(extractPartsFromCatalogItemCompositeId);
  
  const form: AttributeDisableForm = {
    ids: compositeIdParts
      .map(({portionId}) => portionId)
  };
  
  if (dateTime) {
    form.dateTime = dateTime;
  }
  
  requestDisableAttributes(form)
    .then(() => {
      dispatch({
        type: types.REPLACE_ATTRIBUTES,
        payload: {
          compositeIdParts,
          form: {isEnabled: false, disabledUntil: dateTime},
        }
      })
    })
    .catch((err) => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}
