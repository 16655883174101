import React from 'react';
import Button from "react-bootstrap/Button";
import {login} from "../../actions";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import styles from "./LoginForm.module.scss";
import TextInput from "../../../../common/components/TextInput";
import {useTranslation} from 'react-i18next';
import {useAppSelector} from "../../../../app/store";
import {authErrorSelector, authLoadingSelector, isLoggedInSelector} from "../../reducer";
import ErrorPlug from "../../../../common/components/ErrorPlug";
import logo from "../../../../assets/images/emenu-logo.svg";
import classNames from 'classnames';
import * as Yup from 'yup';
import {emailFormatMessage, requiredMessage} from "../../../../common/utils/validation";
import Loader from "../../../../common/components/Loader";
import {Redirect} from "react-router-dom";

const validationSchema = Yup.object().shape({
    email: Yup.string().email(emailFormatMessage()).required(requiredMessage()),
    password: Yup.string().required(requiredMessage())
});

/**
 * Форма входа
 */
const LoginForm: React.VFC = () => {

    const dispatch = useDispatch();
    const {t} = useTranslation();
    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});
    const error = useAppSelector(authErrorSelector);
    const loading = useAppSelector(authLoadingSelector);
    const isLoggedIn = useAppSelector(isLoggedInSelector);
    
    const initialValues = {
        email: '', //
        password: '', //
    }
    
    if (isLoggedIn) {
        return <Redirect to={'/orders'}/>
    }

    return <div className={styles['wrapper']}>
        <div className={classNames(styles['container'])}>
            <div className={classNames(styles['container__body'])}>
                <div className={'d-flex justify-content-center'}>
                    <img className={classNames(styles['logo'], 'ml-auto mr-auto mb-1 mb-md-2')} src={logo} alt={commonT("logotype")}/>
                </div>
                <div className={'text-center'}>
                    <p className={styles['container__header']}>{commonT("partner")}</p>
                    {loading && <Loader className={'mb-4'}/> }
                </div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        const form = {
                            email: values.email ?? null,
                            password: values.password ?? null,
                        }

                        dispatch(login(form));
                    }}>
                    {({isValid}) =>
                        <Form>
                            <Field
                                component={TextInput}
                                name="email"
                                placeholder={t('features.auth.email')}
                                type="email"
                                wrapperClassName="mb-3"
                            />

                            <Field
                                component={TextInput}
                                name="password"
                                placeholder={t('features.auth.password')}
                                type="password"
                                wrapperClassName="mb-3"
                            />

                            {!!error && <ErrorPlug className={styles['container__error-message']}>{error}</ErrorPlug>}

                            <div className={'d-flex'}>
                                <Button
                                    className={'m-auto'}
                                    variant="primary"
                                    type="submit"
                                    disabled={!isValid}
                                >
                                    {t('features.auth.actionLogIn')}
                                </Button>
                            </div>
                        </Form>}
                </Formik>
            </div>
        </div>
    </div>
}

export default LoginForm;
