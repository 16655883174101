import React, {memo} from 'react';
import classNames from "classnames";
import styles from "./Chip.module.scss";
import {ChipProps} from "./types";

/**
 * Кликабельный чип с надписью
 */
const Chip: React.VFC<ChipProps> = ({
  label,
  width,
  onClick,
  count,
  state,
  className,
}) =>
  <div
    onClick={onClick}
    style={{minWidth: width ? `${width}px` : 'auto'}}
    className={classNames(
      styles["chip"],
      state?.map(cl => styles[cl]),
      className,
    )}>
    {label}
    {!!count &&
      <span
        className={classNames("ms-1 rounded-pill", styles["chip-badge"])}>
        {count}
      </span>}
  </div>;

export default memo(Chip);
