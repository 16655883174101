import React from "react";
import {useTranslation} from "react-i18next";
import styles from "./LayoutTopAlert.module.scss";
import {LayoutTopAlertProps} from "./types";
import classNames from "classnames";

/**
 * Предупреждение строкой во всю ширину, оформленное как Alert--danger
 * По умолчанию является предупреждением об устаревшей версии
 */
const LayoutTopAlert: React.VFC<LayoutTopAlertProps> = ({
  message,
  className,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'common'});
  
  if (message) {
    return <>
      <div
        className={classNames(styles['top-alert'], className)}
        style={{backgroundColor: message.backgroundColor}}
        // вставляем html с сервера, т.к. он проверенный
        dangerouslySetInnerHTML={{__html: message.content}}
      />
      <div
        className={classNames(styles['top-alert'], styles['top-alert--offset'], className)}
        // вставляем html с сервера, т.к. он проверенный
        dangerouslySetInnerHTML={{__html: message.content}}
      />
    </>
  }
  
  const needToUpdateMessage = <>
    {t('needToUpdatePage')}
    &nbsp;
    <span className='fw-600' role={'button'} onClick={() => window.location.reload()}>Обновить</span>
  </>
  
  return <>
    <div className={classNames(
      styles['top-alert'],
      styles['top-alert--default'],
      className)}>
      {needToUpdateMessage}
    </div>
    <div className={classNames(
      styles['top-alert'],
      styles['top-alert--default'],
      styles['top-alert--offset'],
      className)}>
      {needToUpdateMessage}
    </div>
  </>
}

export default LayoutTopAlert;
