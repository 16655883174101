import * as types from "../constants/notify";

interface NotifyState {
    message: string | null,
}

export type NotifyAction = {
    type: typeof types.ADD_NOTIFY,
    payload: string
} | {
    type: typeof types.REMOVE_NOTIFY
}

/**
 * Начальные данные стора
 * message - сообщение
 * symbol - уникальный символ каждого сообщения
 * @type {{symbol: null, message: null}}
 */
const initialState: NotifyState = {
    message: null
};

/**
 * функция reducer
 * @returns {{symbol: null, message: null}|{symbol: *, message: *}}
 */
export default function notify(
    state = initialState,
    action: NotifyAction
): NotifyState {
    switch (action.type) {
        case types.ADD_NOTIFY:
            return {
                message: action.payload,
            };
        case types.REMOVE_NOTIFY:
            return {
                message: null,
            };
        default:
            return state;
    }
}
