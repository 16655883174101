import React, {useEffect, useMemo, useRef, useState} from 'react';
import styles from "./OrdersListItem.module.scss";
import {OrdersListItemProps} from "./types";
import Figure from "../../../../../common/components/Figure";
import {
  getOrderTimingInfo,
  isOrderNew,
  shouldDisplayOrderTimerString,
} from "../../../helpers";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import classNames from 'classnames';
import {useAppSelector} from "../../../../../app/store";
import {orderIdsThatNeedAttentionSelector, ordersDateHeaderSelector} from "../../../reducer";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import OrderTimerDisplay from "./OrderTimerDisplay";

const OrderListItem: React.VFC<OrdersListItemProps> = ({
  order,
  onClick,
  className,
  state,
  statusChangeHandler,
}) => {
  
  const {
    id,
    company,
    city,
    deliveryDateTime,
    timeForReady,
    isPayed,
    notCall,
    allowTransitions,
    isEmenuCourier,
    status,
    dateTimeReadyStart,
  } = order;

  const {t} = useTranslation('translation', {keyPrefix: 'features.orders'});
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

  const orderIdsThatNeedAttention = useAppSelector(orderIdsThatNeedAttentionSelector);

  /** требует ли заказ внимания прямо сейчас */
  const [needsAttention, setNeedsAttention] = useState(false);

  useEffect(() => {
    setNeedsAttention(
      orderIdsThatNeedAttention.length > 0 && !!orderIdsThatNeedAttention.find((x) => x === id)
    );
  }, [orderIdsThatNeedAttention, id])

  const ordersDateHeader = useAppSelector(ordersDateHeaderSelector);
  
  /** статус монтирования компонента для присвоения результатов асинхронной операции */
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    }
  }, []);
  
  const orderTiming = getOrderTimingInfo(order);
  
  /** переходы по статусу, продвигающие заказ */
  const advanceTransitions = useMemo(
    () => allowTransitions?.filter(({statusId}) => statusId > 0) ?? [],
    [allowTransitions]);
  
  /** переходы по статусу, отменяющие заказ */
  const cancelTransitions = useMemo(
    () => allowTransitions?.filter(({statusId}) => statusId <= 0) ?? [],
    [allowTransitions]);
  
  let buttons = <>
    {advanceTransitions.map(({statusId, title, additionalForm, color}) =>
      <Button
        className="ms-0 ms-lg-2 mt-2 mt-lg-0"
        key={statusId}
        onClick={e => {
          e.stopPropagation();
          statusChangeHandler({orderId: id, statusTitle: title, isShowDateTimeReadyStart: !!(deliveryDateTime && !isEmenuCourier), additionalForm, statusId, timeForReady, dateTimeReadyStart})
        }}
        variant={color}>
        {title}
      </Button>)}
  
    {!!cancelTransitions.length && <DropdownButton
        className="ms-0 ms-lg-2 mt-2 mt-lg-0"
        variant="danger"
        id="status-dropdown"
        title={<span className="has-caret">{commonT('actionCancel')}</span>}>
      {cancelTransitions.map(({statusId, title, additionalForm}) =>
        <Dropdown.Item
          key={statusId}
          onClick={e => {
            e.stopPropagation();
            statusChangeHandler({orderId: id, statusTitle: title, isShowDateTimeReadyStart: !!(deliveryDateTime && !isEmenuCourier), additionalForm, statusId, timeForReady, dateTimeReadyStart})
          }}>
          {title}
        </Dropdown.Item>
      )}
    </DropdownButton>}
  </>
  
  const isShowingTimer = isOrderNew({status}) && shouldDisplayOrderTimerString(order);
  
  /**
   * Если заказ Новый, вместо кнопок изменения статуса показывается кнопка "Открыть"
   */
  if (isOrderNew({status})) {
    buttons = <>
      {isShowingTimer && !!ordersDateHeader && <OrderTimerDisplay order={order} dateHeader={ordersDateHeader} variant={'primary'}/>}
      <Button onClick={() => onClick(id)} variant={"primary"}>
        {commonT('actionOpen')}
      </Button>
    </>
  }
  
  return <div
    className={classNames(
      styles["item"],
      needsAttention && styles["item--highlighted"],
      isShowingTimer && styles['item--row-actions'],
      state?.map(cl => styles[cl]),
      className,
    )}
    onClick={() => onClick(id)}>
    <div className={"d-flex flex-column flex-lg-row align-self-lg-start flex-wrap justify-content-center justify-content-sm-start"}>
    <div className={classNames(styles["item-head"])}>
      <u>
        <h4 className="mb-0_5">№ {id}</h4>
      </u>
      <div className={styles["item-head-desc"]}>{company?.name}, {city?.name}</div>
    </div>

    <div className={classNames(styles["item-section"])}>
      <Figure
        icon="timer"
        label={t(orderTiming.labelKey)}
        value={orderTiming.value
          ? <span className={styles["item-delivery--to-time"]}>{orderTiming.value}</span>
          : <span>{t('ASAP')}</span>}
        variant={"orange"}
      />
    </div>

    {timeForReady && <div className={classNames(styles["item-section"])}>
      <Figure
        icon="local_fire_department"
        label={t('cooking')}
        value={<>{timeForReady} мин</>}
        variant={"orange"}
      />
    </div>}

    {typeof isPayed === "boolean" && <div className={classNames(styles["item-section"])}>
      <Figure
        icon="credit_score"
        value={isPayed ? t('orderPaid') : t('orderNotPaid')}
        variant={"orange"}
      />
    </div>}

    {notCall && <div className={classNames(styles["item-section"])}>
      <Figure
        icon="phone_disabled"
        value={t('notCall')}
        variant={"orange"}
      />
    </div>}

    </div>

    <div
      className={classNames(styles["item-actions"], "d-flex")}
      onClick={e => e.stopPropagation()}>
      {buttons}
    </div>
  </div>
};

export default OrderListItem;
