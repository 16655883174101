import React from "react";
import {useTranslation} from "react-i18next";
import Modal from "react-bootstrap/Modal";
import {Form, Formik} from "formik";
import {OrderItemQuantityChangeDialogProps} from "./types";
import Button from "react-bootstrap/Button";
import * as Yup from 'yup';
import Counter from "../../../../../common/components/Counter";

const validationSchema = Yup.object().shape({
  quantity: Yup.number().min(0).nullable()
})

const OrderItemQuantityChangeDialog: React.VFC<OrderItemQuantityChangeDialogProps> = ({
  info,
  closeDialog,
  onSubmit,
}) => {
  
  const {t} = useTranslation();
  
  return <Modal show={!!info} onHide={closeDialog} centered>
    <Modal.Header closeButton>
      <Modal.Title>{t(`features.orders.actionEditOrderItem`)}</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={{
        quantity: info?.quantity ?? 0,
      }}
      validationSchema={validationSchema}
      onSubmit={({quantity}) => {
        
        if (info) {
          onSubmit({
            ...info,
            quantity,
          });
        }
      }}>
      {({isValid}) =>
        <Form>
          <Modal.Body>
            <Counter
              name={"quantity"}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeDialog}>
              {t('common.actionCancel')}
            </Button>
            <Button variant="outline-primary"
                    type="submit"
                    disabled={!isValid}
            >
              {t('common.actionSubmit')}
            </Button>
          </Modal.Footer>
        </Form>}
    </Formik>
  </Modal>
}

export default OrderItemQuantityChangeDialog;
