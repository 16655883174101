import React from 'react';
import Select, {Theme, Props, GroupBase} from "react-select";
import NoOptionsMessage from "./components/NoOptionsMessage";

/**
 * Обертка селекта с темой приложения
 */
function SelectWrapper<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>({
  components,
  ...props
}: Props<Option, IsMulti, Group>) {
  return <Select<Option, IsMulti, Group>
    components={{
      NoOptionsMessage,
      ...components,
    }}
    styles={{
      valueContainer: (provided) => ({
        ...provided,
        paddingLeft: 12,
        paddingRight: 12,
      }),
      singleValue: (provided) => ({
        ...provided,
        margin: 0,
      }),
      input: (provided) => ({
        ...provided,
        margin: 0,
      })
    }}
    // styles={(provided) => ({
    //
    // })}
    theme={getSelectTheme}
    {...props}
  />;
}

/**
 * Функция получения темы селекта с вшитыми цветами панели
 */
const getSelectTheme = (theme: Theme): Theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: '#f9612b', // $orange
    primary25: '#fba080', // $orange-300 - фон опции при наведении
    neutral20: '#dddddd', // $gray-300 - граница
    neutral30: '#ced4da', // $gray-400 - граница при наведении
  },
})

export default SelectWrapper;
