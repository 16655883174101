export const LOGIN_REQUEST = Symbol('auth/LOGIN_REQUEST');

export const LOGIN_SUCCESS = Symbol('auth/LOGIN_SUCCESS');

export const LOGIN_FAILURE = Symbol('auth/LOGIN_FAILURE');

export const LOGOUT = Symbol('auth/LOGOUT');

export const GET_USER_REQUEST = Symbol('auth/GET_USER');

export const GET_USER_SUCCESS = Symbol('auth/GET_SUCCESS');

export const GET_USER_FAILURE = Symbol('auth/GET_FAILURE');

export const COMPANY_CHANGE_REQUEST = Symbol('auth/CHANGE_LOADING');

export const COMPANY_CHANGE_FAILURE = Symbol('auth/CHANGE_LOADING');

export const ENABLE_COMPANY = Symbol('auth/ENABLE_COMPANY');

export const ENABLE_COMPANY_FAILURE = Symbol('auth/ENABLE_COMPANY_FAILURE')

export const DISABLE_COMPANY = Symbol('auth/DISABLE_COMPANY');

export const DISABLE_COMPANY_FAILURE = Symbol('auth/DISABLE_COMPANY_FAILURE')


export const UPDATE_COMPANY = Symbol('auth/UPDATE_COMPANY');

export const CHANGE_CURRENT_COMPANY = Symbol('auth/CHANGE_COMPANY');

export const CHANGE_TIMESTAMP = Symbol('auth/CHANGE_TIMESTAMP');

export const UPDATE_PARTNER = Symbol('auth/UPDATE_PARTNER');
