import React from 'react';
import DatePicker from "react-datepicker";
import {parse, format} from "date-fns";
import {UsedDateTimeFormats} from "../../utils/dateTimeHelpers";
import ru from "date-fns/locale/ru";
import {DateTimePickerProps} from "./types";
import TextInput from "../TextInput";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-override.css";
import {useTranslation} from "react-i18next";

const DateTimePicker: React.VFC<DateTimePickerProps> = (
  props
) => {
  
  const {i18n: {language}, t} = useTranslation();
  const locale = dateTimePickerLocales[language];
  
  const name = props.name || props.field?.name;
  let value = props.value || props.field?.value;
  const onChange = props.onChange || props.form?.setFieldValue;

  /**
   * Проверка на соответствие формату yyyy-mm-dd hh:mm
   * */
  if (value.length !== 0 && ! /^\d{2}\.\d{2}\.\d{4} (2[0-3]|[01][0-9]):[0-5][0-9]$/.test(value)) {
    value = format(new Date(), UsedDateTimeFormats.DisplayedInPicker)
  }

  /**
   * в react-datepicker value может быть массивом дат для представления диапазона,
   * но здесь это представление не используется
   */
  const changeHandler = (value: Date | [Date, Date] | null) => {
    if (!onChange || Array.isArray(value)) {
      return;
    }
    
    onChange(name, value);
    
    blurHandler();
  };
  
  const blurHandler = () => {
    props.form?.setFieldTouched(name);
  };
  
  return <DatePicker
    selected={value
      ? parse(
          value,
          UsedDateTimeFormats.DisplayedInPicker,
          new Date(),
          // изначально locale может быть указана en-US, из-за чего даты могут парситься неправильно
          // (в en-US месяц.число.год вместо число.месяц.год)
          {locale: ru}
        )
      : null}
    onChange={changeHandler}
    onBlur={blurHandler}
    name={name}
    dateFormat={UsedDateTimeFormats.DisplayedInPicker}
    timeInputLabel={`${t('common.time')}: `}
    locale={locale}
    showTimeInput
    customInput={<TextInput
      className={'form-control'}
      icon={'calendar_month'}
      mask={[/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ':', /\d/, /\d/]}
      {...props}
    />}
  />;
};

export const dateTimePickerLocales: Record<string, Locale> = {
  'ru': ru,
}

export default DateTimePicker;
