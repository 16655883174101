import React, {useMemo} from 'react';
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {Field, Formik} from "formik";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import BootstrapForm from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Checkbox from "../../../../../common/components/Checkbox";
import {
  OrderPaymentTypes,
  OrderDeliveryTypes,
  orderStatusGroupNames,
  ArchiveFilterDateTimeValues, getFormattedDateSometimeAgo,
} from "../../../../orders/helpers";
import {useAppSelector} from "../../../../../app/store";
import {employeeDataSelector} from "../../../../auth/reducer";
import SelectWrapper from "../../../../../common/components/SelectWrapper";
import TextButton from "../../../../../common/components/TextButton";
import DateTimePicker from "../../../../../common/components/DateTimePicker";
import {subDays, subWeeks, subMonths, format} from "date-fns";
import {
  getDateInServerFormat,
  mapPickerDateToServerDate,
  parseServerDate,
  UsedDateTimeFormats
} from "../../../../../common/utils/dateTimeHelpers";
import {OrdersFilterDialogFilter, OrdersFilterDialogProps} from "./types";
import {OrderStatusGroupIds} from "../../../../orders/constants";

const datetimeLocaleKeys: Record<ArchiveFilterDateTimeValues, string | {key: string; count: number;}> = {
  [ArchiveFilterDateTimeValues.TODAY]: "today",
  [ArchiveFilterDateTimeValues.YESTERDAY]: "yesterday",
  [ArchiveFilterDateTimeValues.LAST_WEEK]: "lastWeek",
  [ArchiveFilterDateTimeValues.LAST_15_DAYS]: {key: 'lastCountDays', count: 15},
  [ArchiveFilterDateTimeValues.LAST_30_DAYS]: {key: 'lastCountDays', count: 30},
  [ArchiveFilterDateTimeValues.LAST_6_MONTHS]: {key: 'lastCountMonths', count: 6},
  [ArchiveFilterDateTimeValues.CUSTOM]: "custom",
}

/**
 * Опции групп статусов архивных заказов (заказы "В работе" не будут в архиве)
 */
const archiveStatusIdOptions = [
  OrderStatusGroupIds.Cancelled,
  OrderStatusGroupIds.Delivered,
  OrderStatusGroupIds.TimeForProcessingExpired,
]
  .map(id => ({
    value: id,
    label: orderStatusGroupNames[id],
  }))

/**
 * Диалог фильтра заказов
 */
const OrdersFilterDialog: React.VFC<OrdersFilterDialogProps> = ({
  show,
  closeHandler,
  currentFilter,
  submitHandler,
}) => {
  
  const {t} = useTranslation();
  
  const {
    companies
  } = useAppSelector(employeeDataSelector) ?? {};
  
  const companyOptions = companies?.map(({id, name}) => ({value: id, label: name})) ?? [];
  
  const dateTimeOptions = useMemo(() =>
    Object.entries(datetimeLocaleKeys).map(([value, labelInfo]) => ({
      value,
      label: typeof labelInfo === 'string'
        ? t(`common.${labelInfo}`)
        : t(`common.${labelInfo.key}`, {count: labelInfo.count})
    })),
  [t])
  
  type Values = Required<OrdersFilterDialogFilter>;
  
  const resetValues: Values = {
    companyIds: [],
    statusTypeIds: [],
    deliveryTypeIds: [],
    payTypeIds: [],
    /** частые стандартные опции даты/времени заказа */
    _dateTime: '',
    dateTimeStart: '',
    dateTimeEnd: '',
    /**
     * суффикс часового пояса
     * @deprecated - не используется, но может понадобиться в будущем
     */
    // _dateTimeSuffix: '',
  }
  
  const initialValues: Values = {
    companyIds: currentFilter?.companyIds || resetValues.companyIds,
    statusTypeIds: currentFilter?.statusTypeIds || resetValues.statusTypeIds,
    deliveryTypeIds: currentFilter?.deliveryTypeIds || resetValues.deliveryTypeIds,
    payTypeIds: currentFilter?.payTypeIds || resetValues.payTypeIds,
    _dateTime: currentFilter?._dateTime ?? resetValues._dateTime,
    dateTimeStart: currentFilter?.dateTimeStart
      ? format(parseServerDate(currentFilter.dateTimeStart), UsedDateTimeFormats.DisplayedInPicker)
      : resetValues.dateTimeStart,
    dateTimeEnd: currentFilter?.dateTimeEnd
      ? format(parseServerDate(currentFilter.dateTimeEnd), UsedDateTimeFormats.DisplayedInPicker)
      : resetValues.dateTimeEnd,
  };
  
  return <Modal show={show} onHide={closeHandler} centered size="lg">
    <Modal.Header closeButton>
      <Modal.Title>{t(`features.archive.filters`)}</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const form: Partial<typeof initialValues> = {...values};
        
        (['companyIds', 'statusTypeIds', 'deliveryTypeIds', 'payTypeIds'] as ['companyIds', 'statusTypeIds', 'deliveryTypeIds', 'payTypeIds'])
          .forEach((key) => {
            if (!form[key]?.length) {
              delete form[key];
            }
          })
        
        if (form._dateTime !== ArchiveFilterDateTimeValues.CUSTOM) {
          delete form.dateTimeStart;
          delete form.dateTimeEnd;
        }
        
        switch (form._dateTime) {
          case ArchiveFilterDateTimeValues.TODAY:
            form.dateTimeStart = getDateInServerFormat((new Date()).setHours(0, 0, 0));
            break;
          case ArchiveFilterDateTimeValues.YESTERDAY: {
            const yesterday = subDays(new Date(), 1);
            form.dateTimeStart = getDateInServerFormat(yesterday.setHours(0, 0, 0));
            form.dateTimeEnd = getDateInServerFormat(yesterday.setHours(23, 59, 59));
            break;
          }
          case ArchiveFilterDateTimeValues.LAST_WEEK:
            form.dateTimeStart = getDateInServerFormat(subWeeks(new Date(), 1).setHours(0, 0, 0));
            break;
          case ArchiveFilterDateTimeValues.LAST_15_DAYS:
            form.dateTimeStart = getFormattedDateSometimeAgo({daysAgo: 15});
            break;
          case ArchiveFilterDateTimeValues.LAST_30_DAYS:
            form.dateTimeStart = getFormattedDateSometimeAgo({daysAgo: 30});
            break;
          case ArchiveFilterDateTimeValues.LAST_6_MONTHS:
            form.dateTimeStart = getDateInServerFormat(subMonths(new Date(), 6).setHours(0, 0, 0));
            break;
          case '':
            delete form.dateTimeStart;
            delete form.dateTimeEnd;
            break;
          case ArchiveFilterDateTimeValues.CUSTOM:
            if (form.dateTimeStart) {
              form.dateTimeStart = mapPickerDateToServerDate(form.dateTimeStart);
            } else {
              delete form.dateTimeStart;
            }
            
            if (form.dateTimeEnd) {
              form.dateTimeEnd = mapPickerDateToServerDate(form.dateTimeEnd);
            } else {
              delete form.dateTimeEnd;
            }
            break;
          default:
            break;
        }
  
        /**
         * Если присутствует, то оставляется в фильтре, но не используется в форме запроса.
         * Используется для показа выбора даты/времени при повторном открытии диалога фильтров
         */
        if (!form._dateTime) {
          delete form._dateTime;
        }
        
        submitHandler(Object.keys(form).length ? form : null);
        closeHandler();
      }}>
      {({values, setFieldValue, submitForm, resetForm}) => {
        return <Form>
          <Modal.Body>

            {companies && companies.length > 1 && <>
              <BootstrapForm.Label htmlFor="companyIds">{t(`features.archive.chooseCompany`)}</BootstrapForm.Label>
              <SelectWrapper
                options={companyOptions}
                isMulti
                onChange={data => {
                  setFieldValue('companyIds', data.map(({value}) => value))
                }}
                placeholder={t(`features.archive.chooseCompany`)}
                value={values.companyIds.map(companyId => ({
                  value: companyId,
                  label: companyOptions?.find(({value}) => value === companyId)?.label ?? '',
                }))}
              />
            </>}
  
            <BootstrapForm.Label className="mt-4" htmlFor="statusTypeIds">{t(`features.archive.orderStatusId`)}</BootstrapForm.Label>
            <SelectWrapper
              options={archiveStatusIdOptions}
              isMulti
              onChange={data => {
                setFieldValue('statusTypeIds', data.map(({value}) => value))
              }}
              placeholder={t(`features.archive.chooseStatusId`)}
              value={values.statusTypeIds.map(statusTypeId => ({
                value: statusTypeId,
                label: archiveStatusIdOptions.find(({value}) => value === statusTypeId)?.label ?? '',
              }))}
            />

            <BootstrapForm.Label className="mt-4" htmlFor="payTypeIds">{t(`features.archive.payTypeIds`)}</BootstrapForm.Label>
            <Row>
              <Col md={4} className="mb-2">
                <Checkbox
                  checked={values.payTypeIds.includes(OrderPaymentTypes.Cash)}
                  id={`payment-${OrderPaymentTypes.Cash}`}
                  label={t(`features.orders.paymentCash`)}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue('payTypeIds', [...values.payTypeIds, OrderPaymentTypes.Cash]);
                      return;
                    }
  
                    setFieldValue('payTypeIds', values.payTypeIds.filter(ptId => ptId !== OrderPaymentTypes.Cash));
                  }}
                />
              </Col>
              <Col md={4} className="mb-2">
                <Checkbox
                  checked={values.payTypeIds.includes(OrderPaymentTypes.ToCouriersCard)}
                  id={`payment-${OrderPaymentTypes.ToCouriersCard}`}
                  label={t(`features.orders.paymentCouriersCard`)}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue('payTypeIds', [...values.payTypeIds, OrderPaymentTypes.ToCouriersCard]);
                      return;
                    }
  
                    setFieldValue('payTypeIds', values.payTypeIds.filter(ptId => ptId !== OrderPaymentTypes.ToCouriersCard));
                  }}
                />
              </Col>
              <Col md={4} className="mb-2">
                <Checkbox
                  checked={values.payTypeIds.includes(OrderPaymentTypes.Online)}
                  id={`payment-${OrderPaymentTypes.Online}`}
                  label={t(`features.orders.paymentOnline`)}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue('payTypeIds', [...values.payTypeIds, OrderPaymentTypes.Online]);
                      return;
                    }
  
                    setFieldValue('payTypeIds', values.payTypeIds.filter(ptId => ptId !== OrderPaymentTypes.Online));
                  }}
                />
              </Col>
            </Row>
            
            <BootstrapForm.Label className="mt-3" htmlFor="deliveryTypeIds">{t(`features.archive.deliveryType`)}</BootstrapForm.Label>
            <Row>
              <Col md={4} className="mb-2">
                <Checkbox
                  checked={values.deliveryTypeIds.includes(OrderDeliveryTypes.Courier)}
                  id={`delivery-${OrderDeliveryTypes.Courier}`}
                  label={t(`features.orders.deliveryCourier`)}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue('deliveryTypeIds', [...values.deliveryTypeIds, OrderDeliveryTypes.Courier]);
                      return;
                    }
          
                    setFieldValue('deliveryTypeIds', values.deliveryTypeIds.filter(dtId => dtId !== OrderDeliveryTypes.Courier));
                  }}
                />
              </Col>
              <Col md={4} className="mb-2">
                <Checkbox
                  checked={values.deliveryTypeIds.includes(OrderDeliveryTypes.Self)}
                  id={`delivery-${OrderDeliveryTypes.Self}`}
                  label={t(`features.orders.deliverySelf`)}
                  onChange={e => {
                    if (e.target.checked) {
                      setFieldValue('deliveryTypeIds', [...values.deliveryTypeIds, OrderDeliveryTypes.Self]);
                      return;
                    }
          
                    setFieldValue('deliveryTypeIds', values.deliveryTypeIds.filter(dtId => dtId !== OrderDeliveryTypes.Self));
                  }}
                />
              </Col>
            </Row>
  
            <BootstrapForm.Label className="mt-4" htmlFor="_dateTime">{t(`features.archive.dateTimeInterval`)}</BootstrapForm.Label>
            <SelectWrapper
              options={dateTimeOptions}
              onChange={data => {
                setFieldValue('_dateTime', data?.value ?? '')
              }}
              placeholder={t(`features.archive.noIntervalSpecified`)}
              value={{
                value: values._dateTime,
                label: dateTimeOptions.find(({value}) => value === values._dateTime)?.label
                  ?? t(`features.archive.noIntervalSpecified`),
              }}
            />
            {values._dateTime === ArchiveFilterDateTimeValues.CUSTOM &&
              <Row>
                <Col md={6} className="mt-3">
                  <BootstrapForm.Label htmlFor="dateTimeStart">{t(`common.from`)}</BootstrapForm.Label>
                  <Field
                    name="dateTimeStart"
                    component={DateTimePicker}
                    onChange={(_: string, value: Date | null) => {
                      if (value) {
                        const formattedDate = format(value, UsedDateTimeFormats.DisplayedInPicker);
    
                        setFieldValue('dateTimeStart', formattedDate);
                      } else {
                        setFieldValue('dateTimeStart', '')
                      }
                    }}
                  />
                </Col>
                <Col md={6} className="mt-3">
                  <BootstrapForm.Label htmlFor="dateTimeEnd">{t(`common.to`)}</BootstrapForm.Label>
                    <Field
                      name="dateTimeEnd"
                      component={DateTimePicker}
                      onChange={(_: string, value: Date | null) => {
                        if (value) {
                          const formattedDate = format(value, UsedDateTimeFormats.DisplayedInPicker);
          
                          setFieldValue('dateTimeEnd', formattedDate);
                        } else {
                          setFieldValue('dateTimeEnd', '')
                        }
                      }}
                    />
                </Col>
              </Row>}
            {/* чтобы кнопка не растягивалась на всю ширину, чтобы нельзя было случайно сбросить */}
            <div className="d-flex justify-content-center mt-5">
              <TextButton
                onClick={() => resetForm({values: resetValues})}
                label={t('features.archive.resetFilter')}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeHandler}>
              {t('common.actionCancel')}
            </Button>
            <Button variant="outline-primary" onClick={submitForm}>
              {t('common.actionSubmit')}
            </Button>
          </Modal.Footer>
        </Form>
      }}
    </Formik>
  </Modal>;
};

export default OrdersFilterDialog;
