/**
 * Информация о контрагенте
 */
export type CompanyResponse = {
  /** ID компании */
  id: number;
  /** Название контрагента */
  name:	string;
  /** ID города */
  cityId:	number;
  /** Информация о городе */
  city?: CityResponse;
  /** Информация о контрагенте */
  partner?: PartnerResponse;
  /** Timestamp до какого времени закрыто заведение */
  closedUntil?: string | null;
  /** Время следующего открытия */
  nextOpenTime?: string | null;
  /** Id статуса заведения */
  status: CompanyStatuses;
  /** URL изображения заведения */
  imageUrl?: string;
  /** Рабочие дни */
  workdays?: CompanyWorkday[];
  /** Закрыто или нет заведение */
  isClosed?: boolean;
  /** Наличие курьерской службы */
  hasEmenuDelivery?: boolean;
}

export enum CompanyStatuses {
  /** Включена */
  Enabled = 1,
  /** Выключена */
  Disabled = 0,
  /** Закрыто за долг */
  DisabledDueToDebt = 2,
  /** Приостановлена */
  Paused = 3,
  /** Закрыто заведением */
  DisabledByCompany = 4,
}

/**
 * Информация о контрагенте
 */
export type CityResponse = {
  /** ID */
  id: number;
  /** Название города */
  name: string;
}

/**
 * Информация о контрагенте
 */
export type PartnerResponse = {
  /** Название контрагента */
  name:	string;
  /** Юридическое название контрагента */
  legalName: string;
  /** ФИО директора */
  directorContact?:	string | null;
  /** Контактные телефоны директора */
  directorPhone?:	string | null;
  /** Контактный email директора */
  directorEmail?:	string | null;
  /** ФИО о.л. бухгалтерии */
  financeContact?: string | null;
  /** Контактные телефоны бухгалтерии */
  financePhone?: string | null;
  financeEmail?: string | null;
  /** ФИО о.л. администрации */
  adminContact?: string | null;
  /** Контактные телефоны администрации */
  adminPhone?: string | null;
  adminEmail?: string | null;
  /** Задолженность контрагента */
  debt:	number;
  /** Ссылка для оплаты */
  payUrl?: string | null;
}

/**
 * Форма отключения заведения
 */
export type CompanyDisableForm = {
  /** Дата и время до которого необходимо отключить товар */
  dateTime: string | null;
  /** Пояснение */
  message: string;
}

export type CompanyWorkday = {
  /** День недели */
  day: number;
  /** Время начала */
  timeTo: string;
  /** Время конца */
  timeFrom: string;
}

/**
 * Форма изменения расписания дня
 */
export type CompanyWorkdayForm = {
  /** День недели */
  day: number;
  /** Время начала */
  timeTo: string;
  /** Время конца */
  timeFrom: string;
}
