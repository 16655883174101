import React, {memo, useMemo} from 'react';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "./CatalogListHeader.module.scss";
import Icon from "../../../../common/components/Icon";
import { useTranslation } from 'react-i18next';
import {CatalogListHeaderProps} from "./types";
import TextInput from "../../../../common/components/TextInput";
import Chip from "../../../../common/components/Chip";
import {ChipWithValue} from "../../../../common/components/Chip/types";

/**
 * Общие компоненты списков продуктов и атрибутов
 */
const CatalogListHeader: React.VFC<CatalogListHeaderProps> = ({
  filter,
  setItemsFilter,
  disabledItemsCount,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'common'});
  
  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchText = e.currentTarget.value;
    setItemsFilter(prevState => ({
      ...prevState,
      searchText
    }));
  }
  
  const chips: ChipWithValue[] = useMemo(() => ([
    {
      label: t('allItems'),
      value: null,
      onClick: () =>
        setItemsFilter(prevState => ({
          ...prevState,
          isEnabled: null,
        })),
    },
    {
      label: t('enabledItems'),
      value: true,
      onClick: () =>
        setItemsFilter(prevState => ({
          ...prevState,
          isEnabled: true,
        })),
    },
    {
      label: t('disabledItems'),
      value: false,
      count: disabledItemsCount,
      onClick: () =>
        setItemsFilter(prevState => ({
          ...prevState,
          isEnabled: false,
        })),
    },
  ]), [disabledItemsCount, setItemsFilter, t]);
  
  return <>
    <Row className="mt-0 mt-md-4 mb-2 mb-md-3">
      <Col md={4} className={styles["search-field"]}>
        <TextInput
          aria-label="search"
          placeholder={t(`search`)}
          // className={styles["search-field"]}
          value={filter.searchText}
          onChange={onSearchChange}
        />
        <Icon icon="search" className={styles["search-field-icon"]}/>
      </Col>
    </Row>

    <div className="d-flex flex-wrap mb-3">
      {chips.map((chip) =>
        <Chip
          key={chip.label}
          state={[`chip--${filter.isEnabled === chip.value ? "active" : "light"}`]}
          className="mb-2"
          {...chip}
        />)}
    </div>
  </>;
};

export default memo(CatalogListHeader);
