import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/App';
import Notify from "./common/components/Notify";
import {Provider} from "react-redux";
import {store} from "./app/store";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import './assets/styles/index.scss';
import './app/i18n';
/**
 * https://www.i18next.com/how-to/faq#why-are-my-plural-keys-not-working
 */
import 'intl-pluralrules';

window.onload = function() {
    ReactDOM.render(
        <Provider store={store}>
            <Notify/>
            <App/>
        </Provider>,
        document.getElementById('root')
    );
}

// Регистрация для PWA
// Если необходимо отключить, то нужно заменить register() на unregister()
// Больше про service worker-ы: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
