import * as types from './constants';
import {AttributeItemResponse, AttributeResponse} from "./models";
import {RootState} from "../../../../app/store";
import {CatalogItemCompositeIdParts} from "../../utils/helpers";
import {spreadInData} from "../../../../common/utils/functions";

type CatalogAttributesState = {
  data: AttributeResponse[];
  loading: boolean;
  error: string | null;
}

type ReplaceAttributeObject = {
  compositeIdParts: CatalogItemCompositeIdParts[];
  form: Partial<AttributeItemResponse>;
}

const replaceAttributes = (
  data: AttributeResponse[],
  {compositeIdParts, form}: ReplaceAttributeObject,
): AttributeResponse[] => {
  const nextAttributes = [...data];
  
  compositeIdParts.forEach(({
    sectionId,
    portionId,
  }) => {
    const attribute = nextAttributes.find(({id}) => id === sectionId);
    
    if (!attribute || !attribute.items) {
      return;
    }
    
    attribute.items = spreadInData(attribute.items, {id: portionId, ...form})
  })
  
  return nextAttributes;
}

export type CatalogAttributesAction = {
  type: typeof types.GET_ATTRIBUTES_REQUEST;
} | {
  type: typeof types.GET_ATTRIBUTES_SUCCESS;
  payload: AttributeResponse[];
} | {
  type: typeof types.GET_ATTRIBUTES_FAILURE;
  payload: string;
} | {
  type: typeof types.REPLACE_ATTRIBUTES;
  payload: ReplaceAttributeObject;
}

const initialState: CatalogAttributesState = {
  data: [],
  loading: false,
  error: null,
}

export const catalogAttributesSelector = ({catalogAttributes: {data}}: RootState) => data;
export const catalogAttributesLoadingSelector = ({catalogAttributes: {loading}}: RootState) => loading;
export const catalogAttributesErrorSelector = ({catalogAttributes: {error}}: RootState) => error;

export default function catalogAttributes(
  state = initialState,
  action: CatalogAttributesAction,
): CatalogAttributesState {
  switch (action.type) {
    case types.GET_ATTRIBUTES_REQUEST:
      return {
        data: [],
        loading: true,
        error: null,
      }
    case types.GET_ATTRIBUTES_SUCCESS:
      return {
        data: action.payload,
        loading: false,
        error: null,
      }
    case types.GET_ATTRIBUTES_FAILURE:
      return {
        data: [],
        loading: false,
        error: action.payload,
      }
    case types.REPLACE_ATTRIBUTES:
      return {
        ...state,
        data: replaceAttributes(state.data, action.payload),
      }
    default:
      return state;
  }
}
