import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../../../app/store";
import {
  CatalogFilter,
  CatalogItem,
  CatalogItemEditInfo,
  filterCatalogItems
} from "../../../../utils/helpers";
import {flatProductsReSelector} from "../../reselectors";
import {disablePortions, enablePortions, getCatalogProducts} from "../../actions";
import CatalogSelectionActionMenu from "../../../../components/CatalogSelectionActions";
import CatalogListItemOutput from "../../../../components/CatalogListItemOutput";
import {currentCompanyIdSelector, employeeDataSelector} from "../../../../../auth/reducer";
import CatalogListHeader from "../../../../components/CatalogListHeader";
import {useTranslation} from "react-i18next";
import DisableParamsDialog from "../../../../../../common/components/DisableParamsDialog";
import {catalogProductsErrorSelector, catalogProductsLoadingSelector} from "../../reducer";
import Loader from "../../../../../../common/components/Loader";
import ErrorPlug from "../../../../../../common/components/ErrorPlug";
import {EntityDisableForm} from "../../../../../../common/components/DisableParamsDialog/types";
import ProductPriceChangeDialog from "../ProductPriceChangeDialog";

/**
 * Вывод списка блюд
 */
const ProductList: React.VFC = () => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation('translation', {keyPrefix: 'features.catalog.products'});
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

  const companyId = useAppSelector(currentCompanyIdSelector);
  const {
    companies
  } = useAppSelector(employeeDataSelector) ?? {};

  const currentCompany = companies?.find(({id}) => id === companyId);
  
  useEffect(() => {
    if (companyId) {
      dispatch(getCatalogProducts(companyId, ''));
    }
  }, [companyId, dispatch]);
  
  const items = useAppSelector(flatProductsReSelector);
  const loading = useAppSelector(catalogProductsLoadingSelector);
  const error = useAppSelector(catalogProductsErrorSelector);
  
  /** фильтр */
  const [productsFilter, setProductsFilter] = useState<CatalogFilter>({searchText: '', isEnabled: null});
  
  /** значения фильтра примитивами для зависимости useMemo */
  const searchText = productsFilter.searchText;
  const isEnabled = productsFilter.isEnabled;
  
  /** прошедшие фильтр блюда */
  const filteredItems = useMemo(
    () => filterCatalogItems(items, {searchText, isEnabled}),
    [items, searchText, isEnabled]
  );
  
  /** id порции для изменения в модальном окне */
  const [portionInfoToEdit, setPortionInfoToEdit] = useState<CatalogItemEditInfo | null>(null);
  
  const openPortionEditDialog = useCallback((info: CatalogItemEditInfo) => setPortionInfoToEdit(info), []);
  const closePortionEditDialog = useCallback(() => setPortionInfoToEdit(null), []);
  
  /** список выделенных позиций */
  const [selectedItems, setSelectedItems] = useState<CatalogItem[]>([]);
  
  const selectedIds = useMemo(
    () => selectedItems.map(({compositeId}) => compositeId),
    [selectedItems]
  )
  
  const disableItemsDialogDescription = <div>
    {commonT(`disableForHowMuch`)}
    &nbsp;
    {selectedItems.map((item, index) =>
      <React.Fragment key={item.compositeId}>
        <span className="fw-600">{item.name}</span>
        {index !== selectedItems.length - 1 && ', '}
      </React.Fragment>)}
    ?
  </div>
  
  /** статус показа диалога деактивации */
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  
  const openDisableItemsDialog = useCallback(() => setIsDisableDialogOpen(true), []);
  const closeDisableItemsDialog = useCallback(() => setIsDisableDialogOpen(false), []);
  
  const changeOneSelectionHandler = (item: CatalogItem, nextStatus: boolean) => {
    setSelectedItems(prevState => {
      
      if (nextStatus) {
        return prevState.includes(item) ? prevState : [...prevState, item];
      }
      
      const index = prevState.indexOf(item);
      
      return index === -1
        ? prevState
        : [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1)
        ];
    });
  }
  
  const deselectAll = useCallback(() => setSelectedItems([]), [])
  
  const getSectionCheckboxStatus = useCallback((items: string[]) => {
    const statuses = items.map(id => selectedIds.includes(id));
    
    if (statuses.includes(true)) {
      if (statuses.includes(false)) {
        return "partial";
      }
      
      return "checked";
    }
    
    return null;
  }, [selectedIds])
  
  const changeSectionSelectionHandler = useCallback((items: CatalogItem[]) => {
    const status = getSectionCheckboxStatus(items.map(({compositeId}) => compositeId));
    
    switch (status) {
      case "checked":
        items.forEach(id => changeOneSelectionHandler(id, false));
        break;
      case "partial":
      case null:
      default:
        items.forEach(id => changeOneSelectionHandler(id, true));
        break;
    }
  }, [getSectionCheckboxStatus])
  
  const enablePortionHandler = (portionId: string) => {
    dispatch(enablePortions([portionId]))
  }
  
  const enableSelectedHandler = () => {
    dispatch(enablePortions(selectedIds))
    deselectAll();
  }
  
  const disableSelectedHandler = useCallback(({dateTime}: EntityDisableForm) => {
    dispatch(disablePortions(selectedIds, dateTime))
    deselectAll();
  }, [deselectAll, dispatch, selectedIds])
  
  let content = <Loader/>
  
  if (!loading) {
    if (error) {
      content = <ErrorPlug className={"mb-0"}>{error}</ErrorPlug>
    } else {
      content = <>
        {!!filteredItems.length
          ? filteredItems.map(item => {
        
            const {
              portionSize,
              ...rest
            } = item;
        
            let displayedDesc = portionSize;
            let checkboxStatus: "checked" | "partial" | null = selectedIds.includes(item.compositeId) ? "checked" : null;
        
            let selectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
              changeOneSelectionHandler(item, e.target.checked)
            }
        
            switch (item.level) {
              case "first": {
                const innerProducts = filteredItems.filter(({level, compositeId}) =>
                  level === "third" && (compositeId.startsWith(`${item.compositeId}.`) || compositeId.startsWith(`${item.compositeId}-`)));
            
                const ids = innerProducts.map(({compositeId}) => compositeId);
            
                displayedDesc = t(`product`, {count: innerProducts.length})
            
                selectHandler = () => changeSectionSelectionHandler(innerProducts)
                checkboxStatus = getSectionCheckboxStatus(ids)
                break;
              }
              case "second": {
                const innerProducts = filteredItems
                  .filter(({level, compositeId}) => level === "third" && compositeId.startsWith(`${item.compositeId}.`))
            
                const ids = innerProducts.map(({compositeId}) => compositeId);
            
                displayedDesc = t(`product`, {count: innerProducts.length})
            
                selectHandler = () => changeSectionSelectionHandler(innerProducts)
                checkboxStatus = getSectionCheckboxStatus(ids)
                break;
              }
              case "third":
                break;
            }
        
            return <CatalogListItemOutput
              key={item.compositeId}
              portionSize={displayedDesc}
              enableHandler={enablePortionHandler}
              editHandler={openPortionEditDialog}
              selectHandler={selectHandler}
              checkboxStatus={checkboxStatus}
              {...rest}
            />
          })
          : <div className="d-flex justify-content-center">
              <h2 className="mb-0">{t('itemsNotFound')}</h2>
            </div>}
      </>
    }
  }
  
  return <>
    <CatalogListHeader
      filter={productsFilter}
      setItemsFilter={setProductsFilter}
      disabledItemsCount={items.filter(({level, enabled}) => level === "third" && !enabled).length}
    />
  
    {content}
  
    {!!selectedIds.length &&
      <CatalogSelectionActionMenu
          selectedCount={selectedIds.length}
          enableHandler={enableSelectedHandler}
          disableHandler={openDisableItemsDialog}
          closeHandler={deselectAll}
      />}
  
    <ProductPriceChangeDialog
      info={portionInfoToEdit}
      closeDialog={closePortionEditDialog}
    />
    
    <DisableParamsDialog
      nextOpenTime={currentCompany?.nextOpenTime ?? null}
      title={t(`actionDisableItems`)}
      description={disableItemsDialogDescription}
      onSubmit={disableSelectedHandler}
      isOpen={isDisableDialogOpen}
      closeDialog={closeDisableItemsDialog}
    />
  </>;
}

export default ProductList;
