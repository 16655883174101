import * as types from "../constants/notify";
import {AppThunkAction} from "../../app/store";
import {NotifyAction} from "../reducers/notify";

/**
 * Функция удаления уведомления
 */
export const removeNotify = (): AppThunkAction<NotifyAction> => dispatch => {
    dispatch({type: types.REMOVE_NOTIFY});
};

/**
 * Функция добавления уведомления
 */
export const addNotify = (message: string): AppThunkAction<NotifyAction> => dispatch => {
    dispatch({type: types.ADD_NOTIFY, payload: message})
};
