import {createSelector} from "reselect";
import {catalogAttributesSelector} from "./reducer";
import {AttributeResponse} from "./models";
import {CatalogItem} from "../../utils/helpers";

const flattenAttributes = (
  attributes: AttributeResponse[],
): CatalogItem[] => {
  const list: CatalogItem[] = [];

  attributes.forEach(attribute => {
    list.push({
      compositeId: attribute.id.toString(),
      level: 'first',
      name: attribute.name,
      enabled: true,
    })

    attribute.items?.forEach(item => {
      list.push({
        compositeId: `${attribute.id}._.${item.id}`,
        level: 'third',
        name: item.name,
        price: item.price,
        enabled: !!item.isEnabled,
        disabledUntil: item.disabledUntil,
      })
    })
  })

  return list;
}

export const flatAttributesReSelector = createSelector(
  [catalogAttributesSelector],
  flattenAttributes,
)
