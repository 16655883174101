import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Box from "../../../../common/components/Box";
import Icon from "../../../../common/components/Icon";
import {useTranslation} from 'react-i18next';
import {OrderProductItemForm, OrderProductResponse, OrderResponse, ProductTypes, TimeReadyForm} from "../../models";
import {
  getOrderReplaceEventName, getOrderTimingInfo,
  OrderDeliveryTypes,
  orderPaymentTypeNameKeys, shouldDisplayOrderTimerString
} from "../../helpers";
import {useHistory} from "react-router-dom";
import Button from "react-bootstrap/Button";
import Figure from "../../../../common/components/Figure";
import styles from "./OrderDetail.module.scss";
import classNames from "classnames";
import FormatPrice from "../../../../common/components/FormatPrice";
import Loader from "../../../../common/components/Loader";
import {requestOrder, requestOrderItemAdd, requestOrderItemEdit, requestOrderStatusChange} from "../../api";
import {buildAddressString, formatPhoneNumber, getAxiosErrorMessage} from "../../../../common/utils/functions";
import ErrorPlug from "../../../../common/components/ErrorPlug";
import OrderItemQuantityChangeDialog from "./dialogs/OrderItemQuantityChangeDialog";
import {OrderDetailProductProps, OrderDetailProps, OrderItemQuantityChangeInfo} from "./types";
import {addNotify} from "../../../../common/actions/notify";
import {useDispatch} from "react-redux";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from 'react-bootstrap/Dropdown';
import OrderAddItemDialog from "./dialogs/OrderAddItemDialog";
import TextButton from "../../../../common/components/TextButton";
import {OrderChangeStatusInfo} from "../OrderChangeStatusDialog/types";
import OrderChangeStatusDialog from "../OrderChangeStatusDialog";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrderComplainCourierDialog from "./dialogs/OrderComplainCourierDialog";
import {isSuccessComplainCourierSelector} from "../../reducer";
import {useAppSelector} from "../../../../app/store";
import OrderComplainCourierSuccessDialog from "./dialogs/OrderComplainCourierSuccessDialog";
import {CourierTypes, REPLACE_IN_ORDERS} from "../../constants";
import OrderNotCallDialog from "./dialogs/OrderNotCallDialog";
import OrderTimerDisplay from "../OrdersList/OrderListItem/OrderTimerDisplay";
import {getDateTimeTuple} from '../../../../common/utils/dateTimeHelpers';
import {getDateHeader} from "../../../../common/utils/apiHelpers";

/**
 * Просмотр заказа
 */
const OrderDetail: React.VFC<OrderDetailProps> = ({
  id,
  inModal = false,
}) => {

  /** id заказа как число */
  const orderId = Number(id);

  const {t} = useTranslation('translation', {keyPrefix: 'features.orders'});
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

  const history = useHistory();
  const goBack = history.length ? () => history.goBack() : () => history.push('/orders');

  /** данные, статус загрузки, ошибка (хранить их в глобальном redux нет смысла) */
  const [data, setData] = useState<OrderResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();

  /**
   * Заголовок Date запроса, чтобы можно было использовать серверное время для вычисления в таймере
   * (серверное время имеет фиксированный часовой пояс +05, в отличие от часового пояса девайса, который может изменяться)
   * См. reducer заказов.
   */
  const [dateHeader, setDateHeader] = useState<string | null>(null);
  
  const dispatch = useDispatch();
  /** статус монтирования компонента для присвоения результатов асинхронной операции */
  const isMounted = useRef(false);

  const getData = useCallback(() => {
    if (isMounted.current) {
      setLoading(true);
    }

    requestOrder(orderId, {expand: "products.attributes,company,city,orderDelivery.deliveryZone,orderSamovivoz,allowTransitions,courier"})
      .then(({data, headers}) => {
        if (isMounted.current) {
          setData(data);
          setDateHeader(getDateHeader(headers));
        }
      })
      .catch((err) => {
        if (isMounted.current) {
          setError(getAxiosErrorMessage(err));
        }
      })
      .finally(() => {
        if (isMounted.current) {
          setLoading(false);
        }
      })
  }, [orderId]);

  useEffect(() => {
    isMounted.current = true;

    getData();

    return () => {
      isMounted.current = false;
    }
  }, [getData]);
  
  /**
   * Замена заказа в просмотре при получении изменений из state
   */
  useEffect(() => {
    const replaceHandler = (e: CustomEvent<OrderResponse>) => {
      if (String(e.detail.id) === id && isMounted.current) {
        setData(e.detail);
      }
    }
    
    document.addEventListener(getOrderReplaceEventName(id), replaceHandler as EventListener);
    
    return () => {
      document.removeEventListener(getOrderReplaceEventName(id), replaceHandler as EventListener);
    }
  }, [id]);

  const [statusChangeInfo, setStatusChangeInfo] = useState<OrderChangeStatusInfo | null>(null);

  const openStatusChangeDialog = useCallback((info: OrderChangeStatusInfo) => setStatusChangeInfo(info), []);
  const closeStatusChangeDialog = useCallback(() => setStatusChangeInfo(null), []);

  /** информация о позиции для изменения количества в модальном окне */
  const [itemInfoToEdit, setItemInfoToEdit] = useState<OrderItemQuantityChangeInfo | null>(null);

  const openItemEditDialog = useCallback((info: OrderItemQuantityChangeInfo) => setItemInfoToEdit(info), []);
  const closeItemEditDialog = useCallback(() => setItemInfoToEdit(null), []);

  const changeItemQuantity = useCallback(({id, quantity}: OrderItemQuantityChangeInfo) => {
    closeItemEditDialog();

    requestOrderItemEdit(id, {quantity})
      .then(({data}) => {
        if (isMounted.current) {
          setData(data);
        }
  
        dispatch({
          type: REPLACE_IN_ORDERS,
          payload: data,
        });
      })
      .catch((err) => {
        dispatch(addNotify(getAxiosErrorMessage(err)));
      })
  }, [closeItemEditDialog, dispatch]);

  /** статус показа диалога добавления позиции */
  const [isAddItemDialog, setIsAddItemDialog] = useState(false);

  const openAddItemDialog = useCallback(() => setIsAddItemDialog(true), []);
  const closeAddItemDialog = useCallback(() => setIsAddItemDialog(false), []);

  const addItem = (form: OrderProductItemForm) => {
    closeAddItemDialog();
    requestOrderItemAdd(orderId, form)
      .then(({data}) => {
        if (isMounted.current) {
          setData(data);
        }
  
        dispatch({
          type: REPLACE_IN_ORDERS,
          payload: data,
        })
      })
      .catch((err) => {
        dispatch(addNotify(getAxiosErrorMessage(err)));
      })
  }

  /** переходы по статусу, продвигающие заказ */
  const advanceTransitions = useMemo(
    () => data?.allowTransitions?.filter(({statusId}) => statusId > 0) ?? [],
    [data?.allowTransitions]);

  /** переходы по статусу, отменяющие заказ */
  const cancelTransitions = useMemo(
    () => data?.allowTransitions?.filter(({statusId}) => statusId <= 0) ?? [],
    [data?.allowTransitions]);

  const [statusChangeLoading, setStatusChangeLoading] = useState(false);

  const changeOrderStatus = (form: TimeReadyForm | null) => {
    if (!statusChangeInfo) {
      return;
    }

    setStatusChangeLoading(true);
    const statusId = statusChangeInfo.statusId;
    closeStatusChangeDialog();

    requestOrderStatusChange(orderId, statusId, form)
      .then(({data}) => {
        if (isMounted.current) {
          setData(data);
        }
  
        dispatch({
          type: REPLACE_IN_ORDERS,
          payload: data,
        })
      })
      .catch((err) => {
        dispatch(addNotify(getAxiosErrorMessage(err)));
      })
      .finally(() => {
        if (isMounted.current) {
          setStatusChangeLoading(false);
        }
      })
  }

  const [isOpenComplainCourierDialog, setIsOpenComplainCourierDialog] = useState(false);

  const openComplainCourierDialog = () => {
    setIsOpenComplainCourierDialog(!isOpenComplainCourierDialog);
  }

  const closeComplainCourierDialog = () => {
    setIsOpenComplainCourierDialog(!isOpenComplainCourierDialog);
  }

  const complainCourierResult = useAppSelector(isSuccessComplainCourierSelector);

  const [isSuccessComplainCourier, setIsSuccessComplainCourier] = useState(complainCourierResult);

  useEffect(() => {
    setIsSuccessComplainCourier(complainCourierResult);
  }, [complainCourierResult])

  const closeSuccessComplainCourierDialog = () => {
    setIsSuccessComplainCourier(false);
  };

  const [isOpenNotCallDialog, setIsOpenNotCallDialog] = useState(false);

  const openNotCallDialog = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setIsOpenNotCallDialog(!isOpenNotCallDialog);
  }

  const closeNotCallDialog = () => {
    setIsOpenNotCallDialog(!isOpenNotCallDialog);
  }
  
  const isShowingTimer = data ? shouldDisplayOrderTimerString(data) : false;

  const timerVariant = isShowingTimer && !!advanceTransitions.length
    ? advanceTransitions[0].color
    : 'info';
  
  if (loading) {
    return <Loader/>;
  }

  if (error) {
    return <ErrorPlug onClick={getData}>
      {error}
    </ErrorPlug>;
  }

  if (!data) {
    return null;
  }

  const {
    isPayed,
    companyId,
    company,
    created,
    customerName,
    customerPhone,
    orderDelivery,
    orderSamovivoz,
    deliveryTypeId,
    deliveryDateTime,
    payTypeId,
    numberOfPersons,
    customerComment,
    products,
    sumItems,
    saleAmount,
    promoSaleAmount,
    deliveryPrice,
    offerSale,
    totalAmount,
    courier,
    timeForReady,
    dateTimeReadyStart,
    isEmenuCourier,
    notCall,
    customerAmount,
    canEditOrderItems,
  } = data;

  let deliveryFigureIcon = 'place';
  let deliveryFigureLabel = t('customerAddress');
  let deliveryAddress = '';

  switch (deliveryTypeId) {
    case OrderDeliveryTypes.Courier:
      deliveryAddress = orderDelivery ? buildAddressString(orderDelivery) : '';
      break;
    case OrderDeliveryTypes.Self:
      deliveryAddress = orderSamovivoz?.address ?? '';
      deliveryFigureIcon = 'storefront';
      deliveryFigureLabel = t('address');
      break;
  }

  const orderTiming = getOrderTimingInfo(data);
  
  /**
   * Стоимость доставки скрывается, если курьер EMENU или доставка самовывозом
   */
  const shouldHideDeliveryPrice = isEmenuCourier || deliveryTypeId === OrderDeliveryTypes.Self;
  
  const courierType = courier?.typeId === CourierTypes.Yandex
    ? t('courierTypeYandex')
    : t('courierTypeEMENU');
  
  /**
   * alt в изображении как пробел, чтобы отсутствие изображения отображалось как пустой белый круг
   */
  const CourierInfo = courier && <>
      <h6 className="mt-4 mt-lg-0 mb-3">{t('courierInfo')}</h6>
      <div className={classNames(styles['courier-profile'], 'd-flex')}>
          <div className={classNames(styles['courier-profile__image-container'], 'me-3')}>
              <img className={styles['courier-profile__image']} src={courier.photoUrl || ''} alt={' '} width={64} height={64}/>
          </div>
          <div className={classNames("d-flex flex-column align-items-start", styles["less-height"])}>
              <div className="fw-600">{courier.name}</div>
              <div className="text-secondary small">{courierType}</div>
              <div
                className={classNames('mt-auto', styles['text-button'])}
                onClick={openComplainCourierDialog}>
                {t('actionComplainCourier')}
              </div>
          </div>
      </div>
  </>

  const productsForMoney: OrderProductResponse[] = [];
  const productsForPoints: OrderProductResponse[] = [];
  const productsForPromotion: OrderProductResponse[] = [];

  products?.forEach((product) => {
    switch (product.typeId){
      case ProductTypes.forMoney:
        productsForMoney.push(product);
        break;
      case ProductTypes.forPoints:
        productsForPoints.push(product);
        break;
      case ProductTypes.forPromotion:
        productsForPromotion.push(product);
    }
  });
  
  const totalWithoutOfferSale = offerSale
    ? totalAmount - offerSale
    : totalAmount;

  return <>
    {!inModal && <div className="d-flex"/* чтобы элемент ссылки не занимал всю строку*/>
      <div className={styles["back-link"]} onClick={goBack}>
        <Icon icon={'arrow_back'}/>
        <div className="fw-600 ms-3">{t('backToList')}</div>
      </div>
    </div>}
    <Box className={classNames(inModal ? "p-0 mt-n3" : "mx-2 mx-md-0")}>
      <Row className="align-items-start">
        <Col xs={12} md={"auto"} className="flex-grow-1 mb-4">
          <div className="d-flex align-items-center">
            <h3 className="mb-0">№ {orderId}</h3>
            {typeof isPayed === "boolean" && <>
              {isPayed
                ? <span className="green fw-600 ms-3">{t('paid')}</span>
                : <span className="red fw-600 ms-3">{t('notPaid')}</span>}
            </>}
          </div>
          <div>{company?.name}, Город</div>
          {created && <div className={styles['subtitle']}>{getDateTimeTuple(created)?.join(', ') ?? ''}</div>}
        </Col>
      </Row>

      <Row>
        <Col lg={6} className="mb-4 mb-lg-0">
          <h6>{t('customerInfo')}</h6>
          <Row>
            <Col md={6} lg={inModal ? 12 : 6} xl={6} className="mt-2_5">
              <Figure
                icon={"perm_identity"}
                label={t('customerName')}
                value={customerName}
              />
            </Col>
            <Col md={6} lg={inModal ? 12 : 6} xl={6} className="mt-2_5">
              {!!customerPhone &&
                <Figure
                  link={`tel:+${customerPhone}`}
                  icon={notCall ? "phone_disabled" : "call"}
                  label={notCall
                    ? <span className={styles['not-call']} onClick={openNotCallDialog}>{t('notCall')} (?)</span>
                    : t('customerPhone')}
                  value={formatPhoneNumber(customerPhone)}
                />
              }
            </Col>
          </Row>
          
          <h6 className="mt-4 mb-2_5">{t('orderDetails')}</h6>
          {deliveryAddress && <Figure
            icon={deliveryFigureIcon}
            label={deliveryFigureLabel}
            value={deliveryAddress}
            className="mb-3"
          />}

          <Figure
            icon="timer"
            label={t(orderTiming.labelKey)}
            value={orderTiming.value
              ? <span className={styles["item-delivery--to-time"]}>{orderTiming.value}</span>
              : <span>{t('ASAP')}</span>}
            className="mb-3"
          />

          {timeForReady && <Figure
            icon="local_fire_department"
            label={t('cooking')}
            value={<>{timeForReady} мин</>}
            className="mb-3"
          />}

          <Figure
            icon={"credit_card"}
            label={t('payment')}
            value={t(orderPaymentTypeNameKeys[payTypeId])}
          />

          {!!customerAmount && <Figure
              icon={"payments"}
              label={t('customerAmount')}
              value={customerAmount}
              className="mt-3"
          />}

          {!!numberOfPersons && <Figure
              icon={"restaurant"}
              label={t('numberOfPersons')}
              value={numberOfPersons}
              className="mt-3"
          />}
          {!!customerComment && <Figure
            icon={"comment"}
            label={t('customerComment')}
            value={customerComment}
            className={classNames("mt-3", styles["break-word"])}
            valueClassName='text-danger'
          />}
          <div className="d-none d-lg-block mt-4">
            {CourierInfo}
          </div>
        </Col>
        
        <Col lg={6}>
          {productsForMoney.length > 0 &&
              <>
                <h6 className="mb-2_5">{commonT('order')}</h6>
                {productsForMoney?.map((product) =>
                  <OrderDetailProduct
                    key={product.id}
                    product={product}
                    isReadOnly={!canEditOrderItems}
                    openItemEditDialog={openItemEditDialog}
                  />)}
              </>
          }
          {productsForPoints.length > 0 &&
              <>
                <h6 className="mt-2_5 mb-2_5">{commonT('productsForPoints')}</h6>
                {productsForPoints?.map((product) =>
                  <OrderDetailProduct
                    key={product.id}
                    product={product}
                    isReadOnly={!canEditOrderItems}
                    openItemEditDialog={openItemEditDialog}
                  />)}
              </>
          }
          {productsForPromotion.length > 0 &&
              <>
                <h6 className="mt-2_5 mb-2_5">{commonT('productsForPromotion')}</h6>
                {productsForPromotion?.map((product) =>
                  <OrderDetailProduct
                    key={product.id}
                    product={product}
                    isReadOnly={!canEditOrderItems}
                    openItemEditDialog={openItemEditDialog}
                  />)}
              </>
          }

          {canEditOrderItems && <div className="d-flex justify-content-center align-items-center">
            <TextButton
              onClick={openAddItemDialog}
              icon={"add"}
              label={t('actionAddProduct')}
              state={["text-button--lowercase"]}
            />
          </div>}
          
          <div className="d-flex align-items-center fw-600 mt-4 mb-3">
            <div className="flex-grow-1">
              {t('sumItems')}
            </div>
            <div>
              <FormatPrice value={sumItems}/> ₸
            </div>
          </div>
          {!!saleAmount && <div className="d-flex align-items-center fw-600 mb-3">
            <div className="flex-grow-1">
              {t('saleAmount')}
            </div>
            <div>
              -<FormatPrice value={saleAmount}/> ₸
            </div>
          </div>}
          {!!promoSaleAmount && <>
            <div className="d-flex align-items-center fw-600">
              <div className="flex-grow-1">
                {t('promoSaleAmount')}
              </div>
              <div>
                -<FormatPrice value={promoSaleAmount}/> ₸
              </div>
            </div>
          </>}
          {!shouldHideDeliveryPrice && <div className="d-flex align-items-center fw-600 mb-3">
            <div className="flex-grow-1">
              {t('deliveryPrice')}
            </div>
            <div>
              {deliveryPrice ? <><FormatPrice value={deliveryPrice}/> ₸</> : t('free')}
            </div>
          </div>}
          {!!offerSale && <>
            <div className="d-flex align-items-center fw-600">
              <div className="flex-grow-1">
                {t('offerSale')}<span className='text-danger'>*</span>
              </div>
              <div>
                -<FormatPrice value={offerSale}/> ₸
              </div>
            </div>
            <div className={classNames('text-danger fst-italic mb-3', styles['small-alert'])}>*{t('offerSaleAmountAlert')}</div>
          </>}
          <div className={classNames(styles["divider"], "mb-3")}/>
          <div className="d-flex align-items-center fw-600">
            <div className="flex-grow-1 fw-bold">
              {t('totalAmount')}
            </div>
            <div className="fw-bold">
              <FormatPrice value={totalWithoutOfferSale}/> ₸
            </div>
          </div>
  
          <div className={classNames("d-flex align-items-center justify-content-center justify-content-lg-end", (advanceTransitions.length || cancelTransitions.length) && styles['mt-adaptive'])}>
            {statusChangeLoading
              ? <Loader/>
              : <>
                  {isShowingTimer && dateHeader && <OrderTimerDisplay
                      order={data}
                      dateHeader={dateHeader}
                      variant={timerVariant}
                      higherPlacement
                  />}
                  {advanceTransitions.map(({statusId, title, additionalForm, color}, index, {length}) =>
                    <Button
                      className={!cancelTransitions?.length && index === length - 1 ? undefined : "me-2"}
                      key={statusId}
                      onClick={() => openStatusChangeDialog({statusId, statusTitle: title, isShowDateTimeReadyStart: !!(deliveryDateTime && !isEmenuCourier), orderId, additionalForm, timeForReady, dateTimeReadyStart})}
                      variant={color}>
                      {title}
                    </Button>)}
          
                  {!!cancelTransitions?.length &&
                      <DropdownButton
                        variant="danger"
                        id="status-dropdown"
                        title={<>
                          <span className="d-none d-lg-inline">
                            <span className="has-caret">{commonT('actionCancel')}</span>
                          </span>
                          <span className="d-flex d-lg-none" style={{height: 20}}>
                            <span className="has-caret d-flex align-items-center"><Icon icon="delete_forever"/></span>
                          </span>
                        </>}>
                        {cancelTransitions?.map(({statusId, title, additionalForm}) =>
                          <Dropdown.Item
                            key={statusId}
                            onClick={() => openStatusChangeDialog({statusId, statusTitle: title, isShowDateTimeReadyStart: !!(deliveryDateTime && !isEmenuCourier), orderId, additionalForm, timeForReady, dateTimeReadyStart})}>
                            {title}
                          </Dropdown.Item>
                        )}
                      </DropdownButton>}
                </>}
          </div>
        </Col>
      </Row>
      <div className="d-block d-lg-none">
        {CourierInfo}
      </div>
    </Box>
    <OrderItemQuantityChangeDialog
      info={itemInfoToEdit}
      closeDialog={closeItemEditDialog}
      onSubmit={changeItemQuantity}
    />
    {typeof companyId === "number" && <OrderAddItemDialog
      show={isAddItemDialog}
      closeDialog={closeAddItemDialog}
      onSubmit={addItem}
      companyId={companyId}
    />}
    <OrderChangeStatusDialog
      info={statusChangeInfo}
      closeHandler={closeStatusChangeDialog}
      submitHandler={changeOrderStatus}
    />
    <OrderComplainCourierDialog
        orderId={orderId}
        show={isOpenComplainCourierDialog}
        closeDialog={closeComplainCourierDialog}
    />
    <OrderComplainCourierSuccessDialog
        show={isSuccessComplainCourier}
        closeDialog={closeSuccessComplainCourierDialog}
    />
    <OrderNotCallDialog
        show={isOpenNotCallDialog}
        closeDialog={closeNotCallDialog}
    />
  </>;
};

/**
 * Товарная позиция заказа
 */
const OrderDetailProduct: React.VFC<OrderDetailProductProps> = ({
  product: {
    id,
    imageUrl,
    quantity,
    title,
    price,
    attributes,
    typeId
  },
  isReadOnly,
  openItemEditDialog,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'common'});
  
  return (
    <div className={styles.item} key={id}>
      <div className={classNames(styles['image-container'])}>
        <img className={styles['image']} src={imageUrl ?? ''} alt={title}/>
      </div>

      <div className={classNames(styles["item-quantity"])}>
        <div className={classNames(styles["item-quantity-badge"], Number(quantity) > 1 && styles["item-quantity-badge--emphasis"])}>
          {quantity}x
        </div>
      </div>

      <div className="flex-grow-1">
        <div className={"mb-2"}>{title}</div>
        {!!attributes && <div className="d-table">
          {attributes?.map(({id, name, price}) =>
              <div className={classNames(styles["item-subtitle"], "d-table-row")} key={id}>
                <div className="d-table-cell pe-3">
                  {name}
                </div>
                <div className="d-table-cell">
                  +<FormatPrice value={price}/> ₸
                </div>
              </div>)}
        </div>}
        {typeId === ProductTypes.forMoney &&
            <div className="mt-2"><FormatPrice value={price}/> ₸</div>
        }
        {typeId === ProductTypes.forPoints &&
            <div className="mt-2"><FormatPrice value={price}/> {t('points', {count: price})}</div>
        }
      </div>

      {typeId === ProductTypes.forMoney &&
          <div className="align-self-start">
            {!isReadOnly && <TextButton
                onClick={() => openItemEditDialog({id, quantity: quantity ?? 0})}
                icon="edit"
                state={["text-button--lowercase", "text-button--condensable"]}
            />}
          </div>
      }
    </div>
  )
}

export default OrderDetail;
