import {useCallback, useEffect, useRef} from "react";
import sound from "../../assets/audio/ring.wav";

export const useNotifications = (): {
    playNotification: () => void;
    stopNotification: () => void;
} => {
    /** реф проигрываемого звукового файла */
    const audioRef = useRef<HTMLAudioElement>();

    const autoRepeat = useCallback(() => {
        if (audioRef.current) {
            audioRef.current.currentTime = 0;
            audioRef.current.play();
        }
    }, []);

    useEffect(() => {
        audioRef.current = new Audio(sound);
        audioRef.current.load();
        audioRef.current.addEventListener('ended', autoRepeat);
    }, [autoRepeat]);

    const playNotification = useCallback(() => {
        audioRef.current?.play()
            .then(() => undefined)
            .catch(() => undefined)
    }, []);

    const stopNotification = useCallback(() => {
        audioRef.current?.load();
    }, [])

    return {
        playNotification,
        stopNotification,
    };
}