import {LoginForm, PartnerEmployeeResponse} from "./models";
import {addParametersToEndpoint, APIRequestParameters, request} from "../../common/utils/apiHelpers";
import {AxiosResponse} from "axios";

/**
 * API методы аутентификации (Auth)
 */
/**
 * Запросить вход, используя параметры входа из form
 */
export const requestAuthentication = (
  form: LoginForm,
): Promise<AxiosResponse<PartnerEmployeeResponse>> =>
  request(
    `/auth/login`,
    'POST',
    form,
  )

/**
 * API по работе с пользователем (User)
 */
/**
 * Запросить данные о текущем пользователе
 */
export const requestUserData = (
  params: APIRequestParameters = {},
): Promise<AxiosResponse<PartnerEmployeeResponse>> =>
  request(
    addParametersToEndpoint(`/user`, params),
    'GET'
  )


