import React from 'react';
import styles from "./CatalogSelectionActions.module.scss";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Icon from "../../../../common/components/Icon";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import {CatalogSelectionActionMenuProps} from "./types";

/**
 * Групповые операции
 */
const CatalogSelectionActionMenu: React.VFC<CatalogSelectionActionMenuProps> = ({
  selectedCount,
  enableHandler,
  disableHandler,
  closeHandler,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'features.catalog'});
  
  return <div className={styles["menu"]}>
    <Container
      className={classNames(styles["menu-container"], "d-flex justify-content-between justify-content-md-end align-items-center px-0")}>
      <div className={styles["menu-label"]}>
        {t(`selectedCount`, {count: selectedCount})}
      </div>
      <div className="d-flex align-items-center">
        <Button
          variant="outline-primary"
          className="me-2"
          onClick={disableHandler}>
          {t(`actionDisable`)}
        </Button>
        <Button
          variant="outline-success"
          onClick={enableHandler}>
          {t(`actionEnable`)}
        </Button>
      </div>
      <div
        className={classNames(styles["menu-button"], "d-flex clickable")}
        onClick={closeHandler}>
        <Icon icon="cancel"/>
      </div>
    </Container>
  </div>;
}

export default CatalogSelectionActionMenu;
