import React from "react";
import {TextAreaProps} from "./types";
import classNames from "classnames";
import styles from "./TextArea.module.scss";

const TextArea = React.forwardRef<unknown, TextAreaProps>(({
    field,
    wrapperClassName,
    formObject,
    form,
    resize = false,
    ...textAreaProps
}, _) => {
    const name = textAreaProps.name || (field && field.name);

    /**
     * Проверка наличия ошибки
     */
    let isError = false;

    if (formObject) {
        isError = !!(formObject && name && name in formObject.touched && name in formObject.errors);
    } else if (form && typeof form === "object"){
        isError = !!(form && name && name in form.touched && name in form.errors);
    }



    return <div className={wrapperClassName}>
        <div className={classNames(styles['textarea-wrapper'], isError && styles['textarea-wrapper--error'])}>
            <textarea
                className={classNames(styles['textarea'], resize && styles["textarea--resize"], "form-control")}
                autoComplete="off"
                {...field}
                {...textAreaProps}
            />
        </div>
    </div>
})

export default TextArea;