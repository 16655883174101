import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../../../app/store";
import {
  currentCompanyIdSelector,
  employeeDataSelector
} from "../../../../../auth/reducer";
import {disableAttributes, enableAttributes, getCatalogAttributes} from "../../actions";
import {flatAttributesReSelector} from "../../reselectors";
import CatalogListHeader from "../../../../components/CatalogListHeader";
import {
  CatalogFilter,
  CatalogItem,
  CatalogItemEditInfo,
  filterCatalogItems
} from "../../../../utils/helpers";
import CatalogListItemOutput from "../../../../components/CatalogListItemOutput";
import CatalogSelectionActionMenu from "../../../../components/CatalogSelectionActions";
import {useTranslation} from "react-i18next";
import AttributePriceChangeDialog from "../AttributePriceChangeDialog";
import DisableParamsDialog from "../../../../../../common/components/DisableParamsDialog";
import {catalogAttributesErrorSelector, catalogAttributesLoadingSelector} from "../../reducer";
import ErrorPlug from "../../../../../../common/components/ErrorPlug";
import Loader from "../../../../../../common/components/Loader";

/**
 * Вывод списка добавок
 */
const AttributeList: React.VFC = () => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation('translation', {keyPrefix: 'features.catalog.attributes'});
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});


  const companyId = useAppSelector(currentCompanyIdSelector);
  const {
    companies
  } = useAppSelector(employeeDataSelector) ?? {};

  const currentCompany = companies?.find(({id}) => id === companyId);

  useEffect(() => {
    if (companyId) {
      dispatch(getCatalogAttributes(companyId, ''));
    }
  }, [companyId, dispatch]);
  
  const items = useAppSelector(flatAttributesReSelector);
  const loading = useAppSelector(catalogAttributesLoadingSelector);
  const error = useAppSelector(catalogAttributesErrorSelector);
  
  /** фильтр */
  const [attributesFilter, setAttributesFilter] = useState<CatalogFilter>({searchText: '', isEnabled: null});
  
  /** значения фильтра примитивами для зависимости useMemo */
  const searchText = attributesFilter.searchText;
  const isEnabled = attributesFilter.isEnabled;
  
  /** прошедшие фильтр блюда */
  const filteredItems = useMemo(
    () => filterCatalogItems(items, {searchText, isEnabled}),
    [items, searchText, isEnabled]
  );
  
  /** информация о порции для изменения в модальном окне */
  const [attributeInfoToEdit, setAttributeInfoToEdit] = useState<CatalogItemEditInfo | null>(null);
  
  const openAttributeEditDialog = useCallback((info: CatalogItemEditInfo) => setAttributeInfoToEdit(info), []);
  const closeAttributeEditDialog = useCallback(() => setAttributeInfoToEdit(null), []);
  
  /** список выделенных позиций */
  const [selectedItems, setSelectedItems] = useState<CatalogItem[]>([]);
  
  const selectedIds = useMemo(
    () => selectedItems.map(({compositeId}) => compositeId),
    [selectedItems]
  )
  
  const disableItemsDialogDescription = <div>
    {commonT(`disableForHowMuch`)}
    &nbsp;
    {selectedItems.map((item, index) =>
      <React.Fragment key={item.compositeId}>
        <span className="fw-600">{item.name}</span>
        {index !== selectedItems.length - 1 && ', '}
      </React.Fragment>)}
    ?
  </div>
  
  /** статус показа диалога деактивации */
  const [isDisableDialogOpen, setIsDisableDialogOpen] = useState(false);
  
  const openDisableItemsDialog = useCallback(() => setIsDisableDialogOpen(true), []);
  const closeDisableItemsDialog = useCallback(() => setIsDisableDialogOpen(false), []);
  
  const changeOneSelectionHandler = (item: CatalogItem, nextStatus: boolean) => {
    setSelectedItems(prevState => {
      
      if (nextStatus) {
        return prevState.includes(item) ? prevState : [...prevState, item];
      }
      
      const index = prevState.indexOf(item);
      
      return index === -1
        ? prevState
        : [
          ...prevState.slice(0, index),
          ...prevState.slice(index + 1)
        ];
    });
  }
  
  const deselectAll = useCallback(() => setSelectedItems([]), [])
  
  const getSectionCheckboxStatus = useCallback((ids: string[]) => {
    const statuses = ids.map(id => selectedIds.includes(id));
    
    if (statuses.includes(true)) {
      if (statuses.includes(false)) {
        return "partial";
      }
      
      return "checked";
    }
    
    return null;
  }, [selectedIds])
  
  const changeSectionSelectionHandler = useCallback((items: CatalogItem[]) => {
    const status = getSectionCheckboxStatus(items.map(({compositeId}) => compositeId));
    
    switch (status) {
      case "checked":
        items.forEach(id => changeOneSelectionHandler(id, false));
        break;
      case "partial":
      case null:
      default:
        items.forEach(id => changeOneSelectionHandler(id, true));
        break;
    }
  }, [getSectionCheckboxStatus])
  
  const enableAttributeHandler = (attributeId: string) => {
    dispatch(enableAttributes([attributeId]))
  }
  
  const enableSelectedHandler = () => {
    dispatch(enableAttributes(selectedIds))
    deselectAll();
  }
  
  const disableSelectedHandler = useCallback(({dateTime}) => {
    dispatch(disableAttributes(selectedIds, dateTime))
    deselectAll();
  }, [deselectAll, dispatch, selectedIds])
  
  let content = <Loader/>
  
  if (!loading) {
    if (error) {
      content = <ErrorPlug className={'mb-0'}>{error}</ErrorPlug>
    } else {
      content = <>
        {!!filteredItems.length
          ? filteredItems.map(item => {
      
              const {
                portionSize,
                ...rest
              } = item;
        
              let displayedDesc = portionSize;
              let checkboxStatus: "checked" | "partial" | null = selectedIds.includes(item.compositeId) ? "checked" : null;
        
              let selectHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
                changeOneSelectionHandler(item, e.target.checked)
              }
        
              switch (item.level) {
                case "first":
                  const innerAttributes = filteredItems.filter(
                    ({level, compositeId}) => level === "third" && compositeId.startsWith(`${item.compositeId}.`)
                  );
            
                  const ids = innerAttributes.map(({compositeId}) => compositeId);
            
                  displayedDesc = t(`attribute`, {count: innerAttributes.length})
            
                  selectHandler = () => changeSectionSelectionHandler(innerAttributes)
                  checkboxStatus = getSectionCheckboxStatus(ids)
                  break;
              }
        
              return <CatalogListItemOutput
                key={item.compositeId}
                portionSize={displayedDesc}
                enableHandler={enableAttributeHandler}
                editHandler={openAttributeEditDialog}
                selectHandler={selectHandler}
                checkboxStatus={checkboxStatus}
                {...rest}
              />
            })
          : <div className="d-flex justify-content-center">
              <h2 className="mb-0">{t('itemsNotFound')}</h2>
            </div>}
      </>
    }
  }
  
  return <>
    <CatalogListHeader
      filter={attributesFilter}
      setItemsFilter={setAttributesFilter}
      disabledItemsCount={items.filter(({level, enabled}) => level === "third" && !enabled).length}
    />
  
    {content}
  
    {!!selectedIds.length &&
      <CatalogSelectionActionMenu
          selectedCount={selectedIds.length}
          enableHandler={enableSelectedHandler}
          disableHandler={openDisableItemsDialog}
          closeHandler={deselectAll}
      />}
  
    <AttributePriceChangeDialog
      info={attributeInfoToEdit}
      closeDialog={closeAttributeEditDialog}
    />
  
    <DisableParamsDialog
      nextOpenTime={currentCompany?.nextOpenTime ?? null}
      title={t(`actionDisableItems`)}
      description={disableItemsDialogDescription}
      onSubmit={disableSelectedHandler}
      isOpen={isDisableDialogOpen}
      closeDialog={closeDisableItemsDialog}
    />
  </>;
};

export default AttributeList;
