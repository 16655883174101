import {OrdersAction} from "./reducer";
import {AppThunkAction} from "../../app/store";
import * as types from "./constants";
import {requestComplainCourier, requestOrders} from "./api";
import {getAxiosErrorMessage} from "../../common/utils/functions";
import {CourierComplainForm, OrderResponse} from "./models";
import {addNotify} from "../../common/actions/notify";
import {changeTimestamp} from "../auth/actions";
import {getOrderReplaceEventName, isOrderNotInProgress} from "./helpers";
import {getDateHeader} from "../../common/utils/apiHelpers";

export const getActiveOrders = (
  expand = '',
): AppThunkAction<OrdersAction> => dispatch => {

  dispatch({type: types.GET_ORDERS_REQUEST});

  requestOrders({expand})
    .then(({data, headers}) => {
      dispatch({
        type: types.GET_ORDERS_SUCCESS,
        payload: {
          data: data || [],
          dateHeader: getDateHeader(headers),
        },
      })
      dispatch(changeTimestamp(Number(headers["x-request-timestamp"])));
    })
    .catch(err => {
      dispatch({
          type: types.GET_ORDERS_FAILURE,
          payload: getAxiosErrorMessage(err)
      })
    })
}

export const complainCourier = (
    form: CourierComplainForm
) : AppThunkAction<OrdersAction> => dispatch => {
    requestComplainCourier(form)
        .then(() => {
            dispatch({
                type: types.COMPLAIN_COURIER_SUCCESS
            })
        })
        .catch(err => {
            dispatch(addNotify(getAxiosErrorMessage(err)));
        })
}

/**
 * Обновление списка заказов
 * */
export const updateOrders = (
  newData: OrderResponse[],
  newDate: string | null,
): AppThunkAction<OrdersAction> => (dispatch, getState) => {
    const state = [...getState().orders.data];

    const idsToDeleteFromNewState: number[] = [];

    newData.forEach((order) => {
        const {id} = order;
        
        document.dispatchEvent(new CustomEvent(getOrderReplaceEventName(id), {detail: order}))

        const index = state.findIndex((order) => order.id === id);

        if (index >= 0) {
            state.splice(index, 1);
        }

        /**
         * Если статус заказа будет <=0 или >=100, то заказ необходимо удалить из отображения в разделе заказы
         */
        if (isOrderNotInProgress(order)) {
            idsToDeleteFromNewState.push(order.id);
            return;
        }
    })

    const result = newData
        .filter(({id}) => !idsToDeleteFromNewState.includes(id))
        .concat(state);

    dispatch({
        type: types.UPDATE_ORDERS_SUCCESS,
        payload: {
          data: result || [],
          dateHeader: newDate,
        },
    })
}
