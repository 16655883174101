import {AxiosPromise} from "axios";
import {ReviewAnswerForm, ReviewFilterForm, ReviewResponse, ReviewSummaryResponse} from "./models";
import {addParametersToEndpoint, APIRequestParameters, request} from "../../common/utils/apiHelpers";

/**
 * Получить список отзывов
 */
export const requestReviews = (
  form: ReviewFilterForm | null,
  params: APIRequestParameters = {page: 1},
): AxiosPromise<ReviewResponse[] | ''> =>
  request(
    addParametersToEndpoint(`/review/search`, params),
    'POST',
    form
  )

/**
 * Добавить или изменить отзыв id с помощью form
 */
export const requestReviewAnswerUpdate = (
  id: number,
  form: ReviewAnswerForm,
): AxiosPromise<ReviewResponse> =>
  request(
    `/review/${id}/answer`,
    'POST',
    form,
  )

/**
 * Удаление ответа на отзыв id
 */
export const requestReviewAnswerDeletion = (
  id: number,
): AxiosPromise<ReviewResponse> =>
  request(
    `/review/${id}`,
    'DELETE'
  )

/**
 * Получение общей статистики по отзывам компании companyId
 */
export const requestReviewSummary = (
  companyId: number,
): AxiosPromise<ReviewSummaryResponse> =>
  request(
    addParametersToEndpoint(`/review/summary`, {companyId}),
    'GET'
  )
