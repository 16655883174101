import React from 'react';
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import AttributeList from "../../subfeatures/attributes/components/AttributeList";
import styles from "./CatalogOutput.module.scss";
import ProductList from "../../subfeatures/products/components/ProductList";
import { useTranslation } from 'react-i18next';
import classNames from "classnames";

/**
 * Каталог продуктов и атрибутов
 */
const CatalogOutput: React.VFC = () => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'features.catalog'});
  
  return <div className="bg-white p-0 p-md-4 mb-5">
    <Tabs
      defaultActiveKey="products"
      id="menu-tabs"
      className={classNames(styles["custom-tabs"], "bg-white")}>
      <Tab eventKey="products" title={t(`products.items`)} className="p-3 p-md-0">
        <ProductList/>
      </Tab>
      <Tab eventKey="attributes" title={t(`attributes.items`)} className="p-3 p-md-0">
        <AttributeList/>
      </Tab>
    </Tabs>
  </div>;
}

export default CatalogOutput;
