import React from 'react';
import BootstrapPagination from "react-bootstrap/Pagination";
import classNames from "classnames";
import {PaginationProps} from "./types";

/**
 * Стандартная пагинация панели
 */
const Pagination: React.VFC<PaginationProps> = ({
  currentPage,
  pageCount,
  setCurrentPage,
  className,
}) => {
  
  let paginationElements = pageCount >= 9
    ? <>
        {currentPage !== 1 && <BootstrapPagination.Prev onClick={() => setCurrentPage(currentPage - 1)}/>}
        <BootstrapPagination.Item active={currentPage === 1} onClick={() => setCurrentPage(1)}>
          1
        </BootstrapPagination.Item>
        {currentPage - 1 >= 3 && <BootstrapPagination.Ellipsis disabled/>}
        
        {currentPage - 1 >= 2 && <BootstrapPagination.Item onClick={() => setCurrentPage(currentPage - 1)}>
          {currentPage - 1}
        </BootstrapPagination.Item>}
        {currentPage !== 1 && currentPage !== pageCount && <BootstrapPagination.Item active>
          {currentPage}
        </BootstrapPagination.Item>}
        {pageCount - currentPage >= 2 && <BootstrapPagination.Item onClick={() => setCurrentPage(currentPage + 1)}>
          {currentPage + 1}
        </BootstrapPagination.Item>}
        
        {pageCount - currentPage >= 3 && <BootstrapPagination.Ellipsis disabled/>}
        <BootstrapPagination.Item active={currentPage === pageCount} onClick={() => setCurrentPage(pageCount)}>
          {pageCount}
        </BootstrapPagination.Item>
        {currentPage !== pageCount && <BootstrapPagination.Next onClick={() => setCurrentPage(currentPage + 1)}/>}
      </>
    : <>
      {Array(pageCount).fill(1).map((_, index) =>
        <BootstrapPagination.Item
          key={index + 1}
          active={currentPage === index + 1}
          onClick={() => setCurrentPage(index + 1)}
        >
          {index + 1}
        </BootstrapPagination.Item>
      )}
    </>
  
  return <div className={classNames("d-flex align-items-center justify-content-center", className)}>
    <BootstrapPagination>
      {paginationElements}
    </BootstrapPagination>
  </div>;
};

export default Pagination;
