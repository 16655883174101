import React from 'react';
import {useDispatch} from "react-redux";
import Modal from "react-bootstrap/Modal";
import {Field, FieldProps, Form, Formik} from "formik";
import NumberFormat from "react-number-format";
import Button from "react-bootstrap/Button";
import {AttributePriceChangeDialogProps} from "./types";
import {useTranslation} from "react-i18next";
import {editAttribute} from "../../actions";
import TextInput from "../../../../../../common/components/TextInput";
import * as Yup from 'yup';
import {requiredMessage} from "../../../../../../common/utils/validation";

const validationSchema = Yup.object().shape({
  price: Yup.number().min(1).required(requiredMessage())
})

/**
 * Диалог изменения цены позиции диалога
 */
const AttributePriceChangeDialog: React.VFC<AttributePriceChangeDialogProps> = ({
  info,
  closeDialog,
}) => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  return <Modal show={!!info} onHide={closeDialog} centered>
    <Modal.Header closeButton>
      <Modal.Title>{t(`features.catalog.attributes.actionEditItem`)}</Modal.Title>
    </Modal.Header>
    <Formik
      initialValues={{
        price: info?.price ?? 0,
      }}
      validationSchema={validationSchema}
      onSubmit={({price}) => {
        if (info) {
          dispatch(editAttribute(info.compositeId, {price}))
          closeDialog();
        }
      }}>
      {({values, setFieldValue, isValid}) =>
        <Form>
          <Modal.Body>
            <Field name="price">
              {(props: FieldProps) =>
                <NumberFormat
                  id={"price"}
                  onValueChange={({floatValue}) => setFieldValue('price', floatValue ?? 0)}
                  value={values.price}
                  allowNegative={false}
                  onBlur={props.field.onBlur}
                  customInput={TextInput}
                  thousandSeparator={" "}
                />}
            </Field>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-secondary" onClick={closeDialog}>
              {t('common.actionCancel')}
            </Button>
            <Button variant="outline-primary"
                    type="submit"
                    disabled={!isValid}
            >
              {t('common.actionSubmit')}
            </Button>
          </Modal.Footer>
        </Form>}
    </Formik>
  </Modal>
}

export default AttributePriceChangeDialog;
