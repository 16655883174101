import React, {memo, useCallback, useState} from 'react';
import styles from "./ReviewsListItem.module.scss";
import classNames from "classnames";
import StarRatingBar from "../../../../../common/components/StarRatingBar";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import {getDateTimeTuple} from "../../../../../common/utils/dateTimeHelpers";
import {useAppSelector} from "../../../../../app/store";
import {employeeDataSelector} from "../../../../auth/reducer";
import FormatPrice from "../../../../../common/components/FormatPrice";
import Figure from "../../../../../common/components/Figure";
import {ReviewsListItemProps} from "./types";
import 'react-image-lightbox/style.css';
import LightboxGallery from "../../../../../common/components/LightboxGallery";
import Loader from "../../../../../common/components/Loader";

/**
 * Элемент списка отзывов
 */
const ReviewsListItem: React.VFC<ReviewsListItemProps> = ({
  review: {
    id,
    user,
    updated,
    orderId,
    rating,
    ratingQuality,
    ratingDesign,
    ratingDelivery,
    message,
    imageUrls,
    answer,
    companyId,
    answeredDateTime,
    canAnswer,
  },
  updateAnswerHandler,
  openOrderIdInModal,
  loading,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'features.reviews'});
  
  const formattedUpdateDate = getDateTimeTuple(updated);
  const formattedAnswerDate = getDateTimeTuple(answeredDateTime);
  
  const {
    companies
  } = useAppSelector(employeeDataSelector) ?? {};
  
  const reviewCompany = companies?.find(({id}) => id === companyId);
  
  const images = imageUrls || [];
  
  const [isLightBox, setIsLightBox] = useState(false);
  
  const openLightBox = useCallback(() => setIsLightBox(true), []);
  const closeLightBox = useCallback(() => setIsLightBox(false), []);
  
  const [imageInLightBoxIndex, setImageInLightBoxIndex] = useState(0);
  
  /**
   * Футер элемента зависит от возможности добавления/редактирования ответа и статуса загрузки запроса добавления/редактирования ответа
   */
  let itemFooter = canAnswer
    ? loading
      ? <div className="d-flex mt-2_5 mt-md-3 ms-n5 ms-md-0"><Loader/></div>
      : <div className="mt-2_5 mt-md-3 ms-n5 ms-md-0">
          <Button
            onClick={() => updateAnswerHandler(id)}
            variant="outline-primary">
            {t('actionRespond')}
          </Button>
        </div>
    : null;
    
  if (answer) {
    itemFooter = <div className="d-flex mt-3 mt-md-4 ms-n5 ms-md-0">
      <img
        src={reviewCompany?.imageUrl}
        width={40}
        height={40}
        alt={reviewCompany?.name}
        className="me-2 me-md-3 rounded-circle overflow-hidden flex-shrink-0"
      />
      <div>
        <div className={styles['review-heading']}>{reviewCompany?.name ?? ''}</div>
        {formattedAnswerDate && <div className={styles['review-subtitle']}>
          {formattedAnswerDate[0]}, {formattedAnswerDate[1]}
        </div>}
        <div className={classNames("mt-1 mt-md-2", styles['review-content'])}>{answer}</div>
        {canAnswer && (
          loading
            ? <div className="d-flex"><Loader className="mt-2_5 mt-md-3"/></div>
            : <div className="d-flex">
                <Button
                  className="mt-2_5 mt-md-3 me-1"
                  onClick={() => updateAnswerHandler(id)}
                  variant="outline-primary">
                  {t('actionEditResponse')}
                </Button>
              </div>
        )}
      </div>
    </div>
  }
  
  return <>
    <div className={classNames("d-flex", styles['review'], !answer && styles['review--highlighted'])}>
      {user?.photoUrl
        ? <img
            src={user?.photoUrl}
            width={40}
            height={40}
            alt={user?.name}
            className="me-2 me-md-3 rounded-circle overflow-hidden flex-shrink-0"
          />
        : <div className="me-2 me-md-3" style={{width: 40, height: 40}}>
            <Figure
              icon={"perm_identity"}
              value={null}
            />
          </div>}
      <div>
        <div className={styles['review-heading']}>{user?.name}</div>
        <div className={styles['review-subtitle']}>
          {formattedUpdateDate && <>{formattedUpdateDate[0]}, {formattedUpdateDate[1]}</>}
          {typeof orderId === "number" && <>
              &nbsp;(Заказ <span className={classNames("clickable orange", styles['dashed'])}
                                 onClick={() => openOrderIdInModal(orderId)}>
                <FormatPrice value={orderId}/>
            </span>)
          </>}
        </div>
        
        <div className={styles['review-ratings']}>
          <div>
            <span className='d-none d-lg-block me-1'>Общая оценка:</span>
            <StarRatingBar rating={rating}/>
            <div className='d-block d-lg-none mt-n1'>Общая</div>
          </div>
          {typeof ratingQuality === "number" && <div>
            <span className='d-none d-lg-block me-1'>Качество/вкус:</span>
            <StarRatingBar rating={ratingQuality}/>
            <div className='d-block d-lg-none mt-n1'>Качество/вкус</div>
          </div>}
          <div className={styles['review-ratings-divider']}/>
          {typeof ratingDesign === "number" && <div>
            <span className='d-none d-lg-block me-1'>Упаковка:</span>
            <StarRatingBar rating={ratingDesign}/>
            <div className='d-block d-lg-none mt-n1'>Упаковка</div>
          </div>}
          {typeof ratingDelivery === "number" && <div>
            <span className='d-none d-lg-block me-1'>Доставка:</span>
            <StarRatingBar rating={ratingDelivery}/>
            <div className='d-block d-lg-none mt-n1'>Доставка</div>
          </div>}
        </div>
        
        {message && <div className={classNames("mt-2 ms-n5 ms-md-0", styles['review-content'])}>{message}</div>}
        {!!images.length && <div className="d-flex ms-n5 ms-md-0">
          {images.map((url, index) =>
            <div
              className={classNames("mt-2 me-2_5 clickable", styles['review-picture'])}
              key={url}
              onClick={() => {
                setImageInLightBoxIndex(index);
                openLightBox();
              }}>
              <img src={url} alt=""/>
            </div>)}
        </div>}
        {itemFooter}
      </div>
    </div>
    {isLightBox && <LightboxGallery
      images={images}
      imageIndex={imageInLightBoxIndex}
      setImageIndex={setImageInLightBoxIndex}
      closeHandler={closeLightBox}
    />}
  </>;
};

export default memo(ReviewsListItem);
