import {PortionResponse} from "../catalog/subfeatures/products/models";
import {OrderDeliveryTypes, OrderPaymentTypes} from "./helpers";
import {CityResponse, CompanyResponse} from "../profile/models";
import {OrderStatusGroupIds} from "./constants";

export type OrderResponse = {
  /** ID */
  id:	number;
  /** Имя заказчика */
  customerName?: string;
  /** Телефон заказчика */
  customerPhone?: string;
  /** ID компании */
  companyId?: number;
  /** Компания */
  company?: CompanyResponse;
  /** ID города */
  cityId:	number;
  /** Город */
  city?: CityResponse;
  /** Тип доставки */
  deliveryTypeId:	OrderDeliveryTypes;
  /** Тип оплаты */
  payTypeId: OrderPaymentTypes;
  /** Количество персон */
  numberOfPersons?:	number;
  /** Сдача с */
  customerAmount?: number;
  /** Дата и время доставки */
  deliveryDateTime?: string;
  /** Признак не звонить клиенту */
  notCall: boolean;
  /**
   * Оплачен или нет заказ.
   * NULL - метод оплаты не подразумевает знание статуса оплаты
   */
  isPayed: boolean | null;
  /** Стоимость доставки */
  deliveryPrice: number;
  /** Признак доставки EMENU */
  isEmenuCourier:	boolean;
  /** Время готовки заказа */
  timeForReady?: number | null;
  /** Время начала подготовки заказа */
  dateTimeReadyStart?: string;
  /** Комментарий клиента */
  customerComment?:	string | true;
  /** Статус заказа */
  status:	StatusResponse;
  /** Сумма скидки */
  saleAmount:	number;
  /** Сумма скидки в процентах */
  salePercent: number;
  /** Скидка по промокоду */
  promoSaleAmount: number;
  /** Блюда */
  products?: OrderProductResponse[];
  /** Стоимость товаров */
  sumItems:	number;
  /** Стоимость заказа с учетом скидок и промокодов */
  orderAmount: number;
  /** Скидка за счет EMENU */
  offerSale?: number;
  /** Окончательная стоимость заказа */
  totalAmount: number;
  /** Информация о доставке */
  orderDelivery?: OrderDeliveryResponse;
  /** Информация о самовывозе */
  orderSamovivoz?: OrderSamovivozResponse;
  /** Курьер */
  courier?: CourierResponse;
  /** Переходы */
  allowTransitions?: OrderTransitionResponse[];
  /** Возможно ли редактирование товаров в заказе */
  canEditOrderItems: boolean;
  /** Дата и время создания заказа */
  created?: string;
  /** Дата и время приезда курьера */
  courierArrivalDateTime?: string;
}

/**
 * Информация о статусе заказа
 */
export type StatusResponse = {
  /** id статуса */
  id: number;
  /** Название статуса */
  name: string;
}

/**
 * Тип товара в заказе
 */
export enum ProductTypes {
  /** Обычный товар за деньги */
  forMoney = 1 ,
  /** Товар за бонусы */
  forPoints = 2,
  /** Товар по акции */
  forPromotion = 3
}

export type OrderProductResponse = {
  /** ID позиции в заказе */
  id:	number;
  /** Номер заказа */
  orderId?:	string;
  /** Порция */
  portion?: PortionResponse;
  /** ID порции */
  portionId?:	number;
  /** Название заказанной позиции */
  title?:	string;
  /** Описание заказанной позиции */
  description?:	string;
  /** Заказанное количество */
  quantity?: number;
  /** Цена */
  price?:	number;
  /** Изображение */
  imageUrl?: string | null;
  /** ID компании */
  attributes?: OrderAttributeItemResponse[];
  /** ID товара в заказе*/
  typeId: ProductTypes;
}

export type OrderAttributeItemResponse = {
  /** id */
  id: number;
  /** id атрибута */
  attributeId?: number;
  /** Название */
  name: string;
  /** Стоимость */
  price: number;
}

/**
 * Информация о доставке заказа
 */
export type OrderDeliveryResponse = {
  /** ID */
  id:	number;
  /** ID зоны доставки */
  deliveryZoneId:	number;
  /** Зона доставки */
  deliveryZone?: DeliveryZoneResponse;
  /** Улица */
  street: string;
  /** Дом */
  house: string;
  /** Квартира */
  flat?: string;
  /** Этаж */
  floor?:	string;
  /** Подъезд */
  entrance?: string;
  /** Квартира */
  speakerphone?: string;
}

export type OrderSamovivozResponse = {
  /** ID */
  id:	number;
  /** адрес */
  address: string;
  /** id адреса */
  addressId: number;
  // /** ID зоны доставки */
  // deliveryZoneId?:	number;
  // /** Зона доставки */
  // deliveryZone?: DeliveryZoneResponse;
  // /** Улица */
  // street?: string;
  // /** Дом */
  // house?: string;
  // /** Квартира */
  // flat?: string;
  // /** Этаж */
  // floor?:	string;
  // /** Подъезд */
  // entrance?: string;
  // /** Квартира */
  // speakerphone?: string;
}

export type DeliveryZoneResponse = {
  /** ID */
  id: number;
  /** Название */
  name: string;
  /** Описание */
  description?: string;
}

export type CourierResponse = {
  /** Id курьера */
  id: number;
  /** Имя курьера */
  name: string;
  /** Телефон */
  phone: string;
  /** Тип курьера */
  typeId: number;
  /** Фотография */
  photoUrl?: string | null;
}

/**
 * Информация о переходе
 */
export type OrderTransitionResponse = {
  /** id */
  statusId: number;
  /** Название */
  title: string;
  /** Цвет кнопки*/
  color: string;
  /** Тип формы */
  additionalForm?: 'timeReadyForm' | null;
}

export type OrderFilterForm = {
  /** id заказов */
  ids?: number[];
  /** id типа статуса */
  statusTypeIds?: OrderStatusGroupIds[];
  /** id статусов */
  statusIds?: number[];
  /** id компаний */
  companyIds?: number[];
  /** Тип оплаты */
  payTypeIds?: number[];
  /** Тип доставки */
  deliveryTypeIds?: number[];
  /** Время начала периода */
  dateTimeStart?: string;
  /** Время конца периода */
  dateTimeEnd?: string;
}

export type OrderItemEditForm = {
  quantity: number;
}

/**
 * Форма добавления позиции к заказу
 */
export type OrderProductItemForm = {
  /** Порция */
  portionId:number;
  /** Количество */
  quantity:	number;
  /** Ids атрибутов */
  attributeIds?: number[];
}

/**
 * Форма времени приготовления для перехода к некоторым статусам
 */
export type TimeReadyForm = {
  timeForReady: number;
  dateTimeReadyStart?: string;
}

export type OrderSummaryResponse = {
  /** Количество заказов за наличные */
  countCash: number;
  /** Количество заказов картой курьеру */
  countPOS: number;
  /** Количество заказов по онлайн оплате */
  countOnline: number;
  /** Общая стоимость заказов за наличные */
  amountCash: number;
  /** Общая стоимость заказов картой курьеру */
  amountPOS: number;
  /** Общая стоимость заказов по онлайн оплате */
  amountOnline: number;
  /** Количество частичных возвратов */
  partRefund: number;
  /** Сумма частичных возвратов */
  partRefundAmount: number;
  /** Успешные транзакции по возвратам */
  partRefundSuccessAmount: number;

}

export type CourierComplainForm = {
  orderId: number;
  message: string
}
