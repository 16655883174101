import React from 'react';
import DatePicker from "react-datepicker";
import {TimePickerProps} from "./types";
import "react-datepicker/dist/react-datepicker.css";
import "./react-datepicker-override.css";
import {useTranslation} from "react-i18next";
import {dateTimePickerLocales} from "./index";
import {format, parse} from "date-fns";
import TextInput from "../TextInput";

/**
 * Ввод времени в формате HH:mm.
 * Данная имплементация может вызывать проблемы с валидацией, опаздывая на один шаг.
 * @see ProfileView - пример решения
 */
const TimePicker: React.VFC<TimePickerProps> = (
  props
) => {
  
  const {i18n: {language}, t} = useTranslation();
  const locale = dateTimePickerLocales[language];
  
  const name = props.name || props.field?.name;
  const value = props.value || props.field?.value;
  const onChange = props.onChange || props.form?.setFieldValue;
  
  /**
   * в react-datepicker value может быть массивом дат для представления диапазона,
   * но здесь это представление не используется
   */
  const changeHandler = (value: Date | [Date, Date] | null) => {
    if (!onChange || Array.isArray(value)) {
      return;
    }
    
    onChange(name, value
      ? format(value, 'HH:mm')
      : null);
    
    blurHandler();
  };
  
  const blurHandler = () => {
    props.form?.setFieldTouched(name);
  };
  
  return <DatePicker
    selected={value
      ? parse(value, 'HH:mm', new Date())
      : null}
    showTimeSelect
    showTimeSelectOnly
    timeIntervals={15}
    timeCaption={t('common.time')}
    locale={locale}
    onChange={changeHandler}
    onBlur={blurHandler}
    name={name}
    dateFormat="HH:mm"
    customInput={<TextInput
      mask={[/\d/, /\d/, ':', /\d/, /\d/]}
      {...props}
    />}
  />;
};

export default TimePicker;
