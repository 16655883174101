import {AppThunkAction} from "../../../../app/store";
import {CatalogProductsAction} from "./reducer";
import * as types from "./constants";
import {
  requestAllCategories,
  requestDisableProductPortions,
  requestEnableProductPortions,
  requestProductPortionEdit
} from "./api";
import {ProductDisableForm, ProductPortionEditForm} from "./models";
import {extractPartsFromCatalogItemCompositeId} from "../../utils/helpers";
import {getAxiosErrorMessage} from "../../../../common/utils/functions";
import {addNotify} from "../../../../common/actions/notify";

export const getCatalogProducts = (
  companyId: number,
  expand = '',
  notify = false,
): AppThunkAction<CatalogProductsAction> => dispatch => {
  
  dispatch({type: types.GET_SECTIONS_REQUEST});
  
  requestAllCategories(companyId, {expand})
    .then(({data}) => {
      dispatch({
        type: types.GET_SECTIONS_SUCCESS,
        payload: data || [],
      })
    })
    .catch((err) => {
      dispatch({
        type: types.GET_SECTIONS_FAILURE,
        payload: getAxiosErrorMessage(err)
      })
      
      if (notify) {
        dispatch(addNotify(err));
      }
    })
}

export const editPortion = (
  compositeId: string,
  form: ProductPortionEditForm,
): AppThunkAction<CatalogProductsAction> => dispatch => {
  const ids = extractPartsFromCatalogItemCompositeId(compositeId);
  
  requestProductPortionEdit(ids.portionId, form)
    .then(({data}) => {
      dispatch({
        type: types.REPLACE_PORTIONS,
        payload: {
          compositeIdParts: [ids],
          form: data,
        }
      })
    })
    .catch((err) => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}

export const enablePortions = (
  compositeIds: string[],
): AppThunkAction<CatalogProductsAction> => dispatch => {
  const compositeIdParts = compositeIds.map(extractPartsFromCatalogItemCompositeId);
  const portionIds = compositeIdParts.map(({portionId}) => portionId);
  
  requestEnableProductPortions({ids: portionIds})
    .then(() => {
      dispatch({
        type: types.REPLACE_PORTIONS,
        payload: {
          compositeIdParts,
          form: {isEnabled: true},
        }
      })
    })
    .catch((err) => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    });
}

export const disablePortions = (
  compositeIds: string[],
  dateTime: string | null = null,
): AppThunkAction<CatalogProductsAction> => dispatch => {
  const compositeIdParts = compositeIds.map(extractPartsFromCatalogItemCompositeId);
  
  const form: ProductDisableForm = {
    ids: compositeIdParts
      .map(({portionId}) => portionId)
  };
  
  if (dateTime) {
    form.dateTime = dateTime;
  }
  
  requestDisableProductPortions(form)
    .then(() => {
      dispatch({
        type: types.REPLACE_PORTIONS,
        payload: {
          compositeIdParts,
          form: {isEnabled: false, disabledUntil: dateTime},
        }
      })
    })
    .catch((err) => {
      dispatch(addNotify(getAxiosErrorMessage(err)));
    })
}
