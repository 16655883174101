import React from 'react';
import {Redirect, Route} from "react-router-dom";
import {useAppSelector} from "../../../app/store";
import {isLoggedInSelector} from "../../../features/auth/reducer";
import {PrivateRouteProps} from "./types";

/**
 * Контейнер для роутов с регулируемым доступом
 */
const PrivateRoute: React.VFC<PrivateRouteProps> = ({
  component: Component,
  ...rest
}) => {
  
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  
  if (!isLoggedIn) {
    return <Redirect to={'/login'}/>
  }
  
  return <Route {...rest} render={props => <Component {...props}/>}/>;
};

export default PrivateRoute;
