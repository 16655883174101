import classNames from 'classnames';
import React from 'react';
import styles from "./Checkbox.module.scss";
import {CheckboxProps} from "./types";

/**
 * Компонент - чекбокс (без лейбла).
 * Может быть включенным, отключенным и частично включенным
 */
const Checkbox: React.VFC<CheckboxProps> = ({
  id,
  checked,
  state,
  label,
  disabled = false,
  ...props
}) =>
  <div className={classNames(
    styles["control"],
    disabled && styles["control--disabled"],
    state?.map(c => styles[c])
    )}>
    <input id={id} type="checkbox" checked={checked} {...props}/>
    {label
      ? <label className="ms-2" htmlFor={id}>{label}</label>
      : <label htmlFor={id}/>}
  </div>;

export default Checkbox;
