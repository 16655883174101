import React from 'react';
import {EmptyProps} from "./types";
import {useTranslation} from "react-i18next";
import Box from "../Box";
import classNames from "classnames";

/**
 * Компонент - заглушка пустого списка
 */
const Empty: React.VFC<EmptyProps> = ({value, className}) => {
  
  const {t} = useTranslation();
  
  return <Box className={classNames("d-flex justify-content-center gray-500", className)}>
    {value || <h3>{t('common.itemsListEmpty')}</h3>}
  </Box>;
};

export default Empty;
