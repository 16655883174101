import {AxiosResponse} from "axios";
import {addParametersToEndpoint, request} from "../../common/utils/apiHelpers";
import {CompanyDisableForm, CompanyResponse, CompanyWorkdayForm} from "./models";

/**
 * API методы компаний (Company)
 */
/**O
 * Включить компанию companyId
 */
export const requestEnableCompany = (
  companyId: number,
): Promise<AxiosResponse> =>
  request(
    addParametersToEndpoint(`/company/enable`, {companyId}),
    'POST',
    null,
  )

/**
 * Выключить компанию companyId, используя параметры отключения form
 */
export const requestDisableCompany = (
  companyId: number,
  form: CompanyDisableForm,
): Promise<AxiosResponse> =>
  request(
    addParametersToEndpoint(`/company/disable`, {companyId}),
    'POST',
    form,
  )

/**
 * Получить полную информацию о компании id
 */
export const requestGetCompany = (
  id: number,
): Promise<AxiosResponse<CompanyResponse>> =>
  request(
    addParametersToEndpoint(`/company/${id}`),
  )

/**
 * Редактировать график работ компании id по форме form
 */
export const requestChangeCompanyWorkdays = (
  id: number,
  form: CompanyWorkdayForm[],
): Promise<AxiosResponse> =>
  request(
    addParametersToEndpoint(`/company/${id}/workdays`),
    'POST',
    form,
  )
