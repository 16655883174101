import React from "react";
import classNames from "classnames";
import styles from "./CatalogListItemOutput.module.scss";
import Checkbox from "../../../../common/components/Checkbox";
import FormatPrice from "../../../../common/components/FormatPrice";
import Icon from "../../../../common/components/Icon";
import Button from "react-bootstrap/Button";
import {CatalogListItemProps} from "./types";
import {useTranslation} from "react-i18next";
import {getDateTimeTuple} from "../../../../common/utils/dateTimeHelpers";

/**
 * Вывод одной позиции списка продуктов/атрибутов каталога
 */
const CatalogListItemOutput: React.VFC<CatalogListItemProps> = ({
  compositeId,
  name,
  description,
  portionSize,
  price,
  disabledUntil,
  enabled,
  enableHandler,
  editHandler,
  selectHandler,
  checkboxStatus,
  level,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'features.catalog'});
  
  const formattedClosedUntil = disabledUntil
    ? getDateTimeTuple(disabledUntil)?.join(', ')
    : null;
  
  return <div className={classNames(
    styles["list-item"],
    styles[`list-item--${level}-level`],
  )}>
    <div className="d-flex justify-content-between align-items-center">
      <div className="d-flex flex-nowrap">
          <div className={styles["list-item-select"]}>
            <Checkbox
                id={`select-dish-${compositeId}`}
                checked={checkboxStatus === "checked"}
                onChange={selectHandler}
                state={checkboxStatus === "partial" ? ["control--partial"] : undefined}
            />
          </div>
          <div className={'d-flex flex-wrap'}>
            <div className={classNames(styles["list-item-title"], !enabled && styles["list-item-title--disabled"])}>{name}</div>
          {portionSize && <div className={classNames(styles["list-item-desc"])}>{portionSize}</div>}
          </div>
      </div>
      {typeof price === "number" && <div className={classNames( styles["list-item-price-wrapper"])}>
          <div className={classNames(styles["list-item-price"], "text-end me-2")}>
              <FormatPrice value={price}/> ₸
          </div>
          <div className={styles["list-item-action"]} onClick={() => {
            if (compositeId) {
              editHandler({compositeId, price, name, description, portionSize: portionSize});
            }
          }}>
            <Icon icon="edit"/>
          </div>
      </div>}
    </div>
    {!enabled &&
      <div className={classNames(styles["list-item-status-row"], "d-flex align-items-center flex-wrap flex-md-nowrap")}>
          <Button
              variant="outline-success"
              onClick={() => compositeId && enableHandler(compositeId)}>
            {t(`actionEnable`)}
          </Button>
          <div className={styles["list-item-status-row-desc"]}>
            {formattedClosedUntil
              ? t(`disabledUntil`, {datetime: formattedClosedUntil})
              : t(`disabledIndefinite`)}
          </div>
      </div>}
  </div>;
}

export default CatalogListItemOutput;
