import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import {LightboxGalleryProps} from "./types";

const LightboxGallery: React.VFC<LightboxGalleryProps> = ({
  images,
  imageIndex,
  setImageIndex,
  closeHandler,
}) =>
  <Lightbox
    mainSrc={images[imageIndex]}
    nextSrc={(images.length > 1 && images[(imageIndex + 1) % images.length]) || undefined}
    prevSrc={(images.length > 1 && images[(imageIndex + images.length - 1) % images.length]) || undefined}
    onCloseRequest={closeHandler}
    onMovePrevRequest={() =>
      setImageIndex((imageIndex + images.length - 1) % images.length)
    }
    onMoveNextRequest={() =>
      setImageIndex((imageIndex + 1) % images.length)
    }
    imageLoadErrorMessage={"Не удалось загрузить изображение"}
    // чтобы лайтбокс был поверх модальных окон бутстрапа
    reactModalStyle={{overlay: {zIndex: 1100}}}
  />;

export default LightboxGallery;
