import React from 'react';
import {OrderComplainCourierSuccessDialogProps} from "./types";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";

/**
 * Диалог успешной жалобы на курьера
 */
const OrderComplainCourierSuccessDialog: React.VFC<OrderComplainCourierSuccessDialogProps> = ({
    show,
    closeDialog
}) => {

    const {t} = useTranslation('translation', {keyPrefix: 'features.orders'});
    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

    return <Modal show={show} onHide={closeDialog} centered>
        <Modal.Header closeButton>
            <Modal.Title>{t('complainCourierSuccess')}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button
                variant="outline-primary"
                onClick={closeDialog}
            >
                {commonT('actionClose')}
            </Button>
        </Modal.Footer>
    </Modal>
};

export default OrderComplainCourierSuccessDialog;
