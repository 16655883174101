import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import StarRatingBar from "../../../../common/components/StarRatingBar";
import {ChipWithValue} from "../../../../common/components/Chip/types";
import Chip from "../../../../common/components/Chip";
import ReviewsListItem from "./ReviewListItem";
import classNames from "classnames";
import styles from "./ReviewsOutput.module.scss";
import ReviewAnswerDialog from "./ReviewAnswerDialog";
import {useAppSelector} from "../../../../app/store";
import {
  reviewsErrorSelector,
  reviewsListSelector,
  reviewsLoadingSelector, reviewsPageCountSelector, reviewsSummaryErrorSelector,
  reviewsSummaryLoadingSelector,
  reviewsSummarySelector, reviewsUnansweredCountSelector
} from "../../reducer";
import {addToUnansweredReviewsCount, getReviews, getReviewsSummary} from "../../actions";
import Loader from "../../../../common/components/Loader";
import ErrorPlug from "../../../../common/components/ErrorPlug";
import Empty from "../../../../common/components/Empty";
import {ReviewAnswerForm, ReviewFilterForm} from "../../models";
import {currentCompanyIdSelector} from "../../../auth/reducer";
import {requestReviewAnswerUpdate} from "../../api";
import {getAxiosErrorMessage} from "../../../../common/utils/functions";
import {addNotify} from "../../../../common/actions/notify";
import {REPLACE_IN_REVIEWS} from "../../constants";
import Pagination from "../../../../common/components/Pagination";
import OrderDetailModal from "../../../orders/components/OrderDetail/OrderDetailModal";

/**
 * Вывод отзывов текущей компании
 */
const ReviewsOutput: React.VFC = () => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation('translation', {keyPrefix: 'features.reviews'});
  
  /** статус монтирования для присваивания состояния после асинхронных запросов */
  const isMounted = useRef(false);
  
  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      isMounted.current = false;
    }
  }, []);
  type ReviewsLocalFilter = {
    type: null | 'noAnswer' | 'positive' | 'negative';
  }
  
  const [filter, setFilter] = useState<ReviewsLocalFilter>({type: null});
  
  /** текущая страница */
  const [currentPage, setCurrentPage] = useState(1);
  
  const unansweredCount = useAppSelector(reviewsUnansweredCountSelector);
  
  const chips: ChipWithValue<ReviewsLocalFilter['type']>[] = useMemo(() => ([
    {
      label: t('allReviews'),
      value: null,
      onClick: () => {
        setFilter(prevState => ({...prevState, type: null}));
        setCurrentPage(1);
      },
    },
    {
      label: t('noAnswerReviews'),
      value: 'noAnswer',
      count: unansweredCount ?? undefined,
      onClick: () => {
        setFilter(prevState => ({...prevState, type: 'noAnswer'}));
        setCurrentPage(1);
      },
    },
    {
      label: t('positiveReviews'),
      value: 'positive',
      onClick: () => {
        setFilter(prevState => ({...prevState, type: 'positive'}));
        setCurrentPage(1);
      },
    },
    {
      label: t('negativeReviews'),
      value: 'negative',
      onClick: () => {
        setFilter(prevState => ({...prevState, type: 'negative'}));
        setCurrentPage(1);
      },
    },
  ]), [t, unansweredCount]);
  
  const companyId = useAppSelector(currentCompanyIdSelector);
  
  useEffect(() => {
    if (typeof companyId === "number") {
      dispatch(getReviewsSummary(companyId));
    }
  }, [companyId, dispatch]);
  
  useEffect(() => {
    const form: ReviewFilterForm = {
      companyIds: typeof companyId === "number" ? [companyId] : []
    };
    
    switch (filter.type) {
      case "noAnswer":
        form.isAnswered = false;
        break;
      case "positive":
        form.ratingFrom = 4;
        break;
      case "negative":
        form.ratingTo = 3;
        break;
    }
    
    dispatch(getReviews(form, {page: currentPage}))
  }, [companyId, currentPage, dispatch, filter]);
  
  const reviews = useAppSelector(reviewsListSelector);
  const loading = useAppSelector(reviewsLoadingSelector);
  const error = useAppSelector(reviewsErrorSelector);
  const pageCount = useAppSelector(reviewsPageCountSelector);
  const summary = useAppSelector(reviewsSummarySelector);
  const summaryLoading = useAppSelector(reviewsSummaryLoadingSelector);
  const summaryError = useAppSelector(reviewsSummaryErrorSelector);
  
  /** id заказа отзыва, показываемого в модальном окне */
  const [orderIdInModal, setOrderIdInModal] = useState<number | null>(null);
  
  const openOrderInModal = useCallback((id: number) => setOrderIdInModal(id), []);
  const closeOrderInModal = useCallback(() => setOrderIdInModal(null), []);
  
  /** id отзыва, на который создается/обновляется ответ */
  const [idForUpdateAnswerDialog, setIdForUpdateAnswerDialog] = useState<number>();
  
  const openUpdateAnswerDialog = useCallback((id: number) => setIdForUpdateAnswerDialog(id), []);
  const closeUpdateAnswerDialog = useCallback(() => setIdForUpdateAnswerDialog(undefined), []);
  
  /** id отзыва, для которого показывать лоадер загрузки (при обновлении ответа) */
  const [loadingReviewId, setLoadingReviewId] = useState<number | null>(null);
  
  const updateAnswer = useCallback((form: ReviewAnswerForm, isCreateAnswer = false) => {
    if (typeof idForUpdateAnswerDialog !== "number") {
      return;
    }
  
    setLoadingReviewId(idForUpdateAnswerDialog);
    closeUpdateAnswerDialog();
    
    requestReviewAnswerUpdate(idForUpdateAnswerDialog, form)
      .then(({data}) => {
        dispatch({
          type: REPLACE_IN_REVIEWS,
          payload: data,
        })
        if (isCreateAnswer) {
          dispatch(addToUnansweredReviewsCount(-1))
        }
      })
      .catch((err) => {
        dispatch(addNotify(getAxiosErrorMessage(err)));
      })
      .finally(() => {
        if (isMounted.current) {
          setLoadingReviewId(null);
        }
      })
  }, [closeUpdateAnswerDialog, dispatch, idForUpdateAnswerDialog])
  
  let content = <Loader/>
  
  if (!loading) {
    if (error) {
      content = <ErrorPlug className={'mb-0'}>{error}</ErrorPlug>
    } else {
      content = reviews.length
        ? <>
            {reviews.map(review =>
              <ReviewsListItem
                key={review.id}
                review={review}
                updateAnswerHandler={openUpdateAnswerDialog}
                openOrderIdInModal={openOrderInModal}
                loading={!!loadingReviewId && review.id === loadingReviewId}
              />)}
    
            <Pagination
              className="mt-3"
              currentPage={currentPage}
              pageCount={pageCount}
              setCurrentPage={setCurrentPage}
            />
          </>
        : <Empty/>
    }
  }
  
  let summaryContent = <Loader className="align-self-center" size="sm"/>
  
  if (!summaryLoading && summary) {
    summaryContent = <div className="d-flex align-items-center">
      <h4 className={classNames("orange me-2 mb-0", styles['increased-lh'])}>{summary.rating}</h4>
      <StarRatingBar rating={summary.rating} className="me-2" state={['bar--larger']}/>
      <div className={styles['text-small']}>
        ({t('review', {count: summary.count})})
      </div>
    </div>
  }
  
  return <>
    {!summaryError && <div className="d-flex flex-column flex-md-row mt-4 mt-md-0 mx-2 mx-md-0">
      <h4 className={classNames("me-3 mb-0", styles['increased-lh'])}>
        {t('generalRating')}
      </h4>
      {summaryContent}
    </div>}
    
    <div className={classNames("d-flex flex-wrap mt-2 mx-2 mx-md-0", styles['filters'])}>
      {chips.map(chip =>
        <Chip
          key={chip.label}
          className="mt-2"
          state={[`chip--${filter.type === chip.value ? "active" : "light-outline"}`]}
          {...chip}
        />)}
    </div>
    
    {content}
    
    <ReviewAnswerDialog
      id={idForUpdateAnswerDialog}
      closeHandler={closeUpdateAnswerDialog}
      submitHandler={updateAnswer}
    />
    <OrderDetailModal
      id={orderIdInModal}
      onHide={closeOrderInModal}
    />
  </>;
};

export default ReviewsOutput;
