import {ReviewFilterForm} from "./models";
import {AppThunkAction} from "../../app/store";
import {ReviewsAction} from "./reducer";
import * as types from "./constants";
import {requestReviews, requestReviewSummary} from "./api";
import {getAxiosErrorMessage} from "../../common/utils/functions";
import {addNotify} from "../../common/actions/notify";
import {APIRequestParameters} from "../../common/utils/apiHelpers";

export const getReviews = (
  form: ReviewFilterForm | null = null,
  {page = 1}: APIRequestParameters = {},
): AppThunkAction<ReviewsAction> => dispatch => {
  
  dispatch({type: types.GET_REVIEWS_REQUEST})
  
  requestReviews(form, {page})
    .then(({data, headers}) => {
      const pageCount = Number(headers['x-pagination-page-count']) || 1;
      
      dispatch({
        type: types.GET_REVIEWS_SUCCESS,
        payload: {
          data: data || [],
          pageCount
        }
      })
      
    }).catch((err) => {
      dispatch({
        type: types.GET_REVIEWS_FAILURE,
        payload: getAxiosErrorMessage(err)
      })
  })
}

export const getReviewsSummary = (
  companyId: number,
): AppThunkAction<ReviewsAction> => dispatch => {
  
  dispatch({type: types.GET_REVIEWS_SUMMARY_REQUEST});
  
  requestReviewSummary(companyId)
    .then(({data}) => {
      dispatch({
        type: types.GET_REVIEWS_SUMMARY_SUCCESS,
        payload: data,
      });
    })
    .catch((err) => {
      const message = getAxiosErrorMessage(err);
      
      dispatch({
        type: types.GET_REVIEWS_SUMMARY_FAILURE,
        payload: message,
      });
      dispatch(addNotify(message));
    })
}

export const setUnansweredReviewsCount = (
  count: number,
): AppThunkAction<ReviewsAction> => dispatch => {
  dispatch({
    type: types.SET_UNANSWERED_REVIEWS_COUNT,
    payload: count,
  })
}

export const addToUnansweredReviewsCount = (
  number = 1,
): AppThunkAction<ReviewsAction> => dispatch => {
  dispatch({
    type: types.ADD_TO_UNANSWERED_REVIEWS_COUNT,
    payload: number,
  })
}
