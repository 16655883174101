import React from 'react';
import {Modal} from "react-bootstrap";
import OrderDetail from "./index";
import {OrderDetailModalProps} from "./types";

/**
 * Просмотр заказа в модальном окне
 */
const OrderDetailModal: React.VFC<OrderDetailModalProps> = ({
  id,
  onHide,
}) =>
  <Modal show={!!id} onHide={onHide} centered size="xl">
    <Modal.Header closeButton>
      <Modal.Title/>
    </Modal.Header>
    <Modal.Body>
      {typeof id === "number" && <OrderDetail id={String(id)} inModal/>}
    </Modal.Body>
    <Modal.Footer/>
  </Modal>;

export default OrderDetailModal;
