import React, {useEffect, useState} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';
import {createPortal} from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {removeNotify} from "../../actions/notify";
import {useTranslation} from "react-i18next";

const noticeRoot = document.getElementById('root');
const el = document.createElement('div') as HTMLElement;
el.className = 'notice';

/**
 * Компонент - уведомление об ошибке
 * @constructor
 */
const Notify: React.FC = () => {

    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

    const {
        message
    } = useSelector((state: RootState) => ({
        message: state.notify.message
    }));

    useEffect(() => {
        if (message) {
            if (!noticeRoot) {
                return;
            }

            /**
             * Отобразить компонент
             */
            setIsOpen(true);

            /**
             * добавить компонент в портал
             */
            noticeRoot.appendChild(el);

            /**
             * Функция закрытия компонента через 5 секунд
             * @type {number}
             */
            const timerHandler = setTimeout(() => {
                setIsOpen(false);
                dispatch(removeNotify())
            }, 5000);

            return () => {
                /**
                 * удалить компонент из портала
                 */
                noticeRoot.removeChild(el);

                /**
                 * удалить таймер
                 */
                clearTimeout(timerHandler);
            }
        }
    }, [dispatch, message]);

    const closeHandler = () => {
        setIsOpen(false);
        dispatch(removeNotify())
    }

    return isOpen ? createPortal(
        <ToastContainer
            className={'mt-5'}
            style={{
                position: 'fixed',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)'
            }}
        >
                <Toast
                    onClose={closeHandler}
                    bg={'light'}
                >
                    <Toast.Header>
                        <strong className="me-auto" style={{lineHeight: 1}}>
                            {commonT("error")}
                        </strong>
                    </Toast.Header>
                    <Toast.Body>{message}</Toast.Body>
                </Toast>
        </ToastContainer>, el)
        : null;
};

export default Notify;
