import React, {useMemo} from 'react';
import {OrderComplainCourierDialogProps} from "./types";
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import Radio from "../../../../../common/components/Radio";
import TextArea from "../../../../../common/components/TextArea";
import * as Yup from "yup";
import {requiredMessage} from "../../../../../common/utils/validation";
import BootstrapForm from "react-bootstrap/Form";
import {useDispatch} from "react-redux";
import {complainCourier} from "../../../actions";

enum ComplainTypes {
    rudeOrUnprofessional = 'rudeOrUnprofessional',
    longDelivery = 'longDelivery',
    internalRules = 'internalRules',
    noResistantBag = 'noResistantBag',
    anotherPhoto = 'anotherPhoto',
    anotherCause = 'anotherCause'
}

/**
 * Диалог пожаловаться на курьера
 */
const OrderComplainCourierDialog: React.VFC<OrderComplainCourierDialogProps> = ({
    orderId,
    show,
    closeDialog
}) => {

    const dispatch = useDispatch();

    const {t} = useTranslation('translation', {keyPrefix: 'features.orders'});
    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

    const validationSchema = useMemo(() =>
            Yup.object().shape({
                complainType: Yup.string().required(),
                message: Yup.string().when("complainType", {
                    is: ComplainTypes.anotherCause,
                    then: Yup.string().required(requiredMessage())
                })
            }),
        []);

    return <Modal show={show} onHide={closeDialog} centered>
        <Modal.Header closeButton>
            <Modal.Title>{t('actionComplainCourier')}</Modal.Title>
        </Modal.Header>
        <Formik
            initialValues={{
                complainType: ComplainTypes.rudeOrUnprofessional,
                message: ''
            }}
            validationSchema={validationSchema}
            onSubmit={({complainType, message}) => {

                const form = {
                    orderId,
                    message: ''
                };

                switch (complainType) {
                    case ComplainTypes.rudeOrUnprofessional:
                        form.message = t("complainType.rudeOrUnprofessional");
                        break;
                    case ComplainTypes.longDelivery:
                        form.message = t("complainType.longDelivery");
                        break;
                    case ComplainTypes.internalRules:
                        form.message = t("complainType.internalRules");
                        break;
                    case ComplainTypes.noResistantBag:
                        form.message = t("complainType.noResistantBag");
                        break;
                    case ComplainTypes.anotherPhoto:
                        form.message = t("complainType.anotherPhoto");
                        break;
                    case ComplainTypes.anotherCause:
                        form.message = message;
                        break;
                }

                dispatch(complainCourier(form));
                closeDialog();
            }}
        >
            {({values, setFieldValue, isValid}) => <Form>
                <Modal.Body>
                    <BootstrapForm.Label htmlFor="complainType">{t("chooseOption")}</BootstrapForm.Label>

                    <Radio
                        className={'mt-3'}
                        id={ComplainTypes.rudeOrUnprofessional}
                        checked={values.complainType === ComplainTypes.rudeOrUnprofessional}
                        label={t("complainType.rudeOrUnprofessional")}
                        onChange={() => {
                            setFieldValue('complainType', ComplainTypes.rudeOrUnprofessional)
                        }}
                    />
                    <Radio
                        className={'mt-3'}
                        id={ComplainTypes.longDelivery}
                        checked={values.complainType === ComplainTypes.longDelivery}
                        label={t("complainType.longDelivery")}
                        onChange={() => {
                            setFieldValue('complainType', ComplainTypes.longDelivery)
                        }}
                    />
                    <Radio
                        className={'mt-3'}
                        id={ComplainTypes.internalRules}
                        checked={values.complainType === ComplainTypes.internalRules}
                        label={t("complainType.internalRules")}
                        onChange={() => {
                            setFieldValue('complainType', ComplainTypes.internalRules)
                        }}
                    />
                    <Radio
                        className={'mt-3'}
                        id={ComplainTypes.noResistantBag}
                        checked={values.complainType === ComplainTypes.noResistantBag}
                        label={t("complainType.noResistantBag")}
                        onChange={() => {
                            setFieldValue('complainType', ComplainTypes.noResistantBag)
                        }}
                    />
                    <Radio
                        className={'mt-3'}
                        id={ComplainTypes.anotherPhoto}
                        checked={values.complainType === ComplainTypes.anotherPhoto}
                        label={t("complainType.anotherPhoto")}
                        onChange={() => {
                            setFieldValue('complainType', ComplainTypes.anotherPhoto)
                        }}
                    />
                    <Radio
                        className={'mt-3'}
                        id={ComplainTypes.anotherCause}
                        checked={values.complainType === ComplainTypes.anotherCause}
                        label={<Field
                            wrapperClassName={'w-100'}
                            name={'message'}
                            component={TextArea}
                            placeholder={t("complainType.another")}
                        />}
                        onChange={() => {
                            setFieldValue('complainType', ComplainTypes.anotherCause)
                        }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={closeDialog}>
                        {commonT('actionCancel')}
                    </Button>
                    <Button
                        variant="outline-primary"
                        type="submit"
                        disabled={!isValid}
                    >
                        {commonT('actionSubmit')}
                    </Button>
                </Modal.Footer>
            </Form>}
        </Formik>
    </Modal>
};

export default OrderComplainCourierDialog;
