import {combineReducers} from "redux";
import auth from "../features/auth/reducer";
import catalogProducts from "../features/catalog/subfeatures/products/reducer";
import catalogAttributes from "../features/catalog/subfeatures/attributes/reducer";
import orders from "../features/orders/reducer";
import {LOGOUT} from "../features/auth/constants";
import notify from "../common/reducers/notify";
import toasts from "../common/reducers/toasts";
import archiveOrders from "../features/archive/reducer";
import reviews from "../features/reviews/reducer";
import appStatus from "../common/reducers/appStatus";

const combinedReducers = combineReducers({
  auth,
  orders,
  archiveOrders,
  catalogProducts,
  catalogAttributes,
  notify,
  toasts,
  reviews,
  appStatus,
})

const reducer = (state: Parameters<typeof combinedReducers>[0], action: Parameters<typeof combinedReducers>[1]) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  
  return combinedReducers(state, action);
}

export default reducer;
