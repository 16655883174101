import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useAppSelector} from "../../../../app/store";
import {currentCompanyIdSelector} from "../../../auth/reducer";
import Box from "../../../../common/components/Box";
import {useTranslation} from "react-i18next";
import {Field, Form, Formik} from "formik";
import Loader from '../../../../common/components/Loader';
import Table from "react-bootstrap/Table";
import TimePicker from "../../../../common/components/DateTimePicker/TimePicker";
import Button from "react-bootstrap/Button";
import {CompanyResponse, CompanyWorkday, CompanyWorkdayForm} from "../../models";
import {requestChangeCompanyWorkdays, requestGetCompany} from "../../api";
import {getAxiosErrorMessage} from "../../../../common/utils/functions";
import {addNotify} from "../../../../common/actions/notify";
import {useDispatch} from "react-redux";
import ErrorPlug from "../../../../common/components/ErrorPlug";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {requiredMessage} from "../../../../common/utils/validation";
import Spinner from "react-bootstrap/Spinner";
import Icon from "../../../../common/components/Icon";

/**
 * Компонент просмотра графика работы и контактной информации текущего заведения
 */
const ProfileView: React.VFC = () => {
  
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const companyId = useAppSelector(currentCompanyIdSelector);
  
  const [data, setData] = useState<CompanyResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>();
  
  /** статус монтирования компонента для присвоения результатов асинхронной операции */
  const isMounted = useRef(false);
  
  const getCompany = useCallback(() => {
    if (companyId) {
      if (isMounted.current) {
        setLoading(true);
      }
      
      requestGetCompany(companyId)
        .then(({data}) => {
          if (isMounted.current) {
            setData(data);
          }
        })
        .catch((err) => {
          if (isMounted.current) {
            setError(getAxiosErrorMessage(err));
          }
        })
        .finally(() => {
          if (isMounted.current) {
            setLoading(false);
          }
        })
    }
  }, [companyId]);
  
  useEffect(() => {
    isMounted.current = true;
    
    getCompany();
    
    return () => {
      isMounted.current = false;
    }
  }, [getCompany]);
  
  const [changeWorkDaysLoading, setChangeWorkDaysLoading] = useState(false);
  const [resetForm, setResetForm] = useState<CompanyWorkdayForm[] | null>(null);
  
  /**
   * Изменить график работы
   * @param form - форма нового графика
   * @param isReset - если true, то изменение является сбросом и нужно убрать возможность сброса после успеха запроса
   */
  const changeWorkDays = (
    form: CompanyWorkdayForm[],
    isReset = false,
  ) => {
    if (companyId) {
      setChangeWorkDaysLoading(true);
      const resetFormToBe = isReset || !data?.workdays
        ? null
        : data.workdays;
      
      requestChangeCompanyWorkdays(companyId, form)
        .then(({data}) => {
          if (isMounted.current) {
            setData(data);
            setResetForm(resetFormToBe);
          }
        })
        .catch((err) => {
          dispatch(addNotify(getAxiosErrorMessage(err)));
        })
        .finally(() => {
          if (isMounted.current) {
            setChangeWorkDaysLoading(false);
          }
        })
    }
  }
  
  type WorkdayValues = {workDays: CompanyWorkday[]};
  
  if (error) {
    return <ErrorPlug onClick={getCompany}>
      {error}
    </ErrorPlug>
  }
  
  if (loading) {
    return <Loader/>
  }
  
  if (!data) {
    return null;
  }
  
  const {
    name,
    city,
    workdays,
  } = data;
  
  const initialValues: WorkdayValues = {
    workDays: [1, 2, 3, 4, 5, 6, 7].map(day =>
      workdays?.find(({day: _day}) => _day === day)
      ?? ({
        day,
        timeFrom: '',
        timeTo: '',
      })),
  }
  
  return <Box className="m-2 m-md-0">
    <h3 className="mb-0">{name}</h3>
    <p className="mb-4">{city?.name}</p>
    <Row>
      <Col md={7} lg={5}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={(values: WorkdayValues) => {
            changeWorkDays(values.workDays.filter(({timeFrom, timeTo}) => !!timeFrom && !!timeTo));
          }}>
          {({values, setFieldValue, setFieldTouched, isValid}) => {
  
            /**
             * Функция изменения вынесена сюда для исправления запаздывания
             */
            const onChange = (name: string, value: string | null) => {
              setFieldValue(name, value);
  
              /**
               * Исправление запаздывания валидации на один шаг
               */
              setTimeout(() => {
                if (isMounted.current) {
                  setFieldTouched(name)
                }
              }, 0)
            }
            
            return <Form>
              <h6 className="mb-2">{t('features.profile.schedule')}</h6>
              <Table striped borderless>
                <tbody>
                {values.workDays.map(({day, timeFrom, timeTo}, index) => {
  
                  const validate = () => !!timeTo !== !!timeFrom
                    ? requiredMessage()
                    : '';
                  
                  return <tr className="align-middle" key={day}>
                    <td className="fw-600 ps-2 ps-md-4 pe-2 pe-md-5 text-uppercase">
                      {t(`common.daysOfTheWeekShort.${translationKeys[day]}`)}
                    </td>
                    <td className="fw-600 ps-0 pe-0 pe-md-2 text-lowercase">
                      {t('common.from')}
                    </td>
                    <td>
                      <Field
                        name={`workDays.${index}.timeFrom`}
                        component={TimePicker}
                        className={'form-control'}
                        onChange={onChange}
                        validate={validate}
                        hasError={!!timeTo !== !!timeFrom}
                      />
                    </td>
                    <td className="fw-600 text-lowercase pe-0 pe-md-2">
                      {t('common.to')}
                    </td>
                    <td>
                      <Field
                        name={`workDays.${index}.timeTo`}
                        component={TimePicker}
                        className={'form-control'}
                        onChange={onChange}
                        validate={validate}
                        hasError={!!timeTo !== !!timeFrom}
                      />
                    </td>
                  </tr>
                })}
                </tbody>
              </Table>
              <div className="d-flex align-items-center">
                {!!resetForm && <Button
                  className="me-2_5"
                  disabled={changeWorkDaysLoading}
                  onClick={() => changeWorkDays(resetForm, true)}
                  variant="outline-secondary">
                  {t('common.actionCancel')}
                </Button>}
                <Button
                  disabled={!isValid || changeWorkDaysLoading}
                  type="submit"
                  variant="outline-success">
                  {t('common.actionSave')}
                </Button>
                {!changeWorkDaysLoading
                  && !!resetForm
                  && <Icon className="ms-2_5 green" icon={'check'}/>}
                {/* не через <Loader/>, так как там паддинг p-2
                    (из-за чего высота лоадера 48 > высоты кнопки в 39 и верстка смещается)
                    и паддинг сложно переопределить */}
                {changeWorkDaysLoading && <div className="d-flex justify-content-center ms-3">
                    <Spinner animation="border" variant="primary"/>
                </div>}
              </div>
            </Form>;
          }}
        </Formik>
      </Col>
    </Row>
  </Box>
};

const translationKeys: Record<number, string> = {
  1: 'monday',
  2: 'tuesday',
  3: 'wednesday',
  4: 'thursday',
  5: 'friday',
  6: 'saturday',
  7: 'sunday',
}

export default ProfileView;
