import React, {useEffect, useMemo, useRef, useState} from 'react';
import Popover from "react-bootstrap/Popover";
import Button from "react-bootstrap/Button";
import styles from "./OrderTimerDisplay.module.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {OrderTimerDisplayProps} from "./types";
import {getDiffInSeconds} from "../../../../helpers";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {addHours, parse} from "date-fns";
import {enUS} from "date-fns/locale";

const OrderTimerDisplay: React.VFC<OrderTimerDisplayProps> = ({
  order,
  dateHeader,
  variant,
  higherPlacement = false,
}) => {
  
  const {
    id,
    created,
  } = order;
  
  /** статус монтирования компонента для присвоения результатов асинхронной операции */
  const isMounted = useRef(false);
  
  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      isMounted.current = false;
    }
  }, []);
  
  /**
   * Парсинг даты заголовка запроса.
   * Она приходит в формате наподобие "Fri, 12 Jul 2024 11:50:51 GMT"
   */
  const headerDateObject = useMemo(() => {
    
    /** убирается название дня недели и часовой пояс (он всегда GMT) */
    const slicedDateString = dateHeader.slice(5, -4);
    
    /** дата в часовом поясе GMT */
    const GMTDate = parse(
      slicedDateString,
      'dd LLL yyyy HH:mm:ss',
      new Date(),
      {locale: enUS}
    )
    
    /** добавить 5 часов, чтобы привести дату к используемому часовому поясу */
    return addHours(GMTDate, 5);
  }, [dateHeader])
  
  /** секунды, оставшиеся до истечения таймера */
  const [secondsLeft, setSecondsLeft] = useState(
    created ? getDiffInSeconds(created, headerDateObject) : 0
  );
  
  useEffect(() => {
    if (created) {
      setSecondsLeft(
        getDiffInSeconds(created, headerDateObject)
      )
    }
  }, [created, headerDateObject]);
  
  /** реф таймера для возможности отключения снаружи */
  const timerRef = useRef<NodeJS.Timeout>();
  
  const {t} = useTranslation('translation', {keyPrefix: 'features.orders'})
  
  useEffect(() => {
    if (created) {
      timerRef.current = setInterval(() => {
        
        if (isMounted.current) {
          setSecondsLeft(prevState => Math.max(0, prevState - 1));
        }
      }, 1000);
      
      return () => {
        if (timerRef.current) {
          clearInterval(timerRef.current);
        }
      }
    }
  }, [created]);
  
  /**
   * При истечении таймера его нужно отключить. Но это нельзя сделать внутри колбека таймера,
   * потому что тогда нужно передавать туда secondsLeft, а это значение меняется каждую секунду,
   * из-за чего эффект будет работать каждую секунду и таймер будет пересоздаваться.
   * Так что отключение таймера происходит здесь, снаружи.
   */
  useEffect(() => {
    if (!secondsLeft && timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [secondsLeft]);
  
  /**
   * Отображение оставшегося времени в формате "мм:сс"
   */
  const timerString = useMemo(() => {
    if (!secondsLeft) {
      return '00:00';
    }
    
    const minutes = Math.floor(secondsLeft / 60);
    const seconds = secondsLeft - minutes * 60;
    const displayedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    
    return `0${minutes}:${displayedSeconds}`;
  }, [secondsLeft]);
  
  const [popoverShow, setPopoverShow] = useState(false);
  
  return <OverlayTrigger
    trigger={'click'}
    placement={'bottom'}
    show={popoverShow}
    onToggle={(nextShow) => {
      setPopoverShow(nextShow);
    }}
    overlay={<Popover body id={`popover-${id}`} className={classNames(!higherPlacement && styles['popover'])}>
        <div className='d-flex align-items-center'>
          <div className='me-3 pre-wrap'>
            {t('timeToStartProcessingOrder')}
          </div>
          <Button size={'sm'} onClick={() => setPopoverShow(false)}>
            ОК
          </Button>
        </div>
    </Popover>}>
    <div className={classNames(
      styles['timer'],
      variant && `text-${variant}`,
      variant && `border-${variant}`,
    )}>
      {timerString}
    </div>
  </OverlayTrigger>;
};

export default OrderTimerDisplay;
