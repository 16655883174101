import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from "./OrdersList.module.scss";
import {useAppSelector} from "../../../../app/store";
import {useDispatch} from "react-redux";
import {getActiveOrders} from "../../actions";
import {ordersErrorSelector, ordersListSelector, ordersLoadingSelector} from "../../reducer";
import Loader from "../../../../common/components/Loader";
import ErrorPlug from "../../../../common/components/ErrorPlug";
import OrderListItem from "./OrderListItem";
import {useTranslation} from "react-i18next";
import classNames from 'classnames';
import {ChipWithValue} from "../../../../common/components/Chip/types";
import Chip from "../../../../common/components/Chip";
import {companyChangeLoadingSelector, currentCompanyIdSelector, employeeDataSelector} from "../../../auth/reducer";
import Button from "react-bootstrap/Button";
import {format} from 'date-fns';
import {enableCompany} from "../../../auth/actions";
import Empty from "../../../../common/components/Empty";
import Modal from "react-bootstrap/Modal";
import Icon from "../../../../common/components/Icon";
import {isOrderAccepted, isOrderCompleted, isOrderNew, isOrderNotInProgress} from "../../helpers";
import OrderDetailModal from "../OrderDetail/OrderDetailModal";
import {CompanyStatuses} from "../../../profile/models";
import iconDoorSign from "../../../../assets/images/icon_doorsign.svg";
import {OrderChangeStatusInfo} from "../OrderChangeStatusDialog/types";
import {addNotify} from "../../../../common/actions/notify";
import {getAxiosErrorMessage} from "../../../../common/utils/functions";
import {REMOVE_FROM_ORDERS, REPLACE_IN_ORDERS} from "../../constants";
import {requestOrderStatusChange} from "../../api";
import {TimeReadyForm} from "../../models";
import OrderChangeStatusDialog from "../OrderChangeStatusDialog";
import OrderAcceptanceInfo from "./OrdersAcceptanceInfo";
import {parseServerDate} from "../../../../common/utils/dateTimeHelpers";

/**
 * Вывод активных заказов
 */
const ActiveOrdersOutput: React.VFC = () => {
  
  const dispatch = useDispatch();
  const expand = 'city,company,orderDelivery,orderSamovivoz,allowTransitions';
  
  const {t} = useTranslation('translation', {keyPrefix: 'features.orders'});
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});
  
  useEffect(() => {
    dispatch(getActiveOrders(expand));
  }, [dispatch]);
  
  const orders = useAppSelector(ordersListSelector);
  const ordersLoading = useAppSelector(ordersLoadingSelector);
  const ordersError = useAppSelector(ordersErrorSelector);
  
  const companyId = useAppSelector(currentCompanyIdSelector);
  const companyChangeLoading = useAppSelector(companyChangeLoadingSelector);
  
  const {
    companies,
  } = useAppSelector(employeeDataSelector) ?? {};
  
  const currentCompany = companies?.find(({id}) => id === companyId);
  
  const companyDisabled = !!currentCompany?.isClosed;
  const companyClosedUntil = currentCompany?.closedUntil
    ? format(parseServerDate(currentCompany.closedUntil), 'HH:mm dd.MM.yyyy')
    : '';
  const companyStatus = currentCompany?.status;
  
  const hasClosedPlaque = !companyChangeLoading && companyId && companyDisabled;
  
  let closedPlaqueLabel = commonT('closed');
  let hasOpenCompanyButton = false;
  let hasClosedUntil = false;
  
  switch (companyStatus) {
    /**
     * Если статус заведения равен: Закрыто за долг, Приостановлено,
     * то показываем информацию о том, что заведение закрыто, без возможности его открыть.
     * Если статус заведения Открыто, то блок вообще не показывается.
     * (значения по умолчанию)
     */
    case CompanyStatuses.DisabledDueToDebt:
    case CompanyStatuses.Paused:
      break;
    /**
     * Если статус заведения равен Закрыто заведением,
     * показываем блок без времени и с кнопкой открытия
     */
    case CompanyStatuses.DisabledByCompany:
      hasOpenCompanyButton = true;
      break;
    case CompanyStatuses.Enabled:
    case CompanyStatuses.Disabled:
      /**
       * Если статус заведения НЕ равен Закрыто заведением и параметр closedUntil НЕ пуст,
       * то показываем блок с временем закрытия и с кнопкой открытия заведения;
       */
      if (companyClosedUntil) {
        hasOpenCompanyButton = true;
        hasClosedUntil = true;
        break;
      }
  
      /**
       * Если статус заведения НЕ равен Закрыто заведением и параметр closedUntil пуст,
       * то показываем блок с подписью о том, что закрыто по графику работы и убираем кнопку открытия
       */
      closedPlaqueLabel = commonT('closedDueToSchedule')
      break;
    default:
      break;
  }
  
  /** id заказа, показываемого в модальном окне */
  const [orderIdInModal, setOrderIdInModal] = useState<number | null>(null);
  
  const openOrderInModal = useCallback((id: number) => setOrderIdInModal(id), []);
  const closeOrderInModal = useCallback(() => setOrderIdInModal(null), []);
  
  const [statusChangeInfo, setStatusChangeInfo] = useState<OrderChangeStatusInfo | null>(null);

  const openStatusChangeDialog = useCallback((info: OrderChangeStatusInfo) => setStatusChangeInfo(info), []);
  const closeStatusChangeDialog = useCallback(() => setStatusChangeInfo(null), []);
  
  /**
   * Изменить статус заказа
   */
  const changeOrderStatus = (form: TimeReadyForm | null) => {
    if (!statusChangeInfo) {
      return;
    }
    
    const statusId = statusChangeInfo.statusId;
    const orderId = statusChangeInfo.orderId;
    closeStatusChangeDialog();
    
    requestOrderStatusChange(orderId, statusId, form)
      .then(({data}) => {
        /** если изменение статуса сделало заказ отмененным/завершенным, убрать его из списка */
        if (isOrderNotInProgress(data)) {
          dispatch({
            type: REMOVE_FROM_ORDERS,
            payload: data.id,
          })
          
          return;
        }
        
        dispatch({
          type: REPLACE_IN_ORDERS,
          payload: data,
        })
      })
      .catch((err) => {
        dispatch(addNotify(getAxiosErrorMessage(err)));
      })
  }
  
  const [isShowCompanyOpenConfirmDialog, setIsShowCompanyOpenConfirmDialog] = useState(false);

  const [isShowNewOrderHelpDialog, setIsShowNewOrderHelpDialog] = useState(false);

  const openNewOrderHelpDialog = () => {
    setIsShowNewOrderHelpDialog(true);
  }

  const closeNewOrderHelpDialog = () => {
    setIsShowNewOrderHelpDialog(false);
  }

  const closeShowCompanyOpenConfirmDialog = () => {
    setIsShowCompanyOpenConfirmDialog(false);
  }

  const openCompany = () => {
    if (companyId) {
      dispatch(enableCompany(companyId));
      setIsShowCompanyOpenConfirmDialog(false);
    }
  }
  
  type OrdersListFilter = {
    statusGroup: 'new' | 'accepted' | 'completed' | null;
  }
  
  /** фильтр */
  const [ordersFilter, setOrdersFilter] = useState<OrdersListFilter>({statusGroup: null});

  /** новые заказы */
  const newOrders = useMemo(() => orders.filter(isOrderNew), [orders])
  /** принятые заказы */
  const acceptedOrders = useMemo(() => orders.filter(isOrderAccepted), [orders])
  /** готовые заказы */
  const completedOrders = useMemo(() => orders.filter(isOrderCompleted), [orders])

  const [ordersCount, setOrdersCount] = useState({
    all: 0,
    accepted: 0,
    completed: 0
  });

  useEffect(() => {
    if (Array.isArray(orders)) {
      setOrdersCount({
        all: orders.length,
        accepted: orders.filter(isOrderAccepted).length,
        completed: orders.filter(isOrderCompleted).length
      })
    }
  }, [orders]);
  
  const chips: ChipWithValue<OrdersListFilter['statusGroup']>[] = useMemo(() => ([
    {
      label: t('all'),
      value: null,
      count: ordersCount.all,
      onClick: () =>
        setOrdersFilter({
          statusGroup: null,
        }),
    },
    // {
    //   label: t('new'),
    //   value: 'new',
    //   onClick: () =>
    //     setOrdersFilter({
    //       statusGroup: 'new',
    //     }),
    // },
    {
      label: t('accepted'),
      value: 'accepted',
      count: ordersCount.accepted,
      onClick: () =>
        setOrdersFilter({
          statusGroup: 'accepted',
        }),
    },
    {
      label: t('completed'),
      value: 'completed',
      count: ordersCount.completed,
      onClick: () =>
        setOrdersFilter({
          statusGroup: 'completed',
        }),
    },
  ]), [t, ordersCount]);
  
  let content = <Loader/>
  
  if (!ordersLoading) {
    if (ordersError) {
      content = <ErrorPlug onClick={() => dispatch(getActiveOrders(expand))} className='mt-4'>
        {ordersError}
      </ErrorPlug>
    } else {
      const sharedListItemProps = {
        statusChangeHandler: openStatusChangeDialog,
        onClick: openOrderInModal,
      }
      
      content = <>
        {(!ordersFilter.statusGroup || ordersFilter.statusGroup === 'new') && <div className={'d-flex justify-content-between'}>
          <h4 className={classNames("mb-2_5", styles["list-heading"])}>
            {t('newOrders')}
          </h4>
          <div className={'mt-auto mb-1'}>
            <div onClick={openNewOrderHelpDialog}>
              <Icon className={styles['help-button']}
                    icon={'help_outline'}
              />
            </div>
          </div>
        </div>}
        {!!newOrders.length
          ? newOrders.map((order) =>
              <OrderListItem
                key={order.id}
                order={order}
                state={ordersFilter.statusGroup && ordersFilter.statusGroup !== 'new' ? ['item--hidden'] : undefined}
                {...sharedListItemProps}
              />)
          : <div className={classNames(ordersFilter.statusGroup && ordersFilter.statusGroup !== 'new' && "d-none", "d-flex justify-content-center")}>
              <Empty className={'col-12'} value={
                <div className={'d-flex flex-column justify-content-center'}>
                  <Icon icon={'shopping_bag'} className={styles["icon-empty"]}/>
                  <h4>{t('emptyNewOrders')}</h4>
                </div>}/>
            </div>}
        {(!ordersFilter.statusGroup || ordersFilter.statusGroup === 'accepted') && <h4 className={classNames("mb-2_5", styles["list-heading"])}>
          {t('acceptedOrders')}
        </h4>}
        {!!acceptedOrders.length
          ? acceptedOrders.map((order) =>
              <OrderListItem
                key={order.id}
                order={order}
                state={ordersFilter.statusGroup && ordersFilter.statusGroup !== 'accepted' ? ['item--hidden'] : undefined}
                {...sharedListItemProps}
              />)
          : <div className={classNames(ordersFilter.statusGroup && ordersFilter.statusGroup !== 'accepted' && "d-none", "d-flex justify-content-center")}>
              <Empty className={'col-12'} value={
                <div className={'d-flex flex-column justify-content-center'}>
                  <Icon icon={'shopping_bag'} className={styles["icon-empty"]} />
                  <h4>{t('emptyAcceptedOrders')}</h4>
                </div>}/>
            </div>}
        {(!ordersFilter.statusGroup || ordersFilter.statusGroup === 'completed') && <h4 className={classNames("mb-2_5", styles["list-heading"])}>
            {t('completedOrders')}
        </h4>}
        {!!completedOrders.length
          ? completedOrders.map((order) =>
              <OrderListItem
                key={order.id}
                order={order}
                state={ordersFilter.statusGroup && ordersFilter.statusGroup !== 'completed' ? ['item--hidden'] : undefined}
                {...sharedListItemProps}
              />)
          : <div className={classNames(ordersFilter.statusGroup && ordersFilter.statusGroup !== 'completed' && "d-none", "d-flex justify-content-center")}>
              <Empty className={'col-12'} value={
                <div className={'d-flex flex-column justify-content-center'}>
                  <Icon icon={'shopping_bag'} className={styles["icon-empty"]} />
                  <h4>{t('emptyCompletedOrders')}</h4>
                </div>}/>
            </div>}
      </>
    }
  }
  
  return <>
    <div className="d-flex justify-content-between flex-column flex-md-row">
      <div className="d-flex flex-wrap order-2 order-md-1 mt-1 mt-md-n2 mx-2 mx-md-0">
        {chips.map(chip =>
          <Chip
            key={chip.label}
            className="mt-2"
            state={[`chip--${ordersFilter.statusGroup === chip.value ? "active" : "light-outline"}`]}
            {...chip}
          />)}
      </div>
      <div className={classNames("d-flex align-items-center mt-md-0 mx-2 mx-md-0 order-1 order-md-2", hasClosedPlaque && "mt-2")}>
        {hasClosedPlaque && <div className={classNames(styles["plaque"])}>
            <div className={classNames(styles["plaque-label"])}>
                <div className={classNames("d-none d-md-block", hasOpenCompanyButton && "me-3")}>
                  {hasClosedUntil
                    ? commonT('closedUntil', {datetime: companyClosedUntil})
                    : closedPlaqueLabel}
                </div>
                <div className="d-block d-md-none">
                    <div className="d-flex align-items-center">
                        <img className="me-2" src={iconDoorSign} alt="" width={24} height={24}/>
                        <div>
                          <div>{closedPlaqueLabel}</div>
                          {hasClosedUntil && <div className={styles['plaque-label-desc']}>{commonT('until', {datetime: companyClosedUntil})}</div>}
                        </div>
                    </div>
                </div>
            </div>
            {hasOpenCompanyButton && <>
              <Button
                className="d-none d-lg-flex"
                onClick={() => setIsShowCompanyOpenConfirmDialog(true)}
                variant="outline-success">
                {commonT('actionEnableCompany')}
              </Button>
              <Button
                className={classNames(styles["plaque-button"], "d-flex d-lg-none")}
                onClick={() => setIsShowCompanyOpenConfirmDialog(true)}
                size="sm"
                variant="success">
                {commonT('actionOpen')}
              </Button>
            </>}
        </div>}
      </div>
    </div>
    <OrderAcceptanceInfo/>
    <div className='mx-2 mx-md-0'>
      {content}
    </div>
    <OrderChangeStatusDialog
      info={statusChangeInfo}
      closeHandler={closeStatusChangeDialog}
      submitHandler={changeOrderStatus}
    />
    <OrderDetailModal
      id={orderIdInModal}
      onHide={closeOrderInModal}
    />

    <Modal show={isShowCompanyOpenConfirmDialog} onHide={closeShowCompanyOpenConfirmDialog} centered>
      <Modal.Header closeButton>
        <Modal.Title>{commonT('isOpenCompany')}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={closeShowCompanyOpenConfirmDialog}>
          {commonT('actionCancel')}
        </Button>
        <Button variant="outline-primary" onClick={openCompany}>
          {commonT('actionSubmit')}
        </Button>
      </Modal.Footer>
    </Modal>

    <Modal show={isShowNewOrderHelpDialog} onHide={closeNewOrderHelpDialog} centered>
      <Modal.Header closeButton className="d-flex justify-content-between">
        <div style={{width: 24, height: 24}}></div>
        <h4>{t('help')}</h4>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex flex-column text-center'>
          <div>{t('helpEmenuSupport')}</div>
          <div>+7 775 030 95 55</div>
        </div>
        <div className='d-flex justify-content-center mt-3 mb-3'>
          <Button variant={'outline-primary'} href="tel:+77750309555">
            {t('actionCall')}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  </>;
};

export default ActiveOrdersOutput;
