import React, { Component, ErrorInfo } from "react";
import {Alert} from "react-bootstrap";
import {ErrorBoundaryProps, ErrorBoundaryState} from "./types";
import {useTranslation} from "react-i18next";

/**
 * Компонент - предохранитель
 */
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    public state: ErrorBoundaryState = {
        hasError: false
    };

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error("Uncaught error:", error, errorInfo);
    }

    public render() {
        if (this.state.hasError) {
            return (
                <ErrorBoundaryAlert/>
            )
        }

        return this.props.children;
    }
}

const ErrorBoundaryAlert = () => {

    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

    return(
        <Alert className={'container'} variant="danger">
            {commonT("boundaryErrorMessage")}
        </Alert>
    )
}

export default ErrorBoundary;