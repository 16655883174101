import React from "react";
import {RouteComponentProps} from "react-router-dom";
import OrderDetail from "./index";

/**
 * Обертка для просмотра заказа, если параметры берутся из URL
 */
const OrderDetailPage: React.VFC<RouteComponentProps<{id: string}>> =
  ({match: {params: {id}}}) => <OrderDetail id={id}/>
  
export default OrderDetailPage;
