import React, {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import styles from "./Layout.module.scss";
import {useAppSelector} from "../../../app/store";
import {
  companyChangeLoadingSelector,
  currentCompanyIdSelector,
} from "../../../features/auth/reducer";
import {useDispatch} from "react-redux";
import {enableCompany} from "../../../features/auth/actions";
import Loader from "../Loader";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import {CompanyStatusToggleProps} from "./types";

/**
 * Переключатель статуса текущего заведения
 */
const CompanyStatusToggle: React.VFC<CompanyStatusToggleProps> = ({
  isCompanyDisabled,
  openDisableDialog,
}) => {
  
  const {t} = useTranslation('translation', {keyPrefix: 'layout'});
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});
  
  const dispatch = useDispatch();
  const companyId = useAppSelector(currentCompanyIdSelector);
  const companyChangeLoading = useAppSelector(companyChangeLoadingSelector);

  const [isShowCompanyOpenConfirmDialog, setIsShowCompanyOpenConfirmDialog] = useState(false);

  const closeShowCompanyOpenConfirmDialog = () => {
    setIsShowCompanyOpenConfirmDialog(false);
  }

  const openCompany = () => {
    if (companyId) {
      dispatch(enableCompany(companyId));
      setIsShowCompanyOpenConfirmDialog(false);
    }
  }
  
  const toggleHandler = useCallback(() => {
    if (!isCompanyDisabled) {
      openDisableDialog();
      return;
    }
    
    if (companyId) {
      setIsShowCompanyOpenConfirmDialog(true);
    }
  }, [companyId, openDisableDialog, isCompanyDisabled])
  
  return <>
    {companyChangeLoading
      ? <Loader className="ms-4"/>
      : <span className={classNames(
          styles["header-status"],
          "ms-0 ms-xl-4 align-self-center clickable",
          isCompanyDisabled ? styles["header-status--disabled"] : styles["header-status--enabled"],
        )} onClick={toggleHandler}>
          {isCompanyDisabled ? t('companyDisabled') : t('companyEnabled')}
        </span>}
    <Modal show={isShowCompanyOpenConfirmDialog} onHide={closeShowCompanyOpenConfirmDialog} centered>
      <Modal.Header closeButton>
        <Modal.Title>{commonT('isOpenCompany')}</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={closeShowCompanyOpenConfirmDialog}>
          {commonT('actionCancel')}
        </Button>
        <Button variant="outline-primary" onClick={openCompany}>
          {commonT('actionSubmit')}
        </Button>
      </Modal.Footer>
    </Modal>
  </>
}

export default CompanyStatusToggle;
