import * as types from './constants';
import {PartnerEmployeeResponse} from "./models";
import {RootState} from "../../app/store";
import {PartnerResponse} from "../profile/models";

type AuthState = {
  employee: PartnerEmployeeResponse | null;
  loading: boolean;
  error: string | null;
  isLoggedIn: boolean;
  currentCompanyId: number | null;
  companyChangeLoading: boolean;
  timestamp: number | null;
}

type LocalDisableCompanyParams = {
  companyId: number;
  dateTime: string | null;
}

export type AuthAction = {
  type: typeof types.LOGIN_REQUEST;
} | {
  type: typeof types.LOGIN_SUCCESS;
  payload: {
    employee: PartnerEmployeeResponse
    // timestamp: number
  };
} | {
  type: typeof types.LOGIN_FAILURE;
  payload: string;
} | {
  type: typeof types.LOGOUT;
} | {
  type: typeof types.GET_USER_REQUEST;
} | {
  type: typeof types.GET_USER_SUCCESS;
  payload: {
    employee: PartnerEmployeeResponse
    // timestamp: number
  };
} | {
  type: typeof types.GET_USER_FAILURE;
  payload: {
    error: string;
    logout: boolean;
  };
} | {
  type: typeof types.COMPANY_CHANGE_REQUEST;
} | {
  type: typeof types.COMPANY_CHANGE_FAILURE;
} | {
  type: typeof types.ENABLE_COMPANY;
  payload: number;
} | {
  type: typeof types.ENABLE_COMPANY_FAILURE;
} | {
  type: typeof types.DISABLE_COMPANY;
  payload: LocalDisableCompanyParams;
} | {
  type: typeof types.DISABLE_COMPANY_FAILURE;
} | {
  type: typeof types.UPDATE_COMPANY;
  payload: {
    isClosed: boolean;
    companyId: number
  }
} | {
  type: typeof types.CHANGE_CURRENT_COMPANY;
  payload: number;
} | {
  type: typeof types.CHANGE_TIMESTAMP;
  payload: number;
} | {
  type: typeof types.UPDATE_PARTNER;
  payload: PartnerResponse;
}

/**
 * Получение id компании по умолчанию.
 * Если нет сохраненного валидного id в localStorage, то id первой в списке компании.
 */
const getDefaultCompanyId = ({companies}: PartnerEmployeeResponse): number | null => {
  
  const localStorageDefaultId = localStorage.getItem('defaultCompanyId');
  
  if (localStorageDefaultId && companies.find(({id}) => id === Number(localStorageDefaultId))) {
    return Number(localStorageDefaultId);
  }
  
  const firstCompanyId = companies[0]?.id;
  
  if (firstCompanyId) {
    localStorage.setItem('defaultCompanyId', String(companies[0].id))
    return companies[0].id;
  }
  
  return null;
}

/**
 * Включить заведение companyId в объекте employee
 */
const enableCompanyInEmployeeResponse = (
  employee: AuthState["employee"],
  companyId: number,
): AuthState["employee"] => {
  if (!employee) {
    return employee;
  }

  const companyIndex = employee.companies.findIndex(({id}) => id === companyId);

  if (companyIndex === -1) {
    return employee;
  }

  const nextEmployee = {...employee};
  nextEmployee.companies[companyIndex].isClosed = false;
  nextEmployee.companies[companyIndex].closedUntil = null;

  return nextEmployee;
}

/**
 * Отключить заведение companyId в объекте employee
 */
const disableCompanyInEmployeeResponse = (
  employee: AuthState["employee"],
  {companyId, dateTime}: LocalDisableCompanyParams,
): AuthState["employee"] => {
  if (!employee) {
    return employee;
  }

  const companyIndex = employee.companies.findIndex(({id}) => id === companyId);

  if (companyIndex === -1) {
    return employee;
  }

  const nextEmployee = {...employee};
  nextEmployee.companies[companyIndex].isClosed = true;
  nextEmployee.companies[companyIndex].closedUntil = dateTime;

  return nextEmployee;
}

const updateCompany = (
  employee: AuthState["employee"],
  companyId: number,
  isClosed: boolean
): AuthState["employee"] => {
  if (!employee) {
    return employee;
  }

  const companyIndex = employee.companies.findIndex(({id}) => id === companyId);

  if (companyIndex === -1) {
    return employee;
  }

  const nextEmployee = {...employee};

  nextEmployee.companies[companyIndex].isClosed = isClosed;

  return nextEmployee;
}

const updatePartner = (
  employee: AuthState["employee"],
  partner: PartnerResponse,
): AuthState["employee"] => {
  if (!employee) {
    return employee;
  }
  
  const nextEmployee = {...employee};
  
  nextEmployee.partner = partner;
  
  return nextEmployee;
}

const authInfo = JSON.parse(localStorage.getItem('auth') ?? "null");

const initialState: AuthState = {
  employee: null,
  loading: false,
  error: null,
  isLoggedIn: !!(authInfo && authInfo.authKey) || false,
  currentCompanyId: null,
  companyChangeLoading: false,
  timestamp: null
}

export const employeeDataSelector = ({auth: {employee}}: RootState) => employee;
export const employeeLoadingSelector = ({auth: {loading}}: RootState) => loading;
export const isLoggedInSelector = ({auth: {isLoggedIn}}: RootState) => isLoggedIn;
export const currentCompanyIdSelector = ({auth: {currentCompanyId}}: RootState) => currentCompanyId;
export const authErrorSelector = ({auth: {error}}: RootState) => error;
export const authLoadingSelector = ({auth: {loading}}: RootState) => loading;
export const companyChangeLoadingSelector = ({auth: {companyChangeLoading}}: RootState) => companyChangeLoading;
export const timestampSelector = ({auth: {timestamp}}: RootState) => timestamp;

export default function auth(
  state = initialState,
  action: AuthAction,
): AuthState {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {
        employee: null,
        loading: true,
        error: null,
        isLoggedIn: false,
        currentCompanyId: null,
        companyChangeLoading: false,
        timestamp: null,
      }
    case types.LOGIN_SUCCESS:
      return {
        employee: action.payload.employee,
        isLoggedIn: true,
        loading: false,
        error: null,
        companyChangeLoading: false,
        currentCompanyId: getDefaultCompanyId(action.payload.employee),
        timestamp: null, // action.payload.timestamp
      }
    case types.LOGIN_FAILURE:
      return {
        error: action.payload,
        loading: false,
        employee: null,
        isLoggedIn: false,
        currentCompanyId: null,
        companyChangeLoading: false,
        timestamp: null,
      }
    case types.LOGOUT:
      return {
        employee: null,
        loading: false,
        error: null,
        isLoggedIn: false,
        currentCompanyId: null,
        companyChangeLoading: false,
        timestamp: null,
      }
    case types.GET_USER_REQUEST:
      return {
        ...state,
        employee: null,
        loading: true,
        error: null,
        currentCompanyId: null,
        timestamp: null,
      }
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        employee: action.payload.employee,
        error: null,
        currentCompanyId: getDefaultCompanyId(action.payload.employee),
        timestamp: null, // action.payload.timestamp
      }
    case types.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        employee: null,
        error: action.payload.error,
        isLoggedIn: action.payload.logout ? false : state.isLoggedIn,
        currentCompanyId: null,
        timestamp: null,
      }
    case types.COMPANY_CHANGE_REQUEST:
      return {
        ...state,
        companyChangeLoading: true,
      }
    case types.ENABLE_COMPANY:
      return {
        ...state,
        employee: enableCompanyInEmployeeResponse(state.employee, action.payload),
        companyChangeLoading: false,
      }
    case types.DISABLE_COMPANY:
      return {
        ...state,
        employee: disableCompanyInEmployeeResponse(state.employee, action.payload),
        companyChangeLoading: false,
      }
    case types.ENABLE_COMPANY_FAILURE:
    case types.DISABLE_COMPANY_FAILURE:
      return {
        ...state,
        companyChangeLoading: false,
      }
    case types.UPDATE_COMPANY:
      const {isClosed, companyId} = action.payload;
      return {
        ...state,
        employee: updateCompany(state.employee, companyId, isClosed),
        companyChangeLoading: false,
      }
    case types.CHANGE_CURRENT_COMPANY:
      localStorage.setItem('defaultCompanyId', String(action.payload))
      return {
        ...state,
        currentCompanyId: action.payload,
        companyChangeLoading: false,
      }
    case types.COMPANY_CHANGE_FAILURE:
      return {
        ...state,
        companyChangeLoading: false,
      }
    case types.CHANGE_TIMESTAMP:
      return {
        ...state,
        timestamp: action.payload
      }
    case types.UPDATE_PARTNER:
      return {
        ...state,
        employee: updatePartner(state.employee, action.payload),
      }
    default:
      return state;
  }
}
