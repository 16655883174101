export const GET_ARCHIVE_ORDERS_REQUEST = Symbol('archive/GET_ORDERS_REQUEST');

export const GET_ARCHIVE_ORDERS_SUCCESS = Symbol('archive/GET_ORDERS_SUCCESS');

export const GET_ARCHIVE_ORDERS_FAILURE = Symbol('archive/GET_ORDERS_FAILURE');

export const GET_ARCHIVE_SUMMARY_REQUEST = Symbol('archive/GET_SUMMARY_REQUEST');

export const GET_ARCHIVE_SUMMARY_SUCCESS = Symbol('archive/GET_SUMMARY_SUCCESS');

export const GET_ARCHIVE_SUMMARY_FAILURE = Symbol('archive/GET_SUMMARY_FAILURE');
