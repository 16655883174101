import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {OrderNotCallDialogProps} from "./types";
import {useTranslation} from "react-i18next";

/**
 * Диалог о просьбе не звонить клиенту
 * */
const OrderNotCallDialog: React.VFC<OrderNotCallDialogProps> = ({
    show,
    closeDialog
}) => {

    const {t} = useTranslation('translation', {keyPrefix: 'features.orders'});
    const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});

    return <Modal show={show} onHide={closeDialog} centered>
        <Modal.Header />
        <Modal.Body>
            <div className={'mt-4'}>
                {
                    t('notCallMessage').split("\n").map(function (item) {
                        return (
                            <p key={item}>
                                {item}
                            </p>
                        )
                    })
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="outline-primary"
                onClick={closeDialog}
            >
                {commonT('understood')}
            </Button>
        </Modal.Footer>
    </Modal>
};

export default OrderNotCallDialog;
