import {Action, applyMiddleware, compose, createStore} from "redux";
import reducer from "./rootReducer";
import thunk, {ThunkAction} from "redux-thunk";
import {TypedUseSelectorHook, useSelector} from "react-redux";

// у типа window нет поля __REDUX_DEVTOOLS_EXTENSION_COMPOSE__
// eslint-disable-next-line
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  // eslint-disable-next-line
  ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // для сериализации Symbol-типов действий
    serialize: true
  })
  : compose;

export const store = createStore(
  reducer,
  applyMiddleware(thunk)
  // composeEnhancers(applyMiddleware(thunk)) // для работы расширения redux для браузера, используется в dev-среде
);

/**
 * Тип thunk-действия
 * (возвращает void, использует RootState, доп. параметр unknown, redux-действия используют T)
 */
export type AppThunkAction<T extends Action> = ThunkAction<void, RootState, unknown, T>;

/**
 * Тип всего состояния
 */
export type RootState = ReturnType<typeof store.getState>;

/**
 * Типизированный хук селектора
 */
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
