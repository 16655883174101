export const GET_REVIEWS_REQUEST = Symbol('reviews/GET_REQUEST');

export const GET_REVIEWS_SUCCESS = Symbol('reviews/GET_SUCCESS');

export const GET_REVIEWS_FAILURE = Symbol('reviews/GET_FAILURE');

export const REPLACE_IN_REVIEWS = Symbol('reviews/REPLACE');

export const GET_REVIEWS_SUMMARY_REQUEST = Symbol('reviews/GET_SUMMARY_REQUEST');

export const GET_REVIEWS_SUMMARY_SUCCESS = Symbol('reviews/GET_SUMMARY_SUCCESS');

export const GET_REVIEWS_SUMMARY_FAILURE = Symbol('reviews/GET_SUMMARY_FAILURE');

export const SET_UNANSWERED_REVIEWS_COUNT = Symbol('reviews/UPDATE_UNANSWERED_COUNT');

export const ADD_TO_UNANSWERED_REVIEWS_COUNT = Symbol('reviews/ADD_TO_UNANSWERED_COUNT');
