import React from 'react';
import classNames from 'classnames';
import styles from "./Radio.module.scss";

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  label?: React.ReactNode;
  className?: string;
}

const Radio: React.VFC<RadioProps> = ({
  id,
  label,
  className,
  ...props
}) => {
  return <div className={classNames(
    styles["wrapper"],
    className,
  )}>
    <div className={classNames(
      styles["control"],
    )}>
      <input type="radio" id={id} {...props} />
      <label htmlFor={id}>{label}</label>
    </div>
  </div>;
};

export default Radio;
