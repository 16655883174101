import {OrderDeliveryResponse} from "../../features/orders/models";
import {AxiosError} from "axios";

/**
 * Найти объект в списке items по его сегменту spread и вставить сегмент spread в найденный объект.
 * В spread должно быть поле id.
 * @returns новый массив (исходный, если не нашел id или некорректные типы аргументов)
 */
export function spreadInData<T extends {id?: number} = Record<string, unknown>>(
  items: T[],
  spread: Partial<T>,
) {
  /**
   * Ранний выход, если некорректные типы аргументов
   */
  if (!Array.isArray(items) || spread.constructor !== Object) {
    return items;
  }
  
  const index = spread.id
    ? items.findIndex(({id}) => id === spread.id)
    : -1;
  
  return index === -1
    ? items
    : [
      ...items.slice(0, index),
      {
        ...items[index],
        ...spread,
      },
      ...items.slice(index + 1)
    ];
}

/**
 * Замена объекта в массиве по id
 * @returns новый массив (исходный, если не нашел id или некорректные типы аргументов)
 */
export function replaceInData<T extends {id?: number} = Record<string, unknown>>(
  items: T[],
  item: T,
): T[] {
  /**
   * Ранний выход, если некорректные типы аргументов
   */
  if (!Array.isArray(items) || item.constructor !== Object) {
    return items;
  }
  
  const index = item.id
    ? items.findIndex(({id}) => id === item.id)
    : -1;
  
  return index === -1
    ? items
    : [
      ...items.slice(0, index),
      item,
      ...items.slice(index + 1)
    ];
}

/**
 * Удаление объекта из массива по id
 * @returns новый массив (исходный, если не нашел id)
 * @param items - все записи
 * @param itemId - id удаляемой записи
 * @param pathToId - путь к id объекта
 */
export const removeFromData:
  <DataObject extends {id?: number} & Record<string, unknown> = Record<string, unknown>>(
    items: DataObject[], itemId: number, pathToId?: ((item: DataObject) => number)
  ) => DataObject[] =
  (items, itemId, pathToId) => {
    /**
     * Ранний выход, если некорректные типы аргументов
     */
    if (!Array.isArray(items)) {
      return items;
    }
    /**
     * Нахождение индекса элемента
     */
    const index = pathToId
      ? items.findIndex((arrayItem) => pathToId(arrayItem) === itemId)
      : items[0]?.id
        ? items.findIndex(({id}) => id === itemId)
        : -1;
    
    return index === -1
      ? items
      : [
        ...items.slice(0, index),
        ...items.slice(index + 1)
      ];
  }
  
/**
 * Получение сообщения ошибки из объекта AxiosError
 */
export const getAxiosErrorMessage = (
    {response, request}: AxiosError<{message?: string}>,
    fallbackErrorMessage = "Ошибка",
): string => {
    if (response) {
        return response.data?.message || fallbackErrorMessage;
    }

    if (request) {
        return "Ошибка соединения с сервером";
    }

    return fallbackErrorMessage;
}

/**
 * Отформатировать номер телефона
 * (аналогично регулярному выражению "#^(\d)(\d{3})(\d{3})(\d{4})$#" -> "+$1 ($2) $3-$4")
 */
export const formatPhoneNumber = (input: string) =>
  `+${input[0]} (${input.substring(1, 4)}) ${input.substring(4, 7)}-${input.substring(7, 11)}`;

/**
 * Собрать строку адреса
 */
export const buildAddressString = (
  {
    street,
    house,
    flat,
    entrance,
    floor,
    speakerphone,
    deliveryZone
  }: OrderDeliveryResponse,
): string => {
  
  let result = ``;
  if (deliveryZone){
    result += `${deliveryZone.name}, ${street}, ${house}`;
  }
  else {
    result += `${street}, ${house}`;
  }

  if (flat) {
    result += `, кв. ${flat}`;
  }
  
  if (entrance) {
    result += `, под. ${entrance}`;
  }
  
  if (floor) {
    result += `, эт. ${floor}`;
  }
  
  if (speakerphone) {
    result += `, домофон ${speakerphone}`;
  }
  
  return result;
}
