import React from "react";
import {NavTabProps} from "./types";
import {Link} from "react-router-dom";
import classNames from "classnames";
import styles from "./Layout.module.scss";
import Icon from "../Icon";

/**
 * Вкладка навигации
 */
const LayoutNavTab: React.VFC<NavTabProps> = ({
  label,
  icon,
  link,
  active = false,
  hasIndicator = false,
  useHTMLLinkElement = false,
}) => {
  
  const className = classNames(
    styles["header-navigation-tab"],
    active && styles["header-navigation-tab--active"],
  )
  
  const content = <>
    <Icon icon={icon}/>
    {hasIndicator && <div className={styles['header-navigation-tab-indicator']}/>}
    {label}
  </>
  
  if (useHTMLLinkElement) {
    return <a
      href={link}
      className={className}
      target={'_blank'}
      rel={'noopener noreferrer'}>
      {content}
    </a>
  }
  
  return <Link
    to={link}
    className={className}>
    {content}
  </Link>;
}

export default LayoutNavTab;
