import React from 'react';
import styles from "./LayoutDebtWarning.module.scss";
import {LayoutDebtWarningProps} from "./types";
import iconCalendarImportant from "../../../../assets/images/icon_calendar_important.svg";
import iconCalendar10 from "../../../../assets/images/icon_calendar_10.svg";
import {inflectedMonthNames} from "../../../utils/dateTimeHelpers";
import Button from "react-bootstrap/Button";
import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import classNames from "classnames";
import FormatPrice from "../../FormatPrice";

/**
 * Предупреждение о долге на всю ширину экрана, зафиксировано при скролле
 */
const LayoutDebtAlert: React.VFC<LayoutDebtWarningProps> = ({
  debt,
  isClosedDueToDebt,
  payUrl,
}) => {
  
  const {t: commonT} = useTranslation('translation', {keyPrefix: 'common'});
  
  if (debt <= 0) {
    return null;
  }
  
  /**
   * getMonth возвращает номер месяца, начиная с 0 (0 - январь), поэтому +1.
   */
  const payDeadlineMonth = (new Date()).getMonth() + 1;
  const payDeadlineYear = (new Date()).getFullYear();
  
  const content = <Container className="d-flex align-items-center justify-content-between justify-content-md-start p-0">
    <img
      className="d-none d-md-block me-2"
      src={isClosedDueToDebt ? iconCalendarImportant : iconCalendar10}
      alt=""
      width={24}
      height={24}
    />
  
    <div className="d-flex flex-column flex-md-row align-items-md-center">
      <div>
        <span>{commonT('debt')}</span>
        <span className="d-inline d-md-none ms-2"><FormatPrice value={debt}/> ₸</span>
        <span className={classNames("d-none d-md-inline ms-2 me-3_5", styles['warning-emphasis'])}><FormatPrice value={debt}/> ₸</span>
      </div>
      <div className={styles['warning-note']}>
        {isClosedDueToDebt
          ? commonT('closedDueToDebt')
          : commonT('payUntil', {datetime: `10 ${inflectedMonthNames[payDeadlineMonth]} ${payDeadlineYear}`})}
      </div>
    </div>
  
    {payUrl && <Button
        variant={"light"}
        href={payUrl}
        target={"_blank"}
        rel={"noopener"}>
      {commonT('actionPayWithKaspi')}
    </Button>}
  </Container>
  
  return <>
    <div className={classNames(
      styles['warning'],
      isClosedDueToDebt && styles['warning--muted'],
    )}>
      {content}
    </div>
    <div className={classNames(
      styles['warning'],
      styles['warning--offset'],
    )}>
      {content}
    </div>
  </>;
};

export default LayoutDebtAlert;
