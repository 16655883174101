import React, {useEffect} from 'react';
import {Redirect, Route, Router, Switch} from 'react-router-dom';
import {customHistory} from "../common/utils/history";
import LoginForm from "../features/auth/components/LoginForm";
import Layout from "../common/components/Layout";
import PrivateRoute from "../common/components/PrivateRoute";
import ActiveOrdersOutput from "../features/orders/components/OrdersList";
import CatalogOutput from "../features/catalog/components/CatalogOutput";
import {useAppSelector} from "./store";
import {useDispatch} from "react-redux";
import {getUser} from "../features/auth/actions";
import {currentCompanyIdSelector, isLoggedInSelector, timestampSelector} from "../features/auth/reducer";
import OrderDetailPage from "../features/orders/components/OrderDetail/OrderDetailPage";
import ArchiveOrdersOutput from "../features/archive/components/ArchiveOrdersOutput";
import ProfileView from "../features/profile/components/ProfileView";
import {getState, getInitiallyRequiredStateFields} from "../features/state/actions";
import Toasts from "../common/components/Toasts";
import {useNotifications} from "../common/utils/useNotifications";
import {orderIdsThatNeedAttentionSelector} from "../features/orders/reducer";
import ReviewsOutput from "../features/reviews/components/ReviewsOutput";
import {reviewsUnansweredCountSelector} from "../features/reviews/reducer";

const App: React.VFC = () => {
  
  const dispatch = useDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const companyId = useAppSelector(currentCompanyIdSelector);
  
  const orderIdsThatNeedAttention = useAppSelector(orderIdsThatNeedAttentionSelector);
  const timestamp = useAppSelector(timestampSelector);
  const unansweredReviewsCount = useAppSelector(reviewsUnansweredCountSelector);
  
  const {playNotification, stopNotification} = useNotifications();

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getUser())
    }
  }, [dispatch, isLoggedIn]);

  /**
   * Запрос стейта
   * (setInterval вместо setTimeout, чтобы при ошибке запроса запросы не прекращались посылаться)
   */
  useEffect(() => {
      let timer = setInterval(() => {
        if (isLoggedIn && companyId) {
          dispatch(getState(companyId, timestamp || undefined))
        }
      }, 12000);

      return () => {
        clearInterval(timer);
      };
  }, [companyId, dispatch, timestamp, isLoggedIn]);
  
  /**
   * В самом начале получить необходимые показатели,
   * такие как кол-во неотвеченных отзывов и наличие глобальных предупреждений
   * (unansweredReviewsCount равно null только в самом начале, потом всегда number,
   *  и условие выполняться не должно)
   */
  useEffect(() => {
    if (isLoggedIn && companyId && unansweredReviewsCount === null) {
      dispatch(getInitiallyRequiredStateFields(companyId))
    }
  }, [companyId, dispatch, isLoggedIn, unansweredReviewsCount]);
  
  /**
   * Проигрывание звукового уведомления при наличии обновленных заказов
   */
  useEffect(() => {
    if (orderIdsThatNeedAttention.length > 0) {
      playNotification();
    } else {
      stopNotification();
    }
  }, [orderIdsThatNeedAttention, playNotification, stopNotification])

  return <>
    <Router history={customHistory}>
      <Switch>
        <Route path="/login" component={LoginForm}/>
        <Layout>
          <Switch>
            <PrivateRoute path="/orders/:id" component={OrderDetailPage}/>
            <PrivateRoute path="/orders" component={ActiveOrdersOutput}/>
            <PrivateRoute path="/archive" component={ArchiveOrdersOutput}/>
            <PrivateRoute path="/catalog" component={CatalogOutput}/>
            <PrivateRoute path="/reviews" component={ReviewsOutput}/>
            <PrivateRoute path="/profile" component={ProfileView}/>

            <Redirect to="/orders"/>
          </Switch>
        </Layout>
      </Switch>
    </Router>
    <Toasts/>
    </>
}

export default App;
