import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ru from '../assets/locales/ru/translation.json';

i18n
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false, // не обязательно, react и так эскейпит значения
    },
    resources: {
      ru
    }
  })

export default i18n;
