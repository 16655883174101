import React from 'react';
import {components, GroupBase, NoticeProps} from "react-select";
import {useTranslation} from "react-i18next";

/**
 * Компонент, показываемый вместо опций, если опций нет
 */
function NoOptionsMessage<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: NoticeProps<Option, IsMulti, Group>) {
  
  const {t} = useTranslation('translation', {keyPrefix: 'common'});
  
  return <components.NoOptionsMessage {...props}>
    {t('itemsListEmpty')}
  </components.NoOptionsMessage>;
}

export default NoOptionsMessage;
