import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch} from "react-redux";
import {useAppSelector} from "../../../../app/store";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {getArchiveOrders, getArchiveSummary} from "../../actions";
import {OrderFilterForm} from "../../../orders/models";
import {
  archiveOrdersErrorSelector,
  archiveOrdersListSelector,
  archiveOrdersLoadingSelector, archiveOrdersPageCountSelector,
  archiveSummaryErrorSelector, archiveSummaryLoadingSelector, archiveSummarySelector
} from "../../reducer";
import TextInput from "../../../../common/components/TextInput";
import Icon from "../../../../common/components/Icon";
import {useTranslation} from "react-i18next";
import styles from "./ArchiveOrdersOutput.module.scss";
import ErrorPlug from "../../../../common/components/ErrorPlug";
import Box from "../../../../common/components/Box";
import {
  ArchiveFilterDateTimeValues,
  getFormattedDateSometimeAgo,
  orderDeliveryTypeNameKeys,
  orderPaymentTypeNameKeys,
} from "../../../orders/helpers";
import Accordion from "react-bootstrap/Accordion";
import FormatPrice from "../../../../common/components/FormatPrice";
import Loader from "../../../../common/components/Loader";
import TextButton from "../../../../common/components/TextButton";
import classNames from "classnames";
import OrdersFilterDialog from "./OrdersFilterDialog";
import Pagination from "../../../../common/components/Pagination";
import {OrdersFilterDialogFilter} from "./OrdersFilterDialog/types";
import {format} from "date-fns";
import {ru} from "date-fns/locale";
import NumberFormat from "react-number-format";
import {requestArchiveExport} from "../../../orders/api";
import {addNotify} from "../../../../common/actions/notify";
import {getAxiosErrorMessage} from "../../../../common/utils/functions";
import OrderDetailModal from "../../../orders/components/OrderDetail/OrderDetailModal";
import {getDateTimeTuple, parseServerDate} from "../../../../common/utils/dateTimeHelpers";

/**
 * Вывод архивных заказов
 */
const ArchiveOrdersOutput: React.VFC = () => {

  const dispatch = useDispatch();
  const {t: archiveT} = useTranslation('translation', {keyPrefix: 'features.archive'});
  const {t} = useTranslation();
  
  const isMounted = useRef(false);
  
  useEffect(() => {
    isMounted.current = true;
    
    return () => {
      isMounted.current = false;
    }
  }, []);
  
  /** id заказа, показываемого в модальном окне */
  const [orderIdInModal, setOrderIdInModal] = useState<number | null>(null);
  
  const openOrderInModal = useCallback((id: number) => setOrderIdInModal(id), []);
  const closeOrderInModal = useCallback(() => setOrderIdInModal(null), []);

  /** отображаемый текст поисковой строки */
  const [displayedSearchText, setDisplayedSearchText] = useState('');
  /** обрабатываемая в форме запроса строка */
  const [searchText, setSearchText] = useState('');

  /** текущая страница */
  const [currentPage, setCurrentPage] = useState(1);

  const pageCount = useAppSelector(archiveOrdersPageCountSelector);

  /** фильтр (начальный фильтр - все заказы за месяц) */
  const [filter, setFilter] = useState<OrdersFilterDialogFilter | null>({
    _dateTime: ArchiveFilterDateTimeValues.LAST_30_DAYS,
    dateTimeStart: getFormattedDateSometimeAgo({daysAgo: 30}),
  });

  /**
   * Диапазон создания заказов считается одним ключом.
   * При наличии дат в фильтре также присутствует _dateTime, который и будет посчитан
   */
  const filterKeyCount = filter
    ? Object.keys(filter).filter(key => key !== 'dateTimeStart' && key !== 'dateTimeEnd').length
    : 0;

  const [isFilterDialog, setIsFilterDialog] = useState(false);

  const openFilterDialog = useCallback(() => setIsFilterDialog(true), []);
  const closeFilterDialog = useCallback(() => setIsFilterDialog(false), []);

  const changeFilter = useCallback((newFilter: OrdersFilterDialogFilter | null) => {
    setFilter(newFilter)
    setCurrentPage(1);
  }, [])
  
  /**
   * Собрать форму фильтра в формате бека
   */
  const buildFilterForm = useCallback((filter: OrdersFilterDialogFilter | null, searchText: string) => {
    const form: OrderFilterForm & {_dateTime?: string;} = {...filter};
    /** _dateTime нужен в стейте filter, чтобы сохранять выбранный период при повторном открытии фильтра */
    delete form._dateTime;
  
    if (searchText) {
      form.ids = [Number(searchText)];
    }
    
    return form;
  }, [])

  useEffect(() => {
    const form = buildFilterForm(filter, searchText);

    dispatch(getArchiveOrders(form, {expand: 'company,city', page: currentPage}));
  }, [buildFilterForm, currentPage, dispatch, filter, searchText]);
  
  /**
   * Отдельно, чтобы изменение страницы не перезапрашивало summary
   */
  useEffect(() => {
    const form = buildFilterForm(filter, searchText);
    
    dispatch(getArchiveSummary(form))
  }, [buildFilterForm, dispatch, filter, searchText]);
  
  /** экспортируемые данные */
  const [exportData, setExportData] = useState<Blob>();
  /** статус загрузки экспорта */
  const [exportLoading, setExportLoading] = useState(false);
  
  /**
   * Загрузка файла после завершения экспорта
   */
  useEffect(() => {
    if (exportData) {
      const fileLink = document.createElement('a');
      fileLink.href = URL.createObjectURL(exportData);
      fileLink.download = `export_${Date.now()}.pdf`;
      fileLink.click();
      fileLink.remove();
      setExportData(undefined)
    }
  }, [exportData]);
  
  const exportArchive = useCallback((filter: OrdersFilterDialogFilter | null, searchText: string) => {
    setExportLoading(true);
    const form = buildFilterForm(filter, searchText);
    
    requestArchiveExport(form)
      .then(({data}) => {
        if (isMounted.current) {
          setExportData(data);
        }
      })
      .catch((err) => {
        dispatch(addNotify(getAxiosErrorMessage(err)));
      })
      .finally(() => {
        if (isMounted.current) {
          setExportLoading(false);
        }
      })
  }, [buildFilterForm, dispatch])
  
  const orders = useAppSelector(archiveOrdersListSelector);
  const loading = useAppSelector(archiveOrdersLoadingSelector);
  const error = useAppSelector(archiveOrdersErrorSelector);
  const summary = useAppSelector(archiveSummarySelector);
  const summaryLoading = useAppSelector(archiveSummaryLoadingSelector);
  const summaryError = useAppSelector(archiveSummaryErrorSelector);

  /**
   * Обработчик поиска
   */
  const searchHandler = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if( e.key === 'Enter' ) {
      const value = e.currentTarget.value.replace(/ /g, '')

      setDisplayedSearchText(value);

      setSearchText(value);
    }
  };

  let content = <Loader/>

  if (!loading) {
    if (error) {
      content = <ErrorPlug className={'mb-0'}>{error}</ErrorPlug>
    } else {
      content = <Box>
        {!!orders.length
          ? <>
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h3>{archiveT('history')}</h3>
                {exportLoading
                  ? <Loader/>
                  : <TextButton
                      onClick={() => exportArchive(filter, searchText)}
                      label={t('common.export')}
                      state={['text-button--larger', 'text-button--bold']}
                    />}
              </div>
              <Table className="text-center fw-600" responsive>
                <thead>
                  <tr>
                    <th>{archiveT('creationDateTime')}</th>
                    {/*<th>{archiveT('deliveryDateTime')}</th>*/}
                    <th>{archiveT('orderId')}</th>
                    <th>{archiveT('totalAmount')}</th>
                    <th>{archiveT('company')}</th>
                    <th>{archiveT('deliveryType')}</th>
                    <th>{archiveT('paymentType')}</th>
                    <th>{archiveT('statusId')}</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map(order => {

                    const displayedCreationTime = getDateTimeTuple(order.created) ?? ['', ''];
                    /*const displayedDeliveryDateTime = getFormattedDateTimeTuple(order.deliveryDateTime) ?? ['', ''];*/

                    return <tr
                      className="clickable"
                      key={order.id}
                      onClick={() => openOrderInModal(order.id)}>
                      <td>
                        <div>{displayedCreationTime[0]}</div>
                        <div className="fw-normal">{displayedCreationTime[1]}</div>
                      </td>
                      {/*<td>
                        <div>{displayedDeliveryDateTime[0]}</div>
                        <div className="fw-normal">{displayedDeliveryDateTime[1]}</div>
                      </td>*/}
                      <td>{order.id}</td>
                      <td><FormatPrice value={order.totalAmount}/> ₸</td>
                      <td>
                        <div>{order.company?.name}</div>
                        <div className="fw-normal">{order.city?.name}</div>
                      </td>
                      <td>
                        {t(`features.orders.${orderDeliveryTypeNameKeys[order.deliveryTypeId]}`)}
                      </td>
                      <td>
                        {t(`features.orders.${orderPaymentTypeNameKeys[order.payTypeId]}`)}
                      </td>
                      <td>{order.status.name}</td>
                    </tr>;
                  })}
                </tbody>
              </Table>
            
              <Pagination
                currentPage={currentPage}
                pageCount={pageCount}
                setCurrentPage={setCurrentPage}
              />
            </>
          : <div className="d-flex justify-content-center">
              <h2 className="mb-0">{t('common.itemsListEmpty')}</h2>
            </div>}
      </Box>
    }
  }

  /**
   * Сводная информация об архивных заказах
   */
  let summaryContent: JSX.Element | null = <Loader/>

  if (!summaryLoading) {
    if (summaryError) {
      summaryContent = <ErrorPlug className={'mb-0'}>{summaryError}</ErrorPlug>
    } else {
      let totalAmount = 0;
      let totalCount = 0;
      let averageAmount = 0;

      if (summary) {
        totalAmount = summary.amountCash + summary.amountPOS + summary.amountOnline;
        totalCount = summary.countCash + summary.countPOS + summary.countOnline;
        /** защита от деления на 0 */
        averageAmount = totalCount !== 0
          ? Math.floor(totalAmount / totalCount)
          : 0;
      }

      summaryContent = summary
        ? <Row>
            <Col md={6} className="pe-2_5 pe-md-4 mb-3 mb-md-4">
              <h6 className="mb-2_5">{archiveT('summaryOrderAmount')}:</h6>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {t('features.orders.paymentCash')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.amountCash}/> ₸</div>
              </div>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {t('features.orders.paymentCouriersCard')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.amountPOS}/> ₸</div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  {t('features.orders.paymentOnline')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.amountOnline}/> ₸</div>
              </div>
            </Col>

            <Col md={6} className="pe-2_5 ps-md-4 mb-3 mb-md-4">
              <h6 className="mb-2_5">{archiveT('summaryOrderCount')}:</h6>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {t('features.orders.paymentCash')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.countCash}/></div>
              </div>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {t('features.orders.paymentCouriersCard')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.countPOS}/></div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  {t('features.orders.paymentOnline')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.countOnline}/></div>
              </div>
            </Col>

            <Col md={6} className="pe-2_5 pe-md-4 mb-3 mb-md-0">
              <h6 className="mb-2_5">{archiveT('summaryTotal')}:</h6>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {archiveT('summaryTotalAmount')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={totalAmount}/> ₸</div>
              </div>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {archiveT('summaryAverageAmount')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={averageAmount}/> ₸</div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  {archiveT('summaryTotalCount')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={totalCount}/></div>
              </div>
            </Col>

            <Col md={6} className="pe-2_5 ps-md-4">
              <h6 className="mb-2_5">{archiveT('summaryPartRefund')}:</h6>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {archiveT('summaryOrderCount')}
                </div>
                <div className="fw-600 text-nowrap">{summary.partRefund}</div>
              </div>
              <div className="d-flex mb-2_5">
                <div className="flex-grow-1">
                  {archiveT('summaryPartRefundAmount')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.partRefundAmount}/> ₸</div>
              </div>
              <div className="d-flex">
                <div className="flex-grow-1">
                  {archiveT('summaryPartRefundSuccessAmount')}
                </div>
                <div className="fw-600 text-nowrap"><FormatPrice value={summary.partRefundSuccessAmount}/> ₸</div>
              </div>
            </Col>
          </Row>
        : null;
    }
  }
  
  let displayedFilterDateTimeStart = '';
  let displayedFilterDateTimeEnd = '';
  
  if (filter && !!filter.dateTimeStart) {
    const startDate = parseServerDate(filter.dateTimeStart);
    const endDate = !!filter.dateTimeEnd
      ? parseServerDate(filter.dateTimeEnd)
      : new Date();
    
    /** если года одинаковые, год первой даты можно не выводить */
    const startDateDisplayFormat = startDate.getFullYear() === endDate.getFullYear()
      ? 'dd MMMM'
      : 'dd MMMM yyyy';
    
    displayedFilterDateTimeStart = format(startDate, startDateDisplayFormat, {locale: ru});
    displayedFilterDateTimeEnd = format(endDate, 'dd MMMM yyyy', {locale: ru});
  }

  return <>
    <div className="mx-3 mx-md-0 mt-4 mt-md-0">
      <Row>
        <Col xs={7} sm={"auto"} md={4} className={classNames("pe-2", styles["search-field"])}>
          <NumberFormat
              aria-label="search"
              placeholder={archiveT(`orderId`)}
              value={displayedSearchText}
              onKeyPress={searchHandler}
              customInput={TextInput}
          />
          <Icon icon="search" className={styles["search-field-icon"]}/>
        </Col>
        <Col xs={"auto"} className="d-flex align-items-center ps-2">
          <TextButton
            onClick={openFilterDialog}
            icon={"filter_alt"}
            label={<div className="d-flex align-items-center justify-content-center">
              {archiveT('filter')}
              {!!filterKeyCount && <div className={classNames("ms-2 rounded-pill", styles["count-badge"])}>{filterKeyCount}</div>}
            </div>}
          />
        </Col>
      </Row>
    </div>

    {displayedFilterDateTimeStart &&
      <div className="mx-3 mx-md-0">
          <Row>
            <Col xs={"12"}>
                <div className={classNames(styles['calendar'], 'mt-2')}>
                  <div className={styles['calendar__icon']}>
                    <Icon icon={'calendar_month'}/>
                  </div>
                  {displayedFilterDateTimeStart}
                  &nbsp;-&nbsp;
                  {displayedFilterDateTimeEnd}
                </div>
            </Col>
          </Row>
      </div>}

    <Col lg={8}>
      <Accordion className="mt-2 mb-4" flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header><h3 className="m-0">{archiveT('summary')}</h3></Accordion.Header>
          <Accordion.Body className="bg-white">
            {summaryContent}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>

    {content}

    <OrdersFilterDialog
      show={isFilterDialog}
      closeHandler={closeFilterDialog}
      currentFilter={filter}
      submitHandler={changeFilter}
    />
  
    <OrderDetailModal
      id={orderIdInModal}
      onHide={closeOrderInModal}
    />
  </>;
};

export default ArchiveOrdersOutput;
