import {
  CourierComplainForm,
  OrderFilterForm,
  OrderItemEditForm,
  OrderProductItemForm,
  OrderResponse,
  TimeReadyForm
} from "./models";
import {AxiosPromise} from "axios";
import {addParametersToEndpoint, APIRequestParameters, request} from "../../common/utils/apiHelpers";

/**
 * API методы заказов (Orders)
 */
/**
 * Запросить список активных заказов
 */
export const requestOrders = (
  params: APIRequestParameters = {},
): AxiosPromise<OrderResponse[]> =>
  request(
    addParametersToEndpoint(`/order`, params),
  )

/**
 * Запросить список архивных заказов
 */
export const requestArchiveOrders = (
  form: OrderFilterForm | null = null,
  params: APIRequestParameters = {page: 1},
): AxiosPromise<OrderResponse[]> =>
  request(
    addParametersToEndpoint(`/order/archive`, params),
    'POST',
    form,
  )

/**
 * Запросить статистику архивных заказов
 */
export const requestArchiveSummary = (
  form: OrderFilterForm | null = null,
  params: APIRequestParameters = {},
): AxiosPromise =>
  request(
    addParametersToEndpoint(`/order/archive-summary`, params),
    'POST',
    form,
  )

/**
 * Экспорт архивных заказов
 */
export const requestArchiveExport = (
  form: OrderFilterForm | null = null,
): AxiosPromise<Blob> =>
  request(
    addParametersToEndpoint(`/order/export`),
    'POST',
    form,
    {
      options: {
        responseType: 'blob',
      }
    }
  )

export const requestOrdersSearch = (
  form: OrderFilterForm | null = null,
  params: APIRequestParameters = {},
): AxiosPromise<OrderResponse[]> =>
  request(
    addParametersToEndpoint(`/order/search`, params),
    'POST',
    form,
  )

/**
 * Запросить заказ по id
 */
export const requestOrder = (
  id: number,
  params: APIRequestParameters = {},
): AxiosPromise<OrderResponse> =>
  request(
    addParametersToEndpoint(`/order/${id}`, params),
  )

export const requestOrderItemEdit = (
  id: number,
  form: OrderItemEditForm,
): AxiosPromise<OrderResponse> =>
  request(
    addParametersToEndpoint(`/order/order-item/${id}`),
    'POST',
    form,
  )

/**
 * Запросить добавление позиции form к заказу orderId
 */
export const requestOrderItemAdd = (
  orderId: number,
  form: OrderProductItemForm,
): AxiosPromise<OrderResponse> =>
  request(
    addParametersToEndpoint(`/order/${orderId}/order-item-add`),
    'POST',
    form,
  )

/**
 * Запросить переход заказа orderId по статусу statusId
 */
export const requestOrderStatusChange = (
  orderId: number,
  statusId: number,
  form: TimeReadyForm | null = null,
): AxiosPromise<OrderResponse> =>
  request(
    addParametersToEndpoint(`/order/${orderId}/status/${statusId}`),
    'POST',
    form,
  )

/**
 * Пожаловаться на курьера
 * */
export const requestComplainCourier = (
    {orderId, message}: CourierComplainForm
): AxiosPromise =>
    request(
        addParametersToEndpoint(`/order/${orderId}/complain`),
        'POST',
        {message}
    )
