import * as types from '../constants/appStatus';
import {NotificationResponse, StateResponse} from "../../features/state/models";
import {RootState} from "../../app/store";

type OrderCountFields = 'orderTodayAcceptedCount'
  | 'orderTodayNotAcceptedCount'
  | 'orderYesterdayAcceptedCount'
  | 'orderYesterdayNotAcceptedCount'

/**
 * Показатели статуса приложения, не привязанные к конкретному разделу
 */
type AppStatusState = {
  needToUpdate: boolean;
  notificationMessage: NotificationResponse | null;
  marketingLink: string | null;
  ordersAcceptanceInfo: Record<OrderCountFields, number | null>;
};

export type AppStatusAction = {
  type: typeof types.GET_APP_STATUS_FROM_STATE;
  payload: Pick<StateResponse,
    'needToUpdate'
    | 'notification'
    | 'notificationMessage'
    | 'marketingLink'
    | OrderCountFields>;
}

const initialState: AppStatusState = {
  needToUpdate: false,
  notificationMessage: null,
  marketingLink: null,
  ordersAcceptanceInfo: {
    orderTodayAcceptedCount: null,
    orderTodayNotAcceptedCount: null,
    orderYesterdayAcceptedCount: null,
    orderYesterdayNotAcceptedCount: null,
  },
}

export const appStatusNeedToUpdateSelector = ({appStatus: {needToUpdate}}: RootState) => needToUpdate;
export const appStatusNotificationMessageSelector = ({appStatus: {notificationMessage}}: RootState) => notificationMessage;
export const appStatusMarketingLinkSelector = ({appStatus: {marketingLink}}: RootState) => marketingLink;
export const appStatusAcceptanceInfoSelector = ({appStatus: {ordersAcceptanceInfo}}: RootState) => ordersAcceptanceInfo;

/**
 * reducer статуса приложения
 */
export default function appStatus(
  state = initialState,
  action: AppStatusAction,
): AppStatusState {
  switch (action.type) {
    case types.GET_APP_STATUS_FROM_STATE:
      return {
        needToUpdate: action.payload.needToUpdate,
        notificationMessage: action.payload.notificationMessage ?? null,
        marketingLink: action.payload.marketingLink ?? null,
        ordersAcceptanceInfo: {
          orderTodayAcceptedCount: action.payload.orderTodayAcceptedCount ?? null,
          orderTodayNotAcceptedCount: action.payload.orderTodayNotAcceptedCount ?? null,
          orderYesterdayAcceptedCount: action.payload.orderYesterdayAcceptedCount ?? null,
          orderYesterdayNotAcceptedCount: action.payload.orderYesterdayNotAcceptedCount ?? null,
        },
      }
    default:
      return state;
  }
}
