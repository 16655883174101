import * as types from "../constants/toasts";

interface ToastsState {
  message: string | null,
  id: number
}

export type ToastsAction = {
  type: typeof types.ADD_TOAST;
  payload: {
    message: string;
    id: number
  };
} | {
  type: typeof types.REMOVE_TOAST;
  payload: number;
}

export default function toasts(
  state: ToastsState[] = [],
  action: ToastsAction
) {
  switch (action.type) {
    case types.ADD_TOAST:
      const {message, id} = action.payload;
      return [
        {
          message,
          id/*: id + new Date().getTime()*/
        }, ...state]
    case types.REMOVE_TOAST:
      return state.filter((toast: any) => toast.id !== action.payload)
    default: return state
  }
}
