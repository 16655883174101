import * as types from "./constants";
import {RootState} from "../../app/store";
import {ReviewResponse, ReviewSummaryResponse} from "./models";
import {replaceInData} from "../../common/utils/functions";

type ReviewsState = {
  data: ReviewResponse[];
  loading: boolean;
  error: string | null;
  pageCount: number;
  /** Кол-во неотвеченных отзывов, получается из StateResponse */
  countNotAnsweredReview: number | null;
  summary: ReviewSummaryResponse | null;
  summaryLoading: boolean;
  summaryError: string | null;
}

export type ReviewsAction = {
  type: typeof types.GET_REVIEWS_REQUEST;
} | {
  type: typeof types.GET_REVIEWS_SUCCESS;
  payload: {
    data: ReviewResponse[];
    pageCount: number;
  }
} | {
  type: typeof types.GET_REVIEWS_FAILURE;
  payload: string;
} | {
  type: typeof types.REPLACE_IN_REVIEWS;
  payload: ReviewResponse;
} | {
  type: typeof types.GET_REVIEWS_SUMMARY_REQUEST;
} | {
  type: typeof types.GET_REVIEWS_SUMMARY_SUCCESS;
  payload: ReviewSummaryResponse;
} | {
  type: typeof types.GET_REVIEWS_SUMMARY_FAILURE;
  payload: string;
} | {
  type: typeof types.SET_UNANSWERED_REVIEWS_COUNT;
  payload: number;
} | {
  type: typeof types.ADD_TO_UNANSWERED_REVIEWS_COUNT;
  payload: number;
}

const initialState: ReviewsState = {
  data: [],
  loading: false,
  error: null,
  pageCount: 1,
  countNotAnsweredReview: null,
  summary: null,
  summaryLoading: false,
  summaryError: null,
}

export const reviewsListSelector = ({reviews: {data}}: RootState) => data;
export const reviewsLoadingSelector = ({reviews: {loading}}: RootState) => loading;
export const reviewsErrorSelector = ({reviews: {error}}: RootState) => error;
export const reviewsSummarySelector = ({reviews: {summary}}: RootState) => summary;
export const reviewsSummaryLoadingSelector = ({reviews: {summaryLoading}}: RootState) => summaryLoading;
export const reviewsSummaryErrorSelector = ({reviews: {summaryError}}: RootState) => summaryError;
export const reviewsUnansweredCountSelector = ({reviews: {countNotAnsweredReview}}: RootState) => countNotAnsweredReview;
export const reviewsPageCountSelector = ({reviews: {pageCount}}: RootState) => pageCount;

export default function reviews(
  state = initialState,
  action: ReviewsAction,
): ReviewsState {
  switch (action.type) {
    case types.GET_REVIEWS_REQUEST:
      return {
        ...state,
        data: [],
        pageCount: 1,
        loading: true,
        error: null,
      }
    case types.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        pageCount: action.payload.pageCount,
        loading: false,
        error: null,
      }
    case types.GET_REVIEWS_FAILURE:
      return {
        ...state,
        data: [],
        pageCount: 1,
        loading: false,
        error: null,
      }
    case types.REPLACE_IN_REVIEWS:
      return {
        ...state,
        data: replaceInData(state.data, action.payload),
      }
    case types.GET_REVIEWS_SUMMARY_REQUEST:
      return {
        ...state,
        summary: null,
        summaryLoading: true,
        summaryError: null,
      }
    case types.GET_REVIEWS_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.payload,
        summaryLoading: false,
        summaryError: null,
      };
    case types.GET_REVIEWS_SUMMARY_FAILURE:
      return {
        ...state,
        summary: null,
        summaryLoading: false,
        summaryError: action.payload,
      }
    case types.SET_UNANSWERED_REVIEWS_COUNT:
      return {
        ...state,
        countNotAnsweredReview: action.payload,
      }
    case types.ADD_TO_UNANSWERED_REVIEWS_COUNT:
      return {
        ...state,
        countNotAnsweredReview: (state.countNotAnsweredReview ?? 0) + action.payload,
      }
    default:
      return state;
  }
}
