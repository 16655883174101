import React from 'react';
import styles from "./TextInput.module.scss";
import {TextInputProps} from "./types";
import classNames from "classnames";
import Icon from "../Icon";
import MaskedInput from 'react-text-mask';

/**
 * Поле ввода текста
 */
const TextInput = React.forwardRef<unknown, TextInputProps>(({
  field,
  wrapperClassName,
  formObject,
  form,
  icon,
  hasError,
  mask,
  ...inputProps
}, _) => {
  const name = inputProps.name || (field && field.name);

  /**
   * Проверка наличия ошибки
   */
  let isError = false;

  if (formObject) {
    isError = !!(formObject && name && name in formObject.touched && name in formObject.errors);
  } else if (form && typeof form === "object"){
    isError = !!(form && name && name in form.touched && name in form.errors);
  }

  if (hasError){
    isError = hasError;
  }
  
  const defaultInputProps = {
    className: 'form-control',
    type: 'text',
    autoComplete: 'off',
  }

  return <div className={wrapperClassName}>
    <div className={classNames(styles['input-wrapper'], isError && styles['input-wrapper--error'])}>
      {mask
        ? <MaskedInput
            mask={mask}
            {...defaultInputProps}
            {...field}
            {...inputProps}
          />
        : <input
            {...defaultInputProps}
            {...field}
            {...inputProps}
          />
      }
      {icon && <Icon className={styles['icon']} icon={icon} />}
    </div>
  </div>
})

export default TextInput;
